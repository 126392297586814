import * as Styled from "./style";
import React from "react";
import ping from "../../assets/Svg/ping.svg";
import vett from "../../assets/image/vett.svg";
import { course } from "./data";

const HeaderPre = () => {
  return (
    <>
      <Styled.Courses>
        <Styled.Image>
          <Styled.ImgPing src={ping}></Styled.ImgPing>
        </Styled.Image>
        <Styled.Preview>
          <div className="precourse">{course.preCourse}</div>
          <div className="please">{course.please}</div>
          <div className="please">{course.before}</div>
        </Styled.Preview>
        <Styled.Vett>
          <Styled.Img src={vett}></Styled.Img>
        </Styled.Vett>
      </Styled.Courses>
    </>
  );
};

export default HeaderPre;
