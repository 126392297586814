import { MdDownload } from "react-icons/md";

export const dataInvoices=[
    {
       Date:"25/03/2022",
       Number:"#051",
       courseName :"General Awareness ...",
       Total:"£700.00",
       amountDue:"£700.00",
       invoicePDF:"Preview",
       Download:<MdDownload/>,
    
    },
    {
        Date:"20/01/2022",
        Number:"#053",
        courseName :"General Awareness ...",
        Total:"£700.00",
        amountDue:"£700.00",
        invoicePDF:"Preview",
        Download:<MdDownload/>,
     
     },
     {
        Date:"07/01/2022",
        Number:"#059",
        courseName :"CPC Periodic Training",
        Total:"£700.00",
        amountDue:"£700.00",
        invoicePDF:"Preview",
        Download:<MdDownload/>,
     
     },
     
]
export const dataItem = {
  Invoices: "Invoices & Payments",
  BelowText:
    "Below is a list of all the records of invoices and payments you have made.",
  GSAT: "GSAT",
  Newestfirst: "Newest first",
  Oldestfirst: "Oldest first",
};
export const ConstantInvoice = {
  invoice: "Invoice",
  no: "Invoice No",
  date: "12/08/23",
  ps: "PS224453",
  client: "Client",
  way: " 3885 Hua Way",
  north: "Northaden, Denmark",
};