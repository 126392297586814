import * as Styled from "./style";
import React, { useState } from "react";
import { Checkbox } from "antd";
import { confirm } from "./data";
import useOrderCancelForm from "../../hooks/OrderCancelFormHook";
import { message } from "antd";

const OrderCancelForm = () => {
  const { OrderCancleAction } = useOrderCancelForm();
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState();

  const handleAgreed = (e) => {
    setAgreed(true);
  };
  const [orderCancelFormdata, setOrderCancelFormdata] = useState({
    orderId: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    address1: "",
    address2: "",
    city: "",
    postalCode: "",
    isPostponeOrder: 0,
    isCancelOrder: 0,
    reason: "",
  });
  const handleChange = (e) => {
    if (e.target.type === "radio") {
      const wish = {
        isCancelOrder: e.target.value === "isCancelOrder" ? 1 : 0,
        isPostponeOrder: e.target.value === "isPostponeOrder" ? 1 : 0,
      };
      setOrderCancelFormdata({ ...orderCancelFormdata, ...wish });
    } else {
      setOrderCancelFormdata({
        ...orderCancelFormdata,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleSubmit = () => {
    const newErrors = {};
    if (
      orderCancelFormdata?.firstName?.length === 0 &&
      orderCancelFormdata?.lastName?.length === 0 &&
      orderCancelFormdata?.email?.length === 0 &&
      orderCancelFormdata?.mobile?.length === 0 &&
      orderCancelFormdata?.orderId?.length === 0 &&
      orderCancelFormdata?.address1?.length === 0 &&
      orderCancelFormdata?.address2?.length === 0 &&
      orderCancelFormdata?.city?.length === 0 &&
      orderCancelFormdata?.postalCode?.length === 0 &&
      orderCancelFormdata?.reason?.length === 0
    ) {
      message.error("Please fill out the required details");
    } else {
      if (!orderCancelFormdata?.firstName?.trim()) {
        newErrors.firstName = "Firstname is required";
      }
      if (!orderCancelFormdata?.lastName?.trim()) {
        newErrors.lastName = "Lastname is required";
      }
      if (!orderCancelFormdata?.orderId?.trim()) {
        newErrors.orderId = "OrderId is required";
      } else if (!/^\d{1,7}$/.test(orderCancelFormdata?.orderId)) {
        newErrors.orderId = "OrderId should be numeric";
      }

      if (!orderCancelFormdata?.address1?.trim()) {
        newErrors.address1 = "Address1 is required";
      }
      if (!orderCancelFormdata?.address2?.trim()) {
        newErrors.address2 = "Address2 is required";
      }
      if (
        orderCancelFormdata?.isPostponeOrder === 0 &&
        orderCancelFormdata?.isCancelOrder === 0
      ) {
        newErrors.isPostponeOrder = "Checkbox is required";
      }
      if (!orderCancelFormdata?.city?.trim()) {
        newErrors.city = "City is required";
      }
      if (!orderCancelFormdata?.reason?.trim()) {
        newErrors.reason = "Reason is required";
      }
      if (!orderCancelFormdata?.postalCode?.trim()) {
        newErrors.postalCode = "Postal code is required";
      }
      if (!orderCancelFormdata?.email?.trim()) {
        newErrors.email = "Email is required";
      } else if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(
          orderCancelFormdata?.email
        )
      ) {
        newErrors.email = "Invalid email address";
      }
      if (!orderCancelFormdata?.mobile?.trim()) {
        newErrors.mobile = "Mobile number is required";
      } else if (!/^\d{1,10}$/.test(orderCancelFormdata?.mobile)) {
        newErrors.mobile = "Mobile number should be numeric";
      }
      if (!agreed == true) {
        message.error("Terms and conditions checkbox is required");
      }
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0 && agreed == true) {
        OrderCancleAction(orderCancelFormdata);
      }
    }
  };

  return (
    <>
      <Styled.Terms>{confirm.cancel}</Styled.Terms>
      <Styled.MainCotainer className="container">
        <div>
          <div className="to">{confirm.to}</div>
          <div className="suite">
            {confirm.avtav}
            <br />
            {confirm.suite}
            <br />
            {confirm.sov}
            <br />
            {confirm.road}
          </div>
        </div>
        <div>
          <div className="order">
            <Styled.Label>
              {confirm.order} <span className="star">*</span>
            </Styled.Label>
            <Styled.Input
              type="text"
              name="orderId"
              value={orderCancelFormdata?.orderId}
              onChange={(e) => handleChange(e)}
            />
            {errors?.orderId && <Styled.Error>{errors?.orderId}</Styled.Error>}
          </div>
          <Styled.Names>
            <div>
              <Styled.Label>
                {confirm.first}
                <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type="text"
                name="firstName"
                value={orderCancelFormdata?.firstName}
                onChange={(e) => handleChange(e)}
              />
              {errors?.firstName && (
                <Styled.Error>{errors?.firstName}</Styled.Error>
              )}
            </div>

            <div>
              <Styled.Label>
                {confirm.last} <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type="text"
                name="lastName"
                value={orderCancelFormdata?.lastName}
                onChange={(e) => handleChange(e)}
              />
              {errors?.lastName && (
                <Styled.Error>{errors?.lastName}</Styled.Error>
              )}
            </div>
          </Styled.Names>
          <Styled.Names>
            <div>
              <Styled.Label>
                {confirm.mail} <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type="email"
                name="email"
                value={orderCancelFormdata?.email}
                onChange={(e) => handleChange(e)}
              />
              {errors?.email && <Styled.Error>{errors?.email}</Styled.Error>}
            </div>

            <div>
              <Styled.Label>
                {confirm.mobile} <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type="text"
                name="mobile"
                value={orderCancelFormdata?.mobile}
                onChange={(e) => handleChange(e)}
              />
              {errors?.mobile && <Styled.Error>{errors?.mobile}</Styled.Error>}
            </div>
          </Styled.Names>

          <div className="order">
            <Styled.Label>
              {confirm.address1}
              <span className="star">*</span>
            </Styled.Label>
            <Styled.InputAddress
              type="text"
              name="address1"
              value={orderCancelFormdata?.address1}
              onChange={(e) => handleChange(e)}
            />
            {errors?.address1 && (
              <Styled.Error>{errors?.address1}</Styled.Error>
            )}
          </div>
          <div className="order">
            <Styled.Label>
              {confirm.address2} <span className="star">*</span>
            </Styled.Label>
            <Styled.InputAddress
              type="text"
              name="address2"
              value={orderCancelFormdata?.address2}
              onChange={(e) => handleChange(e)}
            />
            {errors?.address2 && (
              <Styled.Error>{errors?.address2}</Styled.Error>
            )}
          </div>
          <Styled.Names>
            <div>
              <Styled.Label>
                {confirm.town} <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type="text"
                name="city"
                value={orderCancelFormdata?.city}
                onChange={(e) => handleChange(e)}
              />
              {errors?.city && <Styled.Error>{errors?.city}</Styled.Error>}
            </div>

            <div>
              <Styled.Label>
                {confirm.post}
                <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type="text"
                name="postalCode"
                value={orderCancelFormdata?.postalCode}
                onChange={(e) => handleChange(e)}
              />
              {errors?.postalCode && (
                <Styled.Error>{errors?.postalCode}</Styled.Error>
              )}
            </div>
          </Styled.Names>
          <Styled.CheckMains>
            <div className="check">{confirm.wish}</div>
            <div className="prompts">
              <div className="checked">
                <input
                  type="radio"
                  value="isPostponeOrder"
                  name="wish"
                  className="checkfa"
                  onChange={(e) => handleChange(e)}
                />
                {confirm.box}
              </div>
              <div className="checked">
                <input
                  type="radio"
                  value="isCancelOrder"
                  name="wish"
                  className="checkfa"
                  onChange={(e) => handleChange(e)}
                />{" "}
                {confirm.cancelOrder}
              </div>
            </div>
            {errors?.isPostponeOrder && (
              <Styled.Error>{errors?.isPostponeOrder}</Styled.Error>
            )}
          </Styled.CheckMains>
          <Styled.TextArea>
            <div className="cancelation">{confirm.postponement} </div>
            <Styled.InputText
              type="text"
              value={orderCancelFormdata?.reason}
              name="reason"
              onChange={(e) => handleChange(e)}
            />
            {errors?.reason && <Styled.Error>{errors?.reason}</Styled.Error>}
          </Styled.TextArea>
          <div className="checkBox">
            <Checkbox
              value="check"
              className="checkfa"
              name="agreed"
              onChange={(e) => handleAgreed(e)}
            />
            {confirm.agreed}
            <span className="terms">
              <a href="">{confirm.sale}</a>
            </span>
          </div>
        </div>
        <Styled.Submit>
          <button className="submit" onClick={() => handleSubmit()}>
            {confirm.submit}
          </button>
        </Styled.Submit>
      </Styled.MainCotainer>
    </>
  );
};

export default OrderCancelForm;
