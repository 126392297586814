import { createSlice } from "@reduxjs/toolkit";
const cartSlice = createSlice({
  name: "cartData",
  initialState: {
    cart: [],
    loading: false,
    error: null,
  },
  reducers: {
    addtoCartStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    addtoCartSuccess: (state, action) => {
      state.loading = false;
      state.cart = action.payload;
    },
    addtoCartFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addItemCart: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.cart = [...state.cart, action.payload];
    },
    qtyUpdateAction: (state, action) => {
      state.loading = true;
      state.cart = action.payload;
      state.loading = false;
    },
    qtyUpdateFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const {
  addtoCartStart,
  addtoCartSuccess,
  addtoCartFailure,
  addItemCart,
  qtyUpdateAction,
  qtyUpdateFailure,
} = cartSlice.actions;
export default cartSlice.reducer;
