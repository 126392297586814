import { styled } from "styled-components";
import BackgroundImageUn from "../../assets/image/BackgroundImageUn.jpg";
import { color } from "../../theme/Color";

export const Container = styled.div`
  display: flex;
  overflow-x: hidden;
  @media only screen and (max-width: 800px) {
    justify-content: center;
    background: url(${BackgroundImageUn});
    background-size: cover;
    background-position: center;
  }
`;

export const SidebarContainer = styled.div`
  flex: 0.35;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export const ImgContainer = styled.div`
  display: none;

  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding-bottom: 10%;
  }
`;

export const Log = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImgLogo = styled.img`
  width: 116.245px;
  height: 134.121px;
`;

export const ParaDiv = styled.img`
  position: relative;
  top: 19%;
`;

export const IconContainer = styled.div`
  display: none;
  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Img2 = styled.p`
  margin-bottom: 30px;
`;

export const LogoImg = styled.img`
  width: 155.912px;
  height: 36.032px;
`;

export const Icons = styled.p`
  color: ${color._ffffff};
  transform: scale(2);
  display: flex;
  justify-content: center;
  gap: 5%;

  .icon {
    width: 13.852px;
    height: 13.852px;
  }
`;

export const ImageContainer = styled.div`
  background: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  @media (min-height: 850px) {
    height: 100vh;
  }
`;

export const FormContainer = styled.div`
  flex: 0.65;

  @media only screen and (max-width: 800px) {
    padding-top: 40%;
    padding-bottom: 80%;
    height: 100%;
  }
`;

export const Head = styled.div`
  padding-bottom: 28px;
`;
export const MainContainer = styled.div`
  height: 100%;
  padding: 79px 0px 79px 0px;
`;
