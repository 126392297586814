import React from "react";
import MainLayout from "../../layout/MainLayout";
import TrainingCourses from "../../containers/TrainingCourses/TrainingCourses";

const TrainingCoursePage = () => {
  return (
    <MainLayout>
      <TrainingCourses />
    </MainLayout>
  );
};

export default TrainingCoursePage;
