import HttpApis from "../services/HttpApis";
import { useDispatch } from "react-redux";
import {
  preCoursePostFail,
  preCoursePostSuccess,
  preCourseSuccess,
} from "../redux/slices/preCourseQuestionSlice";

const usePreCourseQuestionHook = () => {
  const dispatch = useDispatch();
  const getPreCourseData = async (data) => {
    try {
      const response = await HttpApis.getPreCourseQuestionnaire(data);
      dispatch(preCourseSuccess(response?.data));
    } catch (error) {
      console.error("error");
      return null;
    }
  };
  const postPreCourseDataAction = (answersData) => async (dispatch) => {
    try {
      const response = await HttpApis.postPreCourseQuestionnaire(answersData);
      return dispatch(preCoursePostSuccess(response.data));
    } catch (error) {
      dispatch(preCoursePostFail(error.message));
      console.error("error");
    }
  };

  const handleAnswersData = async (answersData) => {
    const res = await dispatch(postPreCourseDataAction(answersData));
    return res;
  };
  return { getPreCourseData, postPreCourseDataAction, handleAnswersData };
};

export default usePreCourseQuestionHook;
