import { styled } from "styled-components";
import { color } from "../../../theme/Color";

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;
export const ImgContainer = styled.div`
  margin-top: 118px;
`;

export const ImgLogo = styled.img`
  width: 116.245px;
  height: 134.121px;
`;
export const Log = styled.div`
  display: flex;
  justify-content: center;
`;
export const Img2 = styled.p`
  margin-bottom: 30px;
`;

export const LogoImg = styled.img`
  width: 155.912px;
  height: 36.032px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: inherit;
`;

export const Para = styled.img`
  position: relative;
  top: 19%;
`;
export const AvtImg = styled.p`
  height: 120%;
`;

export const Icons = styled.p`
  color: ${color._ffffff};
  transform: scale(2);
  display: flex;
  justify-content: center;
  gap: 5%;

  .icon {
    width: 13.852px;
    height: 13.852px;
  }
`;

export const SideImg = styled.img`
  width: 440px;
`;
