import { styled } from "styled-components";
import { color } from "../../../theme/Color";

export const LabelCount = styled.div`
  padding-top: 53px;
  position: relative;
`;

export const Details = styled.div`
  .FirstLast {
    display: flex;
    gap: 41px;
    margin-top: 45px;
    position: relative;
    left: 57px;
    @media only screen and (max-width: 800px) {
      left: 0px;
    }
  }
`;
export const Company = styled.div`
  position: relative;
  left: 50px;

  @media only screen and (max-width: 800px) {
    left: 0px;
  }
  .companyReg {
    display: flex;
    gap: 57px;
    margin-top: 32px;
  }
  .companyName {
    margin-top: 32px;
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 15px 0px;
  &.style-width:nth-child(1) {
    width: 84%;
  }
  &.style-width:nth-child(4) {
    width: 84%;
  }
  &.style-width:nth-child(5) {
    width: 84%;
  }
  &.style-width:nth-child(8) {
    width: 84%;
  }
`;
export const TableData = styled.div`
  flex-wrap: wrap;
  display: flex;
`;
export const Column = styled.div`
  align-items: center;
  display: flex;
  width: 80%;
  padding-left: 5px;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 7%;
`;
export const MainPassword = styled.div`
  height: 45px;
  border-radius: 3px;
  width: 300px;
  padding: 8px;
  outline: none;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
  display: flex;
  justify-content: space-between;
  .eye {
    cursor: pointer;
  }
`;
export const PasswordInput = styled.input`
  width: 150px;
  border: none;
  outline: none;
  background: ${color._fbfbfb};
`;
export const Input = styled.input`
  height: 45px;
  border-radius: 3px;
  width: 300px;
  padding: 8px;
  outline: none;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
  text-transform: capitalize;
  &.email-input {
    text-transform: none;
  }
`;
export const CompanyInput = styled.input`
  width: 87%;
  outline: none;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
  height: 45px;
  border-radius: 3px;
  padding: 8px;
  text-transform: capitalize;
`;
export const Tick = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .check {
    position: relative;
    right: 15%;
  }
`;
export const Check = styled.div``;

export const LabelType = styled.label`
  color: rgba(0, 0, 0, 0.66);
  margin-top: 18px;
`;

export const Label = styled.label`
  color: ${({ condition }) => (condition ? color._565656 : color._000000)};
  padding-bottom: 4px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 95%;

  @media only screen and (max-width: 800px) {
    color: ${color._ffffff};
    font-size: 20px;
  }
  .star {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    color: ${color._f20000};
  }
  gap: 3px;
  display: flex;
`;

export const PasswordDetails = styled.div`
  display: flex;
  justify-content: start;
  position: relative;
  left: 8%;
  margin-top: 18px;
`;

export const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

export const SidebarContainer = styled.div`
  flex: 0.35;
`;

export const ImageContainer = styled.div`
  background: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  height: 993px;
`;

export const FormContainer = styled.div`
  flex: 0.65;
`;
export const pipe = styled.div`
  display: flex;
  background-color: ${color._5193aa};
  width: 3px;
  height: 26px;
  margin-top: 4px;
`;

export const Head = styled.div`
  padding-bottom: 14px;
`;
export const MainContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 27px;
`;
export const Box = styled.div`
  min-height: 363px;
  width: 86.4%;
  margin: 0 auto;

  @media only screen and (max-width: 800px) {
    width: 100%;
    min-height: 310px;
  }
`;

export const SubmitMessage = styled.div`
  height: 231px;
  .ant-result .ant-result-title {
    font-size: 21px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-result-subtitle {
    color: ${color._0090BD};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ant-result-icon {
    margin: 0 auto;
    width: 44px;
    height: 44px;
  }

  position: relative;
  top: 18%;
  width: 75%;
  margin: 0 auto;
  background-color: ${color._F1FBFFE3};
  position: relative;
  top: 9%;
  color: ${color._16384e};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const LogoContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 5%;
  justify-content: center;
`;
export const Logo = styled.div``;
export const Checkbox = styled.div`
  .logo {
    width: 29px;
    height: 20px;
    margin-top: 8px;
  }

  display: flex;
`;
export const BoxData = styled.div`
  border-radius: 0px 2px;
  border: 1px solid ${color._eaedef};
  background: ${color._fff};
  width: 88%;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
`;
export const SignLine = styled.div`
  margin-left: 5px;
  width: 195px;
  height: 1px;
  background: ${color._000000};
  margin-top: 4px;
`;

export const Signature = styled.div`
  display: flex;
  padding-left: 5px;
  margin-top: 21px;
  align-items: center;
  gap: 2%;
`;

export const DeclarationData = styled.div`
  .establish {
    margin-left: 22px;
  }
  .trader {
    margin-top: 15px;
  }
  .self-trader {
    margin-top: 6px;
  }
  padding: 28px 34px;
  color: rgba(0, 0, 0, 0.66);
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const ButtonSubmit = styled.div`
  margin-top: 21px;

  .buttonSubmit {
    border: none;
    width: 181px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._0d2331};
    color: ${color._ffffff};
    box-shadow: none;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const RegisterBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  .btnRegister {
    border-radius: 2px;
    background: ${color._0073de};
    width: 28%;
    width: 250px;
    height: 35px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &:hover {
      background-color: #177a9c;
      color: #ffffff;
    }
  }
`;

export const CompanyBox = styled.div`
  min-height: 500px;
  margin: 0 auto;
  width: 86.4%;
  margin-bottom: 30px;
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const PasswordBox = styled.div`
  min-height: 370px;
  margin: 0 auto;
  width: 86.4%;
`;

export const Line = styled.div`
  width: 84%;
  height: 1px;
  background: ${color._eaedef};
  margin: 10px auto;
`;
export const CheckBoxContainer = styled.div`
  display: flex;
  gap: 33px;
  flex-direction: column;
  margin-top: 40px;
  width: 85%;
  padding-left: 8%;
`;
export const CheckBoxText = styled.div`
  display: flex;
  gap: 9px;
  a {
    color: #177a9c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%;
    coursor: pointer;
    text-decoration: none;
  }
  .text {
    color: ${color._6d7d8b};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%;
    @media only screen and (max-width: 800px) {
      color: ${color._ffffff};
    }
  }
  .textStyle {
    color: ${color._177a9c};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%;

    @media only screen and (max-width: 800px) {
      color: ${color._5193aa};
    }
  }
`;

export const Error = styled.div`
  color: #ff0000;
`;
