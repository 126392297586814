import * as Styled from "./style";
import React from "react";
import { website } from "./data";

const TermsAndUse = () => {
  return (
    <>
      <Styled.Terms>{website.terms}</Styled.Terms>
      <Styled.Lines className="container">
        <div>
          <div className="about">{website.aboutMain}</div>
          <div className="register">
            {website.apply}
            <span className="web">{website.web}</span>
            {website.rev}
          </div>
          <div className="register">{website.accessing}</div>
        </div>

        <div>
          <div className="about">{website.about}</div>
          <div className="register">{website.registered}</div>
          <div className="register">{website.with}</div>
          <div>
            <div className="register">{website.questions}</div>
            <div className="register">{website.sending}</div>
            <div className="register">{website.filling}</div>
            <div className="register">{website.calling}</div>
          </div>
        </div>
        <div>
          <div className="about">{website.using}</div>
          <div className="register">{website.reserve}</div>
          <div className="register">{website.responsible}</div>
          <div className="register">{website.condition}</div>
          <div>
            <div className="register">{website.misuse}</div>
            <div className="register">{website.unauthorised}</div>
            <div className="register">{website.remove}</div>
          </div>
          <div className="register">{website.prevent}</div>
          <div className="register">{website.suspend}</div>
          <div className="register">{website.promise}</div>
        </div>
        <div>
          <div className="about">{website.password}</div>
          <div className="register">{website.need}</div>
          <div className="register">{website.detailsText}</div>
          <div className="register">{website.secure}</div>
          <div className="register">{website.compromised}</div>
          <div className="register">{website.terminate}</div>
        </div>
        <div>
          <div className="about">{website.INTELLECTUAL}</div>
          <div className="register">
            {website.licensors}
            <span className="web">{website.content}</span>
            {website.owned}
          </div>
          <div className="register">{website.anywhere}</div>
          <div className="register">{website.grants}</div>
          <div className="register">{website.express}</div>
        </div>
        <div>
          <div className="about">{website.on}</div>
          <div className="register">{website.sure}</div>
          <div className="register">{website.suitable}</div>
          <div className="about">{website.sites}</div>
          <div className="register">{website.control}</div>
        </div>
        <div>
          <div className="about">{website.limit}</div>
          <div className="register">{website.responsibility}</div>
          <div className="register">{website.losses}</div>
          <div className="register">{website.caused}</div>
          <div className="register">{website.business}</div>
          <div className="register">{website.non}</div>
        </div>
        <div>
          <div className="about">{website.variation}</div>
          <div className="register">{website.responsibilityText}</div>
        </div>
        <div>
          <div className="about">{website.complaint}</div>
          <div className="register">{website.contact}</div>
        </div>
        <div>
          <div className="about">{website.law}</div>
          <div className="register">{website.consumer}</div>
        </div>
      </Styled.Lines>
    </>
  );
};

export default TermsAndUse;
