import { createSlice } from "@reduxjs/toolkit";

const SliderSlice = createSlice({
  name: "progressBar",
  initialState: {
    getProgress: 0,
    postProgress: 0,
    postCheckList: [],
    getCheckList: [],
    getQuiz: [],
    loading: false,
    error: null,
    getCourseExpired: {},
  },
  reducers: {
    getProgressSuccess: (state, action) => {
      state.getProgress = action.payload;
    },
    postProgressStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    postProgressSuccess: (state, action) => {
      state.postProgress = action.payload;
    },
    postProgressFail: (state, action) => {
      state.error = action.payload;
    },
    postCheckListSuccess: (state, action) => {
      state.postCheckList = action.payload;
    },
    postCheckListFail: (state, action) => {
      state.error = action.payload;
    },
    getCheckListSuccess: (state, action) => {
      state.getCheckList = action.payload || {};
    },
    getCheckListFail: (state, action) => {
      state.error = action.payload;
    },
    getQuizSuccess: (state, action) => {
      state.getQuiz = action.payload;
    },
    getCourseExpiredStatus: (state, action) => {
      state.getCourseExpired = action.payload;
    },
  },
});

export const {
  postProgressStart,
  postProgressSuccess,
  postProgressFail,
  getProgressSuccess,
  postCheckListSuccess,
  postCheckListFail,
  getCheckListSuccess,
  getCheckListFail,
  getQuizSuccess,
  getCourseExpiredStatus,
} = SliderSlice.actions;

export default SliderSlice.reducer;
