import AcceptableUser from "../../containers/InvitedUserSignUp/AcceptableUser";
import MainLayout from "../../layout/MainLayout";
import React from "react";

const AcceptablePage = () => {
  return (
    <MainLayout>
      <AcceptableUser />
    </MainLayout>
  );
};

export default AcceptablePage;
