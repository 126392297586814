import * as Styled from "./style";
import Footer from "../../components/Organisms/Footer/Footer";
import OrderDetails from "../../components/Atoms/OrderDetail/OrderDetails";
import Payment from "../../assets/Svg/Payment.svg";
import React, { useEffect } from "react";
import { ReactComponent as ResultTick } from "../../assets/Svg/resultTick.svg";
import { Navigation } from "../../components/Organisms/Navigation/Navigation";
import { browseData, paymentTick } from "./BrowseData";
import useCommonHook from "../../hooks/CommonHook";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PaymentMessage = () => {
  const { updateCommonState } = useCommonHook();
  const userType = useSelector((state) => state?.authReducer?.user?.type);

  useEffect(() => {
    updateCommonState({
      checkoutReferer: null,
    });
  }, []);

  const navigate = useNavigate();
  window.addEventListener("popstate", function (event) {
    navigate("/payment-message");
  });

  return (
    <>
      <Navigation />
      <div>
        <Styled.IconMap>
          <img src={Payment} alt="payment" />
          <div>
            {paymentTick?.map((item, index) => {
              return <img key={index} src={item?.img} alt="image" />;
            })}
          </div>
        </Styled.IconMap>
        <Styled.ResultPayment>
          <Styled.ResultConfirm>
            <div className="payment">{browseData.success} </div>
            <Styled.Message>
              <div className="tick">
                <ResultTick />
              </div>

              <div className="success">{browseData.went} </div>
              <div className="receive">
                {userType === "Single User" ? (
                  <p className="after">
                    After your declaration has been received from your manager
                    and verified by AVTAV Training Team, we will send you an
                    email which will give you access to the course material.
                  </p>
                ) : (
                  <p className="after">
                    After your declaration has been verified by AVTAV Training
                    Team, we will send you an email which will give you access
                    to the course licenses.
                  </p>
                )}
              </div>
            </Styled.Message>
          </Styled.ResultConfirm>
        </Styled.ResultPayment>
        <Styled.Confirm>
          <div>
            <OrderDetails />
          </div>
        </Styled.Confirm>
      </div>
      <Footer />
    </>
  );
};

export default PaymentMessage;
