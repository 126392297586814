import styled from "styled-components";
import { color } from "../../theme/Color";

export const MainContainer = styled.div`
  display: flex;
  margin: ${({ isPayment }) => (isPayment ? "" : "50px 0px")};
  @media only screen and (max-width: 800px) {
    display: block;
    padding: 0px 54px;
  }
`;
export const FirstConatiner = styled.div`
  flex: 0.6;
`;
export const SecondContainer = styled.div`
  flex: 0.4;
  background: ${({ isPayment }) => (isPayment ? color._EFF3F9 : "")};
  @media only screen and (max-width: 800px) {
    margin-top: 40px;
  }
`;
export const MidContainer = styled.div`
  background: ${({ isPayment }) => (isPayment ? "" : color._f6fafb)};
  padding: 28px;
  width: 419px;
  @media only screen and (max-width: 800px) {
    width: 700px;
  }
  .subtotal {
    width: 326px;
  }
`;
export const Billing = styled.div`
  .select {
    color: ${color._5193aa};
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 15px 0px;
    @media only screen and (max-width: 800px) {
      font-size: 36px;
    }
  }
  @media only screen and (max-width: 800px) {
    .radioBtn {
      width: 26px;
      height: 22px;
    }
    .addData {
      display: flex;
      align-items: center;
    }
  }

  .address {
    color: ${color._071b28};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
    @media only screen and (max-width: 800px) {
      font-size: 27px;
    }
  }
  .radioBox {
    border: 1px solid ${color._EFF3F9};
    padding: 10px;
  }
  .add {
    margin: 10px 5px;
  }
`;

export const NextLineDeclare = styled.div`
  width: 91%;
  height: 1px;
  background: ${color._eaedef};
  margin: 50px auto;
`;
export const BankDetail = styled.div`
  .accordion-button {
    width: 365px;
    height: 37px;
  }
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${color._EFF3F9};
  margin: 10px auto;
`;
export const OrderDetail = styled.div`
  display: flex;
  .cartText {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media only screen and (max-width: 800px) {
      font-size: 26px;
    }
  }
  .cartQty {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    padding-left: 10px;
    line-height: normal;
    @media only screen and (max-width: 800px) {
      font-size: 26px;
    }
  }
  .cartPrice {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    @media only screen and (max-width: 800px) {
      font-size: 26px;
    }
  }
`;
export const Course = styled.div`
  flex: 0.6;
  .course {
    width: 84px;
    height: 17px;
    background: ${color._f1fbff};
    color: ${color._5193aa};

    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media only screen and (max-width: 800px) {
      width: 185px;
      height: 42px;
      font-size: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
export const Qty = styled.div`
  flex: 0.15;
  .qty {
    width: 30px;
    height: 17px;
    background: ${color._f1fbff};
    color: ${color._5193aa};

    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media only screen and (max-width: 800px) {
      width: 60px;
      height: 42px;
      font-size: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
export const Price = styled.div`
  flex: 0.25;
  .price {
    width: 111px;
    height: 17px;
    background: ${color._f1fbff};
    color: ${color._5193aa};

    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media only screen and (max-width: 800px) {
      width: 228px;
      height: 42px;
      font-size: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
export const InputFieldData = styled.div`
  .orderNotes {
    display: flex;
    flex-direction: column;
  }
  .optional {
    color: ${color._5193aa};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .info {
    margin-top: 18px;
    color: ${color._071b28};
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .option {
    border-radius: 4px;
    outline: none;
    border: ${color._fbfbfb};
    background: ${color._fbfbfb};
    width: 633px;
    height: 89px;
  }
  .label {
    color: ${color._071b28};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .input {
    width: 621px;
    height: 42px;
    text-tranform: capitalize;
  }
`;
export const OrderBox = styled.div`
  width: 82%;
`;
export const OrderNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
export const Payment = styled.div`
  padding-bottom: 32px;
  color: ${color._16384e};
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 35px;
  }
`;
export const ParagraphGoogle = styled.div`
  padding-top: 44px;
  padding-bottom: 28px;
  @media only screen and (max-width: 800px) {
    font-size: 27px;
  }
`;
export const Purchase = styled.div`
  padding-bottom: 51px;
  color: ${color._16384e};
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 38px;
  }
`;
export const Subtotal = styled.div`
  display: flex;
  justify-content: space-between;
  .number {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 800;
    @media only screen and (max-width: 800px) {
      font-size: 30px;
    }
  }
  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }
`;
export const Total = styled.div`
  padding-bottom: 64px;
  display: flex;
  justify-content: space-between;
  .number {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 800;
    @media only screen and (max-width: 800px) {
      font-size: 30px;
    }
  }
  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }
`;

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .banner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .title {
    color: var(--Text-Color, ${color._08232f});
    padding-top: 51px;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .line {
    background: ${color._ffc636};
    width: 167px;
    height: 5px;
    margin-bottom: 20px;
  }
  .order {
    color: var(--Text-Color, ${color._08232f});
    padding-bottom: 35px;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .billing {
    color: var(--Text-Color, ${color._08232f});
    padding-bottom: 31px;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

export const FormInput = styled.div`
  border-radius: 4px;
  background: ${color._fff};
  width: 633px;
  height: 42px;
  .input {
    width: 98%;
    text-transform: capitalize;
    border: none;
  }
`;
export const Error = styled.div`
  color: ${color._ff0000};
`;
export const FormData = styled.div`
  display: flex;
  flex-direction: column;
  .bill {
    position: relative;
    top: 20px;
    color: #071b28;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media only screen and (max-width: 800px) {
      top: 24px;
      font-size: 33px;
    }
  }
  .order {
    color: ${color._5193aa};
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    @media only screen and (max-width: 800px) {
      font-size: 36px;
    }
  }
  .label {
    color: ${color._071B28};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media only screen and (max-width: 800px) {
      font-size: 30px;
    }
  }
  .input {
    width: 303px;
    border: none;
    background: ${color._FBFBFB};
    border-radius: 4px;
    height: 42px;
    outline: none;
    padding: 10px;
    text-transform: capitalize;
    @media only screen and (max-width: 800px) {
      width: 394px;
      height: 69px;
      font-size: 27px;
    }
  }
  .email-input {
    width: 303px;
    border: none;
    background: ${color._FBFBFB};
    border-radius: 4px;
    height: 42px;
    outline: none;
    padding: 10px;
    @media only screen and (max-width: 800px) {
      width: 394px;
      height: 69px;
      font-size: 27px;
    }
  }
  .label::after {
    content: "*";
    color: ${color._F00E00};
    font-size: 25px;
    font-weight: 400;
  }
  .billing {
    color: ${color._5193aa};
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: 15px;
    @media only screen and (max-width: 800px) {
      font-size: 36px;
    }
  }
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .input {
    width: 621px;
    height: 42px;
    @media only screen and (max-width: 800px) {
      width: 804px;
      height: 69px;
    }
    text-transform: capitalize;
  }
`;
export const Additinal = styled.div`
  padding-bottom: 31px;
  color: ${color._071b28};
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

export const OrderNotes = styled.div`
  display: flex;
  flex-direction: column;
  color: ${color._071b28};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  .inputField {
    width: 633px;
    height: 89px;
    flex-shrink: 0;
    border: none;
    background: ${color._fbfbfb};
    width: 100%;
    text-transform: capitalize;
  }
`;

export const FormDataContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const BuyCourseButton = styled.div`
  .buy_course {
    border-radius: 2px;
    background: ${color._3ba32a};
    color: ${color._fff};
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    padding: 10px 0px;
    width: 100%;
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
    @media only screen and (max-width: 800px) {
      font-size: 2rem;
    }
  }
`;

export const PaypalText = styled.div`
  display: flex;
  gap: 12px;
  color: ${color._16384E};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .cardicon {
    font-size: 24px;
    color: ${color._16384E};
    font-weight: 600;
  }
  @media only screen and (max-width: 800px) {
    gap: 12px;
    font-size: 32px;
    display: flex;
    align-items: center;
  }
`;
export const ButtonBackground = styled.div`
  width: 100%;
  background: ${color._fff};
  justify-content: space-between;
  display: flex;
  padding: 0px 10px;
  height: 37px;
  align-items: center;
  cursor: pointer;
  @media only screen and (max-width: 800px) {
    height: 90px;
    .paypal {
      width: 44px;
    }
    .payVisa {
      width: 98px;
    }
    .paymaster {
      width: 60px;
    }
  }
`;

export const RadioInput = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  &.bottom {
    margin-top: 20px;
  }
  @media only screen and (max-width: 800px) {
    .radioPay {
      width: 37px;
      height: 34px;
    }
  }
`;
