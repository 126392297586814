import styled from "styled-components";
import { color } from "../../../theme/Color";

export const CorporateInvoice = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 44px;
  border-radius: 10px;
  background: ${color._fff};
  .HashNo {
    color: ${color._0089A0};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .Number {
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .invoice {
    color: ${color._ffffff};
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .CheckboxInvoice {
    background: ${color._16384e};
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: space-between;
    height: 110px;
    padding: 26px;
  }
`;
export const Logo = styled.div``;
export const LogoContainer = styled.div`
  display: flex;
  gap: 1.6rem;
  justify-content: center;
  margin-top: 15px;
`;
export const Checkbox = styled.div`
  display: flex;
  gap: 9px;
`;
export const CorporateDescription = styled.div`
  padding: 26px;
  .client {
    color: ${color._16384e};
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    gap: 4px;
  }
  .denmark {
    display: flex;
    justify-content: space-between;
  }
  .way {
    color: ${color._6090a8};
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .date {
    color: ${color._16384e};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
    right: 3%;
    padding-right: 36px;
  }
`;

export const Items = styled.div`
  margin: 20px 0px;
  table {
    border: none;
    border-top: 0.5px solid ${color._c9c9c9};
  }
  td,
  th {
    border-bottom: 0.5px solid ${color._c9c9c9};
    padding: 15px;
  }
  th {
    color: ${color._16384e};
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  td {
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const SideLine = styled.div`
  border-right: 1px solid #0089a0;
  height: 21px;
  margin-top: 13px;
`;
