import { createSlice } from "@reduxjs/toolkit";

const corporateUserSlice = createSlice({
  name: "courses",
  initialState: {
    corporateUsers: [],
    role: {},
  },
  reducers: {
    usersList: (state, action) => {
      state.corporateUsers = action.payload;
    },
    updateRole: (state, action) => {
      state.role = action.payload;
    },
  },
});

export const { usersList, updateRole } = corporateUserSlice.actions;
export default corporateUserSlice.reducer;
