import React from "react";
import SingleUserProfile from "../../containers/SingleUserProfile/Dashboard";

const SingleProfilePage = () => {
  return (
    <div>
      <SingleUserProfile />
    </div>
  );
};

export default SingleProfilePage;
