import styled from "styled-components";
import { Checkbox } from "antd";
import { color } from "../../../theme/Color";

export const Security = styled.div`
  margin-top: 25px;
  width: 420px;
  height: 67px;
  flex-shrink: 0;
  border-radius: 2px;
  background: ${color._f3fbff};
  padding: 8px 20px;

  .name {
    margin-top: 5px;
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .title {
    color: ${color._16384e};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Tables = styled.div`
  margin-top: 20px;
  table {
    border: 0.5px solid ${color._DADADA};
  }

  .tick {
    color: ${color._6d7d8b};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    right: 18px;
    width: 54px;
  }

  th {
    background: ${color._eef0fc};
    color: #15374d;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  td {
    color: ${color._5f5f5f};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  td {
    border: 0.5px solid ${color._DADADA};
    height: 32px;
    padding-left: 21px;
  }
  th {
    border: 0.5px solid ${color._DADADA};
    height: 46px;
    padding-left: 21px;
  }

  .column-style {
    padding-left: 30px;
  }
`;
export const Training = styled.div`
  .secure {
    background: ${color._15374d};
    height: 60px;
    color: ${color._ffffff};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 34px;
    font-family: Raleway;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .slash {
    color: #5193aa;
  }
`;

export const ExamData = styled.div`
  width: 80%;
  padding: 30px 15px;
  .secured {
    margin-top: 25px;
  }
  .how {
    color: ${color._5f5f5f};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .start {
    width: 143px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._0073de};
    color: ${color._ffffff};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    margin-top: 25px;
  }

  .exam {
    color: ${color._177a9c};
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .form {
    color: ${color._000000};
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .how {
    margin-top: 20px;
    width: 758px;
    color: ${color._5f5f5f};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const Acknowledge = styled.div`
  .ackno {
    padding: 20px 20px 6px 20px;
  }

  margin-top: 30px;
  border: 0.5px solid ${color._DADADA};
  width: 651px;
  .train {
    color: ${color._15374d};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .cover {
    margin-top: 10px;
    color: ${color._5f5f5f};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .comment {
    color: ${color._5f5f5f};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 9px 0px 6px 20px;
  }
`;
export const Text = styled.textarea`
  width: 575px;
  height: 95px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid ${color._ebebeb};
  margin: 10px 0px 9px 20px;
  outline: none;
  resize: none;
  background: #fbfbfb;
  padding: 8px;
`;
export const Sign = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  gap: 3%;
  align-items: center;
  .signs {
    color: ${color._15374d};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .datesS {
    color: ${color._15374d};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .add {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border: none;
    border-radius: 5px;
  }
`;
export const Input = styled.input`
  width: 221px;
  height: 24px;
  flex-shrink: 0;
  margin-left: 5px;
  outline: none;
  border: 1px solid rgba(235, 235, 235, 1);
  padding: 5px;
  text-transform: capitalize;
`;
export const InputDate = styled.div`
  height: 24px;
  flex-shrink: 0;
  outline: none;
  border: none;
  width: 122px;
  text-transform: capitalize;
  &::placeholder {
    color: ${color._15374d};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const Line = styled.div`
  width: 651px;
  height: 0.5px;
  background: ${color._DADADA};
  margin: 6px 0px;
`;
export const Checked = styled(Checkbox)``;

export const Error = styled.div`
  color: #ff0000;
`;

export const SignImage = styled.div`
  .sign {
    width: 200px;
  }
`;
