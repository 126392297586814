import styled from "styled-components";
import { color } from "../../../theme/Color";

export const InputEdit = styled.input`
  outline: none;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  height: 30px;
  width: auto;
  margin-top: 10px;
  color: ${color._000000};
  text-transform: capitalize;
`;
export const EditAddressed = styled.div`
  display: flex;
  gap: 22px;
  flex-direction: column;
  .editAddress {
    display: flex;
    flex-direction: column;
  }
`;
export const SaveButton = styled.div`
  display: flex;
  justify-content: end;
  gap: 14px;
  margin-top: 10px;

  .save {
    height: 37px;
    border-radius: 2px;
    color: ${color._ffffff};
    border: none;
    background: ${color._15374d};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
  }
  .cancel {
    height: 37px;
    border-radius: 5px;
    border: none;
    color: ${color._37738c};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    background: none;
    line-height: 95%;
    margin: 0px;
  }
  .crossIcon {
    font-size: 17px;
  }
`;
export const MainContainer = styled.div`
  width: 667px;
  .billing {
    padding-bottom: 39px;
    color: ${color._0f2a3c};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .billingAddress {
    padding: 19px 17px;
    margin-bottom: 19px;
    color: ${color._177a9c};
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    background-color: #f1fbff;
  }
`;
export const Error = styled.div`
  color: ${color._ff0000};
`;
export const Eastlondon = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  .eastlondon {
    color: ${color._15374d};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .eastEdit {
    color: ${color._000000};
    border: 1px solid ${color._ededee};
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
    border-radius: 5px;
    height: 30px;
  }
`;
export const EditButton = styled.div`
  .line {
    background: ${color._15374d};
    width: 101px;
    height: 2px;
    margin-left: -11px;
  }
  svg {
    position: absolute;
    left: -10px;
  }
  .editbutton {
    border: none;
    background: none;
    color: ${color._15374d};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
  }
`;
export const Mobilenumber = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  .mobile {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 20px;
  }
  .mobileEdit {
    color: ${color._15374D};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 22px;
  }
  .number {
    color: ${color._15374d};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const BillingCard = styled.div`
  .billingLabel {
    padding: 19px 17px;
    margin-bottom: 12px;
    color: ${color._177a9c};
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    background-color: ${color._f1fbff};
  }
  .text {
    color: rgba(0, 0, 0, 0.4);
    padding-bottom: 28px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Cardholder = styled.div`
  padding-left: 43px;
  margin-bottom: 10%;
  border-radius: 4px;
  background: ${color._5193aa};
  .cardholder {
    padding-top: 20px;
    padding-bottom: 3px;
    opacity: 0.63;
    mix-blend-mode: overlay;
    color: ${color._ffffff};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .stacey {
    color: ${color._ffffff};
    padding-bottom: 15px;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Textconatiner = styled.div`
  display: flex;
  gap: 58px;
  padding-bottom: 18px;
  .cardNumber {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  .card {
    border: none;
    background: none;
    position: unset;
    mix-blend-mode: overlay;
    color: ${color._ffffff};

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .number {
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const ExpiryDate = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 29px;
  gap: 3px;
  .expirydate {
    mix-blend-mode: overlay;
    color: ${color._ffffff};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .number {
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Address = styled.div`
  text-transform: capitalize;
`;
