import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Boxcontainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 0px;
`;
export const Container = styled.div`
  background: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
export const Border = styled.div`
  height: 4px;
  background: ${color._ffbe32};
  margin-top: 6px;
`;
export const TitleLine = styled.div`
  width: 166px;
  position: relative;
  left: 7%;
`;
export const CheckOut = styled.div`
  color: var(--Text-Color, #08232f);
  margin-top: 50px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const Logo = styled.div`
  @media only screen and (max-width: 800px) {
    .imgLogo {
      width: 360px;
      margin-left: 20px;
    }
  }
`;
export const Cancel = styled.div`
  display: flex;
  align-items: center;
  .cancel {
    background: none;
    border: none;
    color: ${color._ffffff};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media only screen and (max-width: 800px) {
      font-size: 40px;
    }
  }
  @media only screen and (max-width: 800px) {
    margin-right: 20px;
  }
`;
