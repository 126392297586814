import React from 'react'
import * as Styled from "./style"
import { data } from './data'
import AVVetting from "../../assets/image/AVVetting.png"
import Form from '../../components/Organisms/EnquiryForm/Form'
import SocilaMedia from '../../components/Atoms/SocilaMedia/SocilaMedia'

const Vetting = () => {
  return (
    <>
  <Styled.VettingContainer>
 <div className='heading'>{data.heading}</div>
  </Styled.VettingContainer>
  <Styled.About className='container'>
   <div className='left'>
    <div className='heading'>{data.heading}</div>
    <p>{data.para1}</p>
    <p>{data.para2}</p>
    <p>{data.para3}</p>
   </div>
   <div className='right'>
  <img src={AVVetting} alt='AV-Vetting' className='cardImage'/>
   </div>
     </Styled.About>
     <Form />
     <SocilaMedia />
     </>
  )
}
export default Vetting
