import React, { useState } from "react";
import * as Styled from "./style";
import DeclarationLogo from "../../../assets/Svg/DeclarationLogo.svg";
import { AccountDashboardData } from "./data";
import Button from "../Button";
import SignatureModal from "../../Molecules/SignatureModal/SignatureModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import useDeclarationHook from "../../../hooks/DeclarationDataHook";
import { message } from "antd";
import { Routes } from "../../../routes/routes";

const DeclarationFormPdf = () => {
  const navigate = useNavigate();
  const { handlePostDeclaration } = useDeclarationHook();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const userId = searchParams.get("userId");
  const courseId = searchParams.get("courseId");
  const companyName = searchParams.get("companyName");
  const companyRegNo = searchParams.get("companyRegNo");
  const addressLine1 = searchParams.get("addressLine1");
  const addressLine2 = searchParams.get("addressLine2");
  const landingNo = searchParams.get("landingNo");

  const [modalOpen, setModalOpen] = useState(false);
  const [finalSign, setFinalSign] = useState();
  const [postDeclareData, setPostDeclareData] = useState({
    courseId: courseId,
    name: "",
    role: "",
    backgroundCeck: false,
    cpsra: false,
    userId: userId,
    managerName: "",
    managerMobile: null,
    email: email,
    formdate: null,
    sign: null,
  });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setPostDeclareData({
      ...postDeclareData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async () => {
    const listData = { ...postDeclareData, sign: finalSign };
    const response = await handlePostDeclaration(listData);
    if (response?.status === 200) {
      message.success(response?.data?.message);
      navigate(Routes?.homePage);
    }
    return response;
  };

  return (
    <Styled.MainScreen>
      <div style={{ width: "70%" }}>
        <Styled.Header>
          <Styled.HeaderContent>
            <img src={DeclarationLogo} alt="icon" />
          </Styled.HeaderContent>
        </Styled.Header>
        <div style={{ width: "85%", margin: "0 auto" }}>
          <Styled.Heading>Background Check Declaration</Styled.Heading>
          <div>
            <Styled.ItText>{AccountDashboardData.It}</Styled.ItText>
            <Styled.ListText>
              <div className="rulesText">
                {AccountDashboardData.IN}
                <span className="backgroundtext">
                  {AccountDashboardData.Background}
                </span>
                {AccountDashboardData.shall}
              </div>
              <ol>
                <li>{AccountDashboardData.establish}</li>
                <li>{AccountDashboardData.cover}</li>
                <li>{AccountDashboardData.criminal}</li>
              </ol>
            </Styled.ListText>
          </div>
          <Styled.BoxSize>
            <Styled.LearnerName>
              <span className="confirm">{AccountDashboardData.confirm}</span>
              <Styled.InputText
                type="text"
                name="name"
                value={postDeclareData?.name}
                onChange={(e) => handleChange(e)}
                required
              />
              <span className="confirm">{AccountDashboardData.isEmployed}</span>
              <Styled.InputTextRoll
                type="text"
                name="role"
                value={postDeclareData?.role}
                onChange={(e) => handleChange(e)}
                required
              />
              <span className="confirm">
                {AccountDashboardData.Successfully}
              </span>
            </Styled.LearnerName>
            <Styled.BackgroundCheck>
              <span className="check">{AccountDashboardData.BACKGROUND} </span>
              {AccountDashboardData.access}
            </Styled.BackgroundCheck>
            <Styled.PleaseText>
              <div className="please">{AccountDashboardData.Pleasetick}</div>
              <input
                type="checkbox"
                className="inputstyled"
                name="backgroundCeck"
                value={postDeclareData?.backgroundCeck}
                onChange={(e) => handleChange(e)}
                required
              />
            </Styled.PleaseText>
            <span className="airside">{AccountDashboardData.And}</span>
            <div className="textContainer">
              {AccountDashboardData.Has}
              <span className="airside">{AccountDashboardData.AIRSIDE}</span>
              {AccountDashboardData.assigned}
              <span className="airside">{AccountDashboardData.Security}</span>
              {AccountDashboardData.required}
            </div>
            <Styled.PleaseText>
              <div className="please">{AccountDashboardData.Pleasetick}</div>
              <input
                type="checkbox"
                className="inputstyled"
                name="cpsra"
                value={postDeclareData?.cpsra}
                onChange={(e) => handleChange(e)}
                required
              />
            </Styled.PleaseText>
          </Styled.BoxSize>
          <div>
            <Styled.MALPRACTICE>
              <div className="malpractice">
                {AccountDashboardData.MALPRACTICE}
              </div>
              <div className="malpracticeText">
                {AccountDashboardData.deliberate}
              </div>
            </Styled.MALPRACTICE>
            <Styled.AppropriateContainer>
              <div className="appropriate">
                {AccountDashboardData.APPROPRIATEAUTHORITYACTION}
              </div>
              <div className="dftText">
                {AccountDashboardData.The}
                <span className="appropriate">{AccountDashboardData.DfT}</span>
                {AccountDashboardData.willseek}
              </div>
              <div className="purchasing ">
                {AccountDashboardData.purchasing}{" "}
                <span className="appropriate">{AccountDashboardData.DfT}</span>{" "}
                {AccountDashboardData.result}{" "}
                <span className="appropriate">{AccountDashboardData.DfT}</span>
                {AccountDashboardData.contravention}{" "}
                <span className="appropriate">
                  {AccountDashboardData.National}
                </span>
                {AccountDashboardData.associatedsyllabuses}
              </div>
            </Styled.AppropriateContainer>
          </div>
          <Styled.BoxContainer>
            <Styled.CompanyName>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.CompanyName}
                </div>
                <div className="companytext">
                  {companyName ? companyName : ""}
                </div>
              </div>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.CompanyAddress}
                </div>
                <div className="companytext2">
                  {`${addressLine1 ? addressLine1 : ""},`}
                </div>
                <div className="companytext2">
                  {addressLine2 ? addressLine2 : ""}
                </div>
              </div>
            </Styled.CompanyName>
            <Styled.CompanyRegNumber>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.CompanyRegNumber}
                </div>
                <div className="textContainer">
                  {companyRegNo ? companyRegNo : ""}
                </div>
              </div>
              <div className="nameContainer">
                <div className="company">
                  {AccountDashboardData.LandlineNumber}
                </div>
                <div className="textContainer1">
                  {landingNo ? landingNo : ""}
                </div>
              </div>
            </Styled.CompanyRegNumber>
          </Styled.BoxContainer>
          <Styled.DetailContainer>
            <Styled.MainContainer>
              <div className="nameContainer">
                <div className="company">Your Name</div>
                <Styled.InputField
                  type="text"
                  name="managerName"
                  value={postDeclareData?.managerName}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
              <div className="nameContainer">
                <div className="company">Date</div>
                <Styled.InputField
                  type="date"
                  name="formdate"
                  value={postDeclareData?.formdate}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
            </Styled.MainContainer>
            <Styled.MainContainer>
              <div className="nameContainer">
                <div className="company">Mobile Number</div>
                <Styled.InputField
                  type="text"
                  name="managerMobile"
                  value={postDeclareData?.managerMobile}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
            </Styled.MainContainer>
            <Styled.MainContainer>
              <div className="nameContainer">
                <div className="company">Email</div>
                <div>{email}</div>
              </div>
            </Styled.MainContainer>
          </Styled.DetailContainer>
          <Styled.SignWrapper>
            <button className="add" onClick={() => setModalOpen(true)}>
              Add Signature
            </button>
            <Styled.SignedImage>
              {finalSign && (
                <img
                  src={finalSign}
                  alt="signature"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            </Styled.SignedImage>
            {/* {!finalSign && errors?.sign && (
                  <Styled.Error>{errors?.sign}</Styled.Error>
                )} */}
          </Styled.SignWrapper>
        </div>
        <Styled.ButtonContainer>
          <Button
            title="Submit"
            className="btn"
            onClick={() => handleSubmit()}
          />
        </Styled.ButtonContainer>
      </div>
      {modalOpen && (
        <SignatureModal
          setFinalSign={setFinalSign}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
        />
      )}
    </Styled.MainScreen>
  );
};

export default DeclarationFormPdf;
