import * as Styled from "./style";
import Footer from "../../components/Organisms/Footer/Footer";
import OrderDetails from "../../components/Atoms/OrderDetail/OrderDetails";
import Payment from "../../assets/Svg/Payment.svg";
import React, { useEffect } from "react";
import { ReactComponent as ResultTick } from "../../assets/Svg/resultTick.svg";
import { Navigation } from "../../components/Organisms/Navigation/Navigation";
import { browseData, paymentTick } from "./BrowseData";
import useCommonHook from "../../hooks/CommonHook";
import { useNavigate } from "react-router-dom";

const PaymentSoleTrader = () => {
  const { updateCommonState } = useCommonHook();
  useEffect(() => {
    updateCommonState({
      checkoutReferer: null,
    });
  }, []);

  const navigate = useNavigate();
  window.addEventListener("popstate", function (event) {
    navigate("/payment-sole-trader");
  });
  return (
    <>
      <Navigation />
      <Styled.IconMap>
        <img src={Payment} alt="payment" />
        <div>
          {paymentTick?.map((item, index) => {
            return <img key={index} src={item?.img} />;
          })}
        </div>
      </Styled.IconMap>
      <Styled.ResultPayment className="container">
        <Styled.Result>
          <div className="payment">{browseData.success} </div>
          <Styled.Message>
            <div className="tick">
              <ResultTick />
            </div>
            <div className="success">{browseData.team} </div>
            <div className="receive">
              <p className="after">{browseData.contacted}</p>
            </div>
          </Styled.Message>
        </Styled.Result>
      </Styled.ResultPayment>
      <Styled.Confirm>
        <div>
          <OrderDetails />
        </div>
      </Styled.Confirm>
      <Footer />
    </>
  );
};

export default PaymentSoleTrader;
