import * as Styled from "./style";
import Card from "../Cards/Card";
import React from "react";
import TrainingCourseLogo from "../../../assets/Svg/TrainingCourseLogo.svg";
import trainingLogo from "../../../assets/Svg/trainingLogo.svg";
import { BsArrowDown } from "react-icons/bs";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { IoMdCall, IoMdMail } from "react-icons/io";
import { useCourse } from "../../../hooks/CourseHook.js";

import {
  tabData,
  trainingConstant,
  courseTab,
  CoursesCardConstant,
} from "./data";
const AVSECCoursesCard = ({
  isPageOpen = false,
  iswidth,
  handleClickCourse,
}) => {
  const { courses, handleClick } = useCourse();

  return (
    <>
      <Styled.AboutContentContainer iswidth={iswidth}>
        {isPageOpen && (
          <Styled.BackData onClick={handleClickCourse}>
            <FaLongArrowAltLeft className="icon" />
            <Styled.Back>{trainingConstant.Back}</Styled.Back>
          </Styled.BackData>
        )}

        <Styled.TabContainer>
          <Styled.Title>
            {trainingConstant.heading}
            <Styled.Border />
          </Styled.Title>
          <Styled.Tabs>
            {tabData?.map((item, index) => {
              courseTab.push(index);
              return (
                <Styled.NavLinks to={courseTab[index]}>
                  <div key={index} className="label">
                    {item?.label}
                  </div>
                </Styled.NavLinks>
              );
            })}
          </Styled.Tabs>
        </Styled.TabContainer>
        <Styled.BrowseTraining>
          <Styled.CardsContainer>
            {courses
              ?.filter((items) => items?.type === "AVSEC")
              ?.map((items, index) => {
                return (
                  <>
                    <Card
                      data={items}
                      children={
                        <>
                          <Styled.Content key={index}>
                            <Styled.Label>
                              {CoursesCardConstant?.courseName}
                            </Styled.Label>
                            <Styled.Value>{items?.location}</Styled.Value>
                          </Styled.Content>
                          <Styled.Content>
                            <Styled.Label>
                              {CoursesCardConstant?.priceName}
                            </Styled.Label>
                            <Styled.ValuePrice>
                              <span>{CoursesCardConstant?.pricesymbol}</span>
                              {items?.price}
                            </Styled.ValuePrice>
                          </Styled.Content>
                          <Styled.Line />

                          <Styled.CardBottom>
                            <>
                              {items.id === 18 || items.id === 19 ? (
                                <button className="button_style">
                                  {CoursesCardConstant?.buttonName}
                                  <BsArrowDown className="btn_icon" />
                                </button>
                              ) : (
                                <>
                                  {/* <Styled.CartIcon key={index}>
                                    {CoursesCardConstant?.shoppingCart}
                                  </Styled.CartIcon> */}

                                  <button
                                    className="btn_style"
                                    onClick={() => handleClick(items.slug)}
                                  >
                                    {CoursesCardConstant?.Browse}
                                  </button>
                                </>
                              )}
                            </>
                          </Styled.CardBottom>
                        </>
                      }
                    />
                  </>
                );
              })}
          </Styled.CardsContainer>
        </Styled.BrowseTraining>
      </Styled.AboutContentContainer>
      <Styled.DifferentContainers>
        <div className="container" style={{ width: "88%" }}>
          <Styled.RegulatedCourseHeader>
            <img src={trainingLogo} alt="logo" />
            <Styled.TitleLine>
              <Styled.Title>{trainingConstant.Regulated}</Styled.Title>
              <Styled.Border />
            </Styled.TitleLine>
          </Styled.RegulatedCourseHeader>
          <Styled.RegulatedCourseContent>
            Our Regulated Agent Courses cover <b>all levels of training </b>
            required for those who have access to and are involved in the
            handling, screening, processing, and transportation of secure air
            cargo and for those responsible for the supervising and managing of
            these duties. <br /> <br />
            Classroom-based courses can be delivered at our
            <b> AVTAV Training Centres</b>, at our client’s premises or via our
            e-learning platform.
          </Styled.RegulatedCourseContent>
          <Styled.RegulateMap>
            <Styled.Items>
              {courses
                ?.filter((item) => item?.type === "Regulated agent")
                ?.map((items, index) => {
                  return (
                    <Card
                      data={items}
                      children={
                        <>
                          <Styled.CardText
                            key={index}
                            className="cardTitleAlignment"
                          >
                            {items?.description}
                          </Styled.CardText>
                          <Styled.NumberBlock>
                            <Styled.CardNumber>
                              <IoMdCall className="call" />
                              {items?.contactPhone}
                            </Styled.CardNumber>
                            <Styled.Lined />
                            <Styled.PhoneNumber>
                              {items?.contactMobile}
                            </Styled.PhoneNumber>
                          </Styled.NumberBlock>
                          <Styled.Email>
                            <IoMdMail className="mail" /> {items?.contactEmail}
                          </Styled.Email>

                          <Styled.CardButton>
                            <button
                              className="btn"
                              onClick={() => handleClick(items.slug)}
                            >
                              {CoursesCardConstant?.Browse}
                            </button>
                          </Styled.CardButton>
                        </>
                      }
                    />
                  );
                })}
            </Styled.Items>

            <Styled.TrainingLogo>
              <img
                src={TrainingCourseLogo}
                alt="Avtav Training"
                width={393}
                height={69}
              />
            </Styled.TrainingLogo>
          </Styled.RegulateMap>
        </div>
      </Styled.DifferentContainers>
      <Styled.DifferentColorContainers>
        <div className="container" style={{ width: "88%" }}>
          <Styled.RegulatedCourseHeader>
            <img src={trainingLogo} alt="logo" />
            <Styled.TitleLine>
              <Styled.Title>{trainingConstant.Consignor}</Styled.Title>
              <Styled.Border />
            </Styled.TitleLine>
          </Styled.RegulatedCourseHeader>
          <Styled.RegulatedCourseContent>
            Our Known Consignor Courses cover all levels of training required
            for those who have access to and are involved in the handling,
            screening, processing, and transportation of secure air cargo and
            for those responsible for the supervising and managing of these
            duties. <br /> <br />
            Classroom-based courses can be delivered at our
            <b> AVTAV Training Centres</b>, at our client’s premises or via our
            e-learning platform.
          </Styled.RegulatedCourseContent>

          <Styled.RegulateMap>
            <Styled.Items>
              {courses
                ?.filter((item) => item?.type === "Known Consignor")
                ?.map((items, index) => {
                  return (
                    <Card
                      data={items}
                      children={
                        <>
                          <Styled.CardText key={index}>
                            {items?.description}
                          </Styled.CardText>
                          <Styled.NumberBlock>
                            <Styled.CardNumber>
                              <IoMdCall className="call" />
                              {items?.contactPhone}
                            </Styled.CardNumber>
                            <Styled.Lined />
                            <Styled.PhoneNumber>
                              {items?.contactMobile}
                            </Styled.PhoneNumber>
                          </Styled.NumberBlock>
                          <Styled.Email>
                            <IoMdMail className="mail" /> {items?.contactEmail}
                          </Styled.Email>

                          <Styled.CardButton>
                            {index !== 0 && CoursesCardConstant?.btn ? (
                              <button
                                className="btn"
                                onClick={() => handleClick(items.slug)}
                              >
                                {CoursesCardConstant?.Browse}
                              </button>
                            ) : (
                              <button
                                className="btnHide"
                                onClick={() => handleClick(items.slug)}
                              >
                                {CoursesCardConstant?.Browse}
                              </button>
                            )}
                          </Styled.CardButton>
                        </>
                      }
                    />
                  );
                })}
            </Styled.Items>
            <Styled.TrainingLogo>
              <img
                src={TrainingCourseLogo}
                alt="Avtav Training"
                width={393}
                height={69}
              />
            </Styled.TrainingLogo>
          </Styled.RegulateMap>
        </div>
      </Styled.DifferentColorContainers>
      <Styled.DifferentContainers id={courseTab[0]}>
        <div className="container" style={{ width: "88%" }}>
          <Styled.RegulatedCourseHeader>
            <img src={trainingLogo} alt="logo" />
            <Styled.TitleLine>
              <Styled.Title>{trainingConstant.Transport}</Styled.Title>
              <Styled.Border />
            </Styled.TitleLine>
          </Styled.RegulatedCourseHeader>

          <Styled.RegulateMap>
            <Styled.Items>
              {courses
                ?.filter((item) => item?.type === "Transport")
                ?.map((items, index) => {
                  return (
                    <Card
                      data={items}
                      children={
                        <>
                          <Styled.CardText
                            key={index}
                            className="cardTitleAlignment"
                          >
                            {items?.description}
                          </Styled.CardText>
                          <Styled.NumberBlock>
                            <Styled.CardNumber>
                              <IoMdCall className="call" />
                              {items?.contactPhone}
                            </Styled.CardNumber>
                            <Styled.Lined />
                            <Styled.PhoneNumber>
                              {items?.contactMobile}
                            </Styled.PhoneNumber>
                          </Styled.NumberBlock>
                          <Styled.Email>
                            <IoMdMail className="mail" /> {items?.contactEmail}
                          </Styled.Email>
                          <Styled.CardButton>
                            <button
                              className="btn"
                              onClick={() => handleClick(items.slug)}
                            >
                              {CoursesCardConstant?.Browse}
                            </button>
                          </Styled.CardButton>
                        </>
                      }
                    />
                  );
                })}
            </Styled.Items>
            <Styled.TrainingLogo>
              <img
                src={TrainingCourseLogo}
                alt="Avtav Training"
                width={393}
                height={69}
              />
            </Styled.TrainingLogo>
          </Styled.RegulateMap>
        </div>
      </Styled.DifferentContainers>
      <Styled.DifferentColorContainers id={courseTab[1]}>
        <div className="container" style={{ width: "88%" }}>
          <Styled.RegulatedCourseHeader>
            <Styled.TitleLine>
              <Styled.Title>{trainingConstant.Industrial}</Styled.Title>
              <Styled.Border />
            </Styled.TitleLine>
          </Styled.RegulatedCourseHeader>

          <Styled.RegulateMap>
            <Styled.Items>
              {courses
                ?.filter((item) => item?.type === "Industrial")
                ?.map((items, index) => {
                  return (
                    <Card
                      data={items}
                      children={
                        <>
                          <Styled.CardText
                            key={index}
                            className="cardTitleAlignment"
                          >
                            {items?.description}
                          </Styled.CardText>
                          <Styled.NumberBlock>
                            <Styled.CardNumber>
                              <IoMdCall className="call" />
                              {items?.contactPhone}
                            </Styled.CardNumber>
                            <Styled.Lined />
                            <Styled.PhoneNumber>
                              {items?.contactMobile}
                            </Styled.PhoneNumber>
                          </Styled.NumberBlock>
                          <Styled.Email>
                            <IoMdMail className="mail" /> {items?.contactEmail}
                          </Styled.Email>
                        </>
                      }
                    />
                  );
                })}
            </Styled.Items>
            <Styled.TrainingLogo>
              <img
                src={TrainingCourseLogo}
                alt="Avtav Training"
                width={393}
                height={69}
              />
            </Styled.TrainingLogo>
          </Styled.RegulateMap>
        </div>
      </Styled.DifferentColorContainers>
      <Styled.DifferentContainers id={courseTab[2]}>
        <div className="container" style={{ width: "88%" }}>
          <Styled.RegulatedCourseHeader>
            <Styled.TitleLine>
              <Styled.Title>{trainingConstant.Compliance}</Styled.Title>
              <Styled.Border />
            </Styled.TitleLine>
          </Styled.RegulatedCourseHeader>

          <Styled.RegulateMap>
            <Styled.Items>
              {courses
                ?.filter((item) => item?.type === "General Compliance")
                ?.map((items, index) => {
                  return (
                    <Card
                      data={items}
                      children={
                        <>
                          <Styled.CardText
                            key={index}
                            className="cardTitleAlignment"
                          >
                            {items?.description}
                          </Styled.CardText>
                          <Styled.NumberBlock>
                            <Styled.CardNumber>
                              <IoMdCall className="call" />{" "}
                              {items?.contactPhone}
                            </Styled.CardNumber>
                            <Styled.Lined />
                            <Styled.PhoneNumber>
                              {items?.contactMobile}
                            </Styled.PhoneNumber>
                          </Styled.NumberBlock>
                          <Styled.Email>
                            <IoMdMail className="mail" /> {items?.contactEmail}
                          </Styled.Email>
                        </>
                      }
                    />
                  );
                })}
            </Styled.Items>
            <Styled.TrainingLogo>
              <img
                src={TrainingCourseLogo}
                alt="Avtav Training"
                width={393}
                height={69}
              />
            </Styled.TrainingLogo>
          </Styled.RegulateMap>
        </div>
      </Styled.DifferentContainers>
      <Styled.DifferentColorContainers id={courseTab[3]}>
        <div className="container" style={{ width: "88%" }}>
          <Styled.RegulatedCourseHeader>
            <Styled.TitleLine>
              <Styled.Title>{trainingConstant.Management}</Styled.Title>
              <Styled.Border />
            </Styled.TitleLine>
          </Styled.RegulatedCourseHeader>

          <Styled.RegulateMap>
            <Styled.Items>
              {courses
                ?.filter((item) => item?.type === "General Management")
                ?.map((items, index) => {
                  return (
                    <Card
                      data={items}
                      children={
                        <>
                          <Styled.CardText
                            key={index}
                            className="cardTitleAlignment"
                          >
                            {items?.description}
                          </Styled.CardText>
                          <Styled.NumberBlock>
                            <Styled.CardNumber>
                              <IoMdCall className="call" />{" "}
                              {items?.contactPhone}
                            </Styled.CardNumber>
                            <Styled.Lined />
                            <Styled.PhoneNumber>
                              {items?.contactMobile}
                            </Styled.PhoneNumber>
                          </Styled.NumberBlock>
                          <Styled.Email>
                            <IoMdMail className="mail" /> {items?.contactEmail}
                          </Styled.Email>
                        </>
                      }
                    />
                  );
                })}
            </Styled.Items>
            <Styled.TrainingLogo>
              <img
                src={TrainingCourseLogo}
                alt="Avtav Training"
                width={393}
                height={69}
              />
            </Styled.TrainingLogo>
          </Styled.RegulateMap>
        </div>
      </Styled.DifferentColorContainers>
      <Styled.DifferentContainers id={courseTab[4]}>
        <div className="container" style={{ width: "88%" }}>
          <Styled.RegulatedCourseHeader>
            <Styled.TitleLine>
              <Styled.Title>{trainingConstant.Wellbeing}</Styled.Title>
              <Styled.Border />
            </Styled.TitleLine>
          </Styled.RegulatedCourseHeader>

          <Styled.RegulateMap>
            <Styled.Items>
              {courses
                ?.filter((item) => item?.type === "Wellbeing")
                ?.map((items, index) => {
                  return (
                    <Card
                      data={items}
                      children={
                        <>
                          <Styled.CardText
                            key={index}
                            className="cardTitleAlignment"
                          >
                            {items?.description}
                          </Styled.CardText>
                          <Styled.NumberBlock>
                            <Styled.CardNumber>
                              <IoMdCall className="call" />{" "}
                              {items?.contactPhone}
                            </Styled.CardNumber>
                            <Styled.Lined />
                            <Styled.PhoneNumber>
                              {items?.contactMobile}
                            </Styled.PhoneNumber>
                          </Styled.NumberBlock>
                          <Styled.Email>
                            <IoMdMail className="mail" /> {items?.contactEmail}
                          </Styled.Email>
                        </>
                      }
                    />
                  );
                })}
            </Styled.Items>
            <Styled.TrainingLogo>
              <img
                src={TrainingCourseLogo}
                alt="Avtav Training"
                width={393}
                height={69}
              />
            </Styled.TrainingLogo>
          </Styled.RegulateMap>
        </div>
      </Styled.DifferentContainers>
    </>
  );
};

export default AVSECCoursesCard;
