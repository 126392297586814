import * as Styled from "./style";
import React from "react";
import { Modal, Progress } from "antd";
import { ModalText } from "./data";
import GSATLOGO from "../../../assets/image/GSATLOGO.png";

export const ProgressModal = ({ modal, setModal }) => {
  const modules = modal?.moduleName?.split(",");
  return (
    <div>
      <Styled.ModalBox>
        <Modal
          footer={null}
          className={"modalStyle"}
          centered
          open={modal}
          onOk={() => setModal(false)}
          onCancel={() => setModal(false)}
          width={850}
          closeIcon={false}
        >
          <Styled.HeaderContainer>
            <div>
              <Styled.ProgressText>
                {ModalText.ProgressStats}
              </Styled.ProgressText>
              <Styled.ShaunText>{modal?.trainee_name} </Styled.ShaunText>
            </div>
            <Progress
              percent={modal?.course_progress}
              className="progressBar"
              trailColor={"white"}
              strokeColor={"rgba(4, 136, 182, 1)"}
              percentPosition={{ align: "end" }}
            />
          </Styled.HeaderContainer>
          <Styled.MainBoxContainer>
            <Styled.CoursesBox>
              <div>
                <div className="course">Course name</div>
                <div className="courseName">{modal?.courseName}</div>
              </div>
              <div>
                <img src={GSATLOGO} alt="GSATLOGO" />
              </div>
            </Styled.CoursesBox>
            <Styled.ListContainer>
              <div className="boxContainer1">
                {modules?.map((item, index) => {
                  return (
                    <Styled.CoursesList>
                      <div>
                        <span className="heading">{`Module ${index + 1}`}</span>
                        <br />
                        <span className="text">{item} </span>
                        {/* <br />
                        <span>{item?.time}</span> */}
                      </div>
                      <div className="completeBox">
                        {/* <input type="checkbox" className="checkBox" /> */}
                        {/* <span className="heading">{item?.text}</span> */}
                      </div>
                    </Styled.CoursesList>
                  );
                })}
              </div>
              <Styled.RightBox>
                <div className="boxContainer">
                  <span className="numberText">NUMBER OF MODULES</span>
                  <br />
                  <span className="num">2 </span>
                  <span className="numBox">{`OF ${modules.length}`}</span>
                  <br />
                  <span className="numberText">COMPLETED</span>
                </div>
              </Styled.RightBox>
            </Styled.ListContainer>
          </Styled.MainBoxContainer>

          <Styled.Text>{ModalText.Stats}</Styled.Text>
        </Modal>
      </Styled.ModalBox>
    </div>
  );
};
