import React, { useLayoutEffect, useRef } from "react";
import "./style.css";

const Slider = () => {
  const avoidRerender = useRef(false);
  useLayoutEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = () => {
          resolve();
        };
        script.onerror = () => {
          console.error(`Failed to load script: ${src}`);
          reject(new Error(`Failed to load script ${src}`));
        };
        document.body.appendChild(script);
      });
    };

    const loadAndInitializeSlider = async () => {
      try {
        await loadScript(
          "https://avtav.co.uk/wp-includes/js/jquery/jquery.min.js?ver=3.6.0"
        );
        await loadScript(
          "https://avtav.co.uk/wp-content/plugins/revslider/public/assets/js/rs6.min.js?ver=6.5.2"
        );
        await loadScript(
          "https://avtav.co.uk/wp-content/plugins/revslider/public/assets/js/rbtools.min.js?ver=6.5.2"
        );
        let revapi10;
        if (window.jQuery && window.jQuery.fn) {
          revapi10 = window.jQuery("#rev_slider_10_1");
          if (revapi10 === undefined || revapi10.revolution === undefined) {
            console.error("Revolution Slider initialization failed");
            return;
          }
          revapi10.revolutionInit({
            DPR: "dpr",
            sliderLayout: "fullscreen",
            duration: "3000ms",
            visibilityLevels: "1240,1024,778,480",
            gridwidth: "1240,1024,778,480",
            gridheight: "950,900,900,800",
            lazyType: "smart",
            perspective: 600,
            perspectiveType: "local",
            editorheight: "950,900,900,800",
            responsiveLevels: "1240,1024,778,480",
            fullScreenOffsetContainer: "#masthead",
            progressBar: { disableProgressBar: true },
            navigation: {
              wheelCallDelay: 1000,
              onHoverStop: false,
              arrows: {
                enable: true,
                style: "uranus",
                hide_onmobile: true,
                hide_under: 778,
                hide_onleave: true,
                left: {
                  h_offset: 28,
                  v_offset: 1,
                },
                right: {
                  h_offset: 30,
                },
              },
              bullets: {
                enable: true,
                tmp: "",
                style: "light-bars",
                v_offset: 30,
                space: 10,
              },
            },
            parallax: {
              levels: [
                5, 10, 15, 20, 25, 30, 35, 40, 45, 46, -5, -10, -15, -20, -25,
                30,
              ],
              type: "scroll",
              origo: "slideCenter",
              speed: 0,
              speedls: "1000ms",
            },
            viewPort: {
              global: true,
              globalDist: "-200px",
              enable: false,
              visible_area: "50px",
            },
            fallbacks: {
              allowHTML5AutoPlayOnAndroid: true,
            },
          });
          console.log("Slider initialized");
        }
        if (window.RS_MODULES && window.RS_MODULES.checkMinimal !== undefined) {
          window.RS_MODULES.checkMinimal();
        }
      } catch (error) {
        console.error("Failed to load scripts:", error);
      }
    };
    if (!avoidRerender.current) {
      loadAndInitializeSlider();
    }
    return () => (avoidRerender.current = true);
  }, []);
  return (
    <>
      <div id="wrapper-container" className="wrapper-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width={0}
          height={0}
          focusable="false"
          role="none"
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "-9999px",
            overflow: "hidden",
          }}
        >
          <defs>
            <filter id="wp-duotone-dark-grayscale">
              <feColorMatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <feComponentTransfer colorInterpolationFilters="sRGB">
                <feFuncR type="table" tableValues="0 0.49803921568627" />
                <feFuncG type="table" tableValues="0 0.49803921568627" />
                <feFuncB type="table" tableValues="0 0.49803921568627" />
                <feFuncA type="table" tableValues="1 1" />
              </feComponentTransfer>
              <feComposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width={0}
          height={0}
          focusable="false"
          role="none"
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "-9999px",
            overflow: "hidden",
          }}
        >
          <defs>
            <filter id="wp-duotone-grayscale">
              <feColorMatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <feComponentTransfer colorInterpolationFilters="sRGB">
                <feFuncR type="table" tableValues="0 1" />
                <feFuncG type="table" tableValues="0 1" />
                <feFuncB type="table" tableValues="0 1" />
                <feFuncA type="table" tableValues="1 1" />
              </feComponentTransfer>
              <feComposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width={0}
          height={0}
          focusable="false"
          role="none"
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "-9999px",
            overflow: "hidden",
          }}
        >
          <defs>
            <filter id="wp-duotone-purple-yellow">
              <feColorMatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <feComponentTransfer colorInterpolationFilters="sRGB">
                <feFuncR
                  type="table"
                  tableValues="0.54901960784314 0.98823529411765"
                />
                <feFuncG type="table" tableValues="0 1" />
                <feFuncB
                  type="table"
                  tableValues="0.71764705882353 0.25490196078431"
                />
                <feFuncA type="table" tableValues="1 1" />
              </feComponentTransfer>
              <feComposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width={0}
          height={0}
          focusable="false"
          role="none"
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "-9999px",
            overflow: "hidden",
          }}
        >
          <defs>
            <filter id="wp-duotone-blue-red">
              <feColorMatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <feComponentTransfer colorInterpolationFilters="sRGB">
                <feFuncR type="table" tableValues="0 1" />
                <feFuncG type="table" tableValues="0 0.27843137254902" />
                <feFuncB
                  type="table"
                  tableValues="0.5921568627451 0.27843137254902"
                />
                <feFuncA type="table" tableValues="1 1" />
              </feComponentTransfer>
              <feComposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width={0}
          height={0}
          focusable="false"
          role="none"
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "-9999px",
            overflow: "hidden",
          }}
        >
          <defs>
            <filter id="wp-duotone-midnight">
              <feColorMatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <feComponentTransfer colorInterpolationFilters="sRGB">
                <feFuncR type="table" tableValues="0 0" />
                <feFuncG type="table" tableValues="0 0.64705882352941" />
                <feFuncB type="table" tableValues="0 1" />
                <feFuncA type="table" tableValues="1 1" />
              </feComponentTransfer>
              <feComposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width={0}
          height={0}
          focusable="false"
          role="none"
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "-9999px",
            overflow: "hidden",
          }}
        >
          <defs>
            <filter id="wp-duotone-magenta-yellow">
              <feColorMatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <feComponentTransfer colorInterpolationFilters="sRGB">
                <feFuncR type="table" tableValues="0.78039215686275 1" />
                <feFuncG type="table" tableValues="0 0.94901960784314" />
                <feFuncB
                  type="table"
                  tableValues="0.35294117647059 0.47058823529412"
                />
                <feFuncA type="table" tableValues="1 1" />
              </feComponentTransfer>
              <feComposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width={0}
          height={0}
          focusable="false"
          role="none"
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "-9999px",
            overflow: "hidden",
          }}
        >
          <defs>
            <filter id="wp-duotone-purple-green">
              <feColorMatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <feComponentTransfer colorInterpolationFilters="sRGB">
                <feFuncR
                  type="table"
                  tableValues="0.65098039215686 0.40392156862745"
                />
                <feFuncG type="table" tableValues="0 1" />
                <feFuncB type="table" tableValues="0.44705882352941 0.4" />
                <feFuncA type="table" tableValues="1 1" />
              </feComponentTransfer>
              <feComposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 0 0"
          width={0}
          height={0}
          focusable="false"
          role="none"
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "-9999px",
            overflow: "hidden",
          }}
        >
          <defs>
            <filter id="wp-duotone-blue-orange">
              <feColorMatrix
                colorInterpolationFilters="sRGB"
                type="matrix"
                values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
              />
              <feComponentTransfer colorInterpolationFilters="sRGB">
                <feFuncR type="table" tableValues="0.098039215686275 1" />
                <feFuncG type="table" tableValues="0 0.66274509803922" />
                <feFuncB
                  type="table"
                  tableValues="0.84705882352941 0.41960784313725"
                />
                <feFuncA type="table" tableValues="1 1" />
              </feComponentTransfer>
              <feComposite in2="SourceGraphic" operator="in" />
            </filter>
          </defs>
        </svg>

        <div id="apus-main-content">
          <section
            id="main-container"
            className="wrapper-main-page container-fluid inner"
          >
            <div className="row">
              <div id="main-content" className="main-page col-xs-12 clearfix">
                <div id="main" className="site-main clearfix" role="main">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id={2837}
                    className="elementor elementor-2837"
                  >
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-fd2221f elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                      data-id="fd2221f"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-default">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-02a6a83"
                          data-id="02a6a83"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-c0f6287 elementor-widget elementor-widget-wp-widget-rev-slider-widget"
                              data-id="c0f6287"
                              data-element_type="widget"
                              data-widget_type="wp-widget-rev-slider-widget.default"
                            >
                              <div className="elementor-widget-container">
                                {/* START AVTAV1 1 REVOLUTION SLIDER 6.5.2 */}
                                <p className="rs-p-wp-fix" />
                                <rs-module-wrap
                                  id="rev_slider_10_1_wrapper"
                                  data-source="gallery"
                                  style={{
                                    visibility: "hidden",
                                    background: "transparent",
                                    padding: 0,
                                  }}
                                >
                                  <rs-module
                                    id="rev_slider_10_1"
                                    className="rs-respect-topbar"
                                    style={{}}
                                    data-version="6.5.2"
                                  >
                                    <rs-slides>
                                      <rs-slide
                                        data-key="rs-46"
                                        data-title="Slide"
                                        data-thumb="//avtav.co.uk/wp-content/uploads/2021/07/Screenshot-2021-07-12-at-11.24.04.png"
                                        data-in="o:0;"
                                        data-out="a:false;"
                                      >
                                        <img
                                          src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                          alt="Slide"
                                          title="Homepage"
                                          className="rev-slidebg tp-rs-img rs-lazyload"
                                          data-lazyload="//avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/transparent.png"
                                          data-bg="c:#17374d;"
                                          data-parallax="off"
                                          data-no-retina=""
                                        />
                                        <rs-zone
                                          id="rrzt_46"
                                          className="rev_row_zone_top"
                                          style={{ zIndex: 21 }}
                                        >
                                          <rs-row
                                            id="slider-10-slide-46-layer-0"
                                            data-type="row"
                                            data-xy="xo:50px;yo:50px;"
                                            data-rsp_o="off"
                                            data-rsp_bd="off"
                                            data-margin="t:100,100,100,50;r:20,90,25,10;b:100,200,200,100;l:20,50,25,10;"
                                            data-border="boc:#ffffff;bow:2px,0,0,0;"
                                            data-frame_999="o:1;st:w;sR:5700;sA:3000;"
                                            style={{ zIndex: 1 }}
                                          >
                                            <rs-column
                                              id="slider-10-slide-46-layer-1"
                                              data-type="column"
                                              data-xy="xo:50px;yo:50px;"
                                              data-rsp_o="off"
                                              data-rsp_bd="off"
                                              data-column="w:100%;"
                                              data-frame_999="o:1;st:w;sR:5700;sA:3000;"
                                              style={{
                                                zIndex: 2,
                                                width: "100%",
                                              }}
                                            >
                                              {/*
                                               */}
                                              <rs-layer
                                                id="slider-10-slide-46-layer-2"
                                                data-type="shape"
                                                data-dim="w:100%;h:2px;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-frame_0="x:-100%;o:1;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:500;sp:2000;sR:500;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:3500;auto:true;"
                                                style={{
                                                  zIndex: 3,
                                                  backgroundColor: "#ffffff",
                                                }}
                                              ></rs-layer>

                                              <rs-layer
                                                id="slider-10-slide-46-layer-8"
                                                data-type="text"
                                                data-text="s:60,60,20,20;l:60,60,20,20;fw:500;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-margin="t:90,90,25,25;b:45,45,25,25;"
                                                data-frame_0="o:1;"
                                                data-frame_0_chars="d:5;x:-105%;o:0;rZ:-90deg;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:300;sp:1200;sR:300;"
                                                data-frame_1_chars="e:power4.inOut;dir:backward;d:3;rZ:0deg;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:4350;auto:true;"
                                                style={{
                                                  zIndex: 4,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                AVTAV
                                              </rs-layer>

                                              <rs-layer
                                                id="slider-10-slide-46-layer-5"
                                                data-type="text"
                                                data-text="w:nowrap,normal,normal,normal;s:80,80,40,30;l:90,100,45,40;ls:2px,2px,-1px,2px;fw:700,700,600,600;"
                                                data-dim="w:100%;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-margin="r:20,20,20,35;"
                                                data-padding="t:10;"
                                                data-frame_0="o:1;"
                                                data-frame_0_chars="d:5;y:100%;o:0;rZ:-35deg;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:400;sp:1200;sR:400;"
                                                data-frame_1_chars="e:power4.inOut;d:5;rZ:0deg;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:2500;auto:true;"
                                                style={{
                                                  zIndex: 5,
                                                  fontFamily: "Poppins",
                                                  wordBreak:
                                                    "break-all !important",
                                                }}
                                              >
                                                Training, Vetting <br /> and
                                                Recruitment <br /> services
                                              </rs-layer>
                                            </rs-column>
                                          </rs-row>

                                          <rs-row
                                            id="slider-10-slide-46-layer-17"
                                            data-type="row"
                                            data-xy="xo:50px;yo:184.44444274902344px;"
                                            data-rsp_o="off"
                                            data-rsp_bd="off"
                                            data-margin="t:-200,-200,-100,-50;"
                                            data-padding="t:10;r:10,10,10,0;b:10;l:10,10,10,0;"
                                            data-frame_0="o:1;"
                                            data-frame_999="o:0;st:w;sA:3000;"
                                            style={{
                                              zIndex: 6,
                                              marginTop: "-40px !important",
                                            }}
                                          >
                                            <rs-column
                                              id="slider-10-slide-46-layer-18"
                                              data-type="column"
                                              data-xy="xo:50px;yo:184.44444274902344px;"
                                              data-text="a:center;"
                                              data-rsp_o="off"
                                              data-rsp_bd="off"
                                              data-column="a:middle;"
                                              data-padding="t:10;r:10;b:10;l:50,50,10,10;"
                                              data-frame_0="o:1;"
                                              data-frame_999="o:0;st:w;sA:3000;"
                                              style={{
                                                zIndex: 7,
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <rs-layer
                                                id="slider-10-slide-46-layer-15"
                                                data-type="image"
                                                data-xy="xo:0,357px,285px,30px;yo:0,237px,24px,150px;"
                                                data-text="w:normal;"
                                                data-dim="w:300px,300px,100%,100%;h:142px,142px,200px,200px;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-frame_1="sp:3650;"
                                                data-frame_999="o:0;st:w;sp:2370;sR:2350;"
                                                style={{ zIndex: 8 }}
                                              >
                                                <img
                                                  loading="lazy"
                                                  src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                                  className="tp-rs-img rs-lazyload"
                                                  width={300}
                                                  height={142}
                                                  data-lazyload="//avtav.co.uk/wp-content/uploads/2022/02/AV-AVTAV-VETTING-Logos.png"
                                                  data-no-retina=""
                                                />
                                              </rs-layer>
                                              {/*
                                               */}
                                            </rs-column>
                                            {/*

									*/}
                                            <rs-column
                                              id="slider-10-slide-46-layer-19"
                                              data-type="column"
                                              data-xy="xo:50px;yo:184.44444274902344px;"
                                              data-rsp_o="off"
                                              data-rsp_bd="off"
                                              data-padding="t:10;r:10;b:10;l:50,50,10,10;"
                                              data-frame_0="o:1;"
                                              data-frame_999="o:0;st:w;sA:3000;"
                                              style={{
                                                zIndex: 9,
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              {/*
                                               */}
                                              <rs-layer
                                                id="slider-10-slide-46-layer-10"
                                                data-type="image"
                                                data-xy="xo:0,49px,51px,31px;yo:0,236px,23px,33px;"
                                                data-text="w:normal;"
                                                data-dim="w:300px,300px,100%,100%;h:142px,142px,200px,200px;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-frame_1="sp:3640;"
                                                data-frame_999="o:0;st:w;sp:2600;sR:2360;"
                                                style={{
                                                  zIndex: 10,
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <img
                                                  loading="lazy"
                                                  src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                                  className="tp-rs-img rs-lazyload"
                                                  width={300}
                                                  height={142}
                                                  data-lazyload="//avtav.co.uk/wp-content/uploads/2022/02/AT-AVTAV-TRAINING-Logos.png"
                                                  data-no-retina=""
                                                />
                                              </rs-layer>
                                              {/*
                                               */}
                                            </rs-column>
                                            {/*

									*/}
                                            <rs-column
                                              id="slider-10-slide-46-layer-20"
                                              data-type="column"
                                              data-xy="xo:50px;yo:184.44444274902344px;"
                                              data-rsp_o="off"
                                              data-rsp_bd="off"
                                              data-padding="t:10;r:10;b:10;l:50,50,10,10;"
                                              data-frame_0="o:1;"
                                              data-frame_999="o:0;st:w;sA:3000;"
                                              style={{
                                                zIndex: 11,
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              {/*
                                               */}
                                              <rs-layer
                                                id="slider-10-slide-46-layer-16"
                                                data-type="image"
                                                data-xy="xo:0,666px,516px,31px;yo:0,237px,26px,269px;"
                                                data-text="w:normal;"
                                                data-dim="w:300px,300px,100%,100%;h:142px,142px,200px,200px;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-frame_1="sp:3620;"
                                                data-frame_999="o:0;st:w;sp:2830;sR:2380;"
                                                style={{
                                                  zIndex: 12,
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <img
                                                  loading="lazy"
                                                  src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                                  className="tp-rs-img rs-lazyload"
                                                  width={300}
                                                  height={142}
                                                  data-lazyload="//avtav.co.uk/wp-content/uploads/2022/02/AR-AVTAV-Recruitment-Logos.png"
                                                  data-no-retina=""
                                                />
                                              </rs-layer>
                                              {/*
                                               */}
                                            </rs-column>
                                            {/*
                                             */}
                                          </rs-row>
                                          {/*
                                           */}
                                        </rs-zone>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-46-layer-6"
                                          className="rs-pxl-2"
                                          data-type="image"
                                          data-xy="x:r;xo:248px,262px,206px,46px;y:b;yo:350px,439px,433px,412px;"
                                          data-text="s:20,16,12,7;l:0,20,15,9;"
                                          data-dim="w:646px,533px,404px,200px;h:645.997px,532px,404px,200px;"
                                          data-border="bor:200px,200px,200px,200px;"
                                          data-clip="u:true;t:circle;o:clr;"
                                          data-frame_0="o:1;cp:0%;"
                                          data-frame_1="e:power4.inOut;st:800;sp:2000;sR:800;cp:50%;"
                                          data-frame_999="st:w;sp:600;sR:3200;auto:true;"
                                          style={{ zIndex: 19 }}
                                        >
                                          <img
                                            loading="lazy"
                                            src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                            className="tp-rs-img rs-lazyload"
                                            width={1600}
                                            height={1600}
                                            data-lazyload="//avtav.co.uk/wp-content/uploads/2021/07/vesctor-fly.png"
                                            data-no-retina=""
                                          />
                                        </rs-layer>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-46-layer-7"
                                          className="rs-pxl-12"
                                          data-type="image"
                                          data-xy="x:r;xo:0,0,-80px,-198px;y:m;yo:80px,0,117px,47px;"
                                          data-text="s:20,16,12,7;l:0,20,15,9;"
                                          data-dim="w:447px,369px,280px,400px;h:446.997px,368px,279px,400px;"
                                          data-border="bor:400px,400px,400px,400px;"
                                          data-clip="u:true;t:circle;o:clr;"
                                          data-frame_0="o:1;cp:0%;"
                                          data-frame_1="e:power4.inOut;st:700;sp:2000;sR:700;cp:50%;"
                                          data-frame_999="st:w;sp:600;sR:3300;auto:true;"
                                          style={{ zIndex: 17 }}
                                        >
                                          <img
                                            loading="lazy"
                                            src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                            className="tp-rs-img rs-lazyload"
                                            width={1600}
                                            height={1600}
                                            data-lazyload="//avtav.co.uk/wp-content/uploads/2021/07/world-map.png"
                                            data-no-retina=""
                                          />
                                        </rs-layer>
                                        {/*
                                         */}{" "}
                                      </rs-slide>
                                      <rs-slide
                                        data-key="rs-43"
                                        data-title="Slide"
                                        data-thumb="//avtav.co.uk/wp-content/uploads/2021/07/Screenshot-2021-07-12-at-11.24.04.png"
                                        data-in="o:0;"
                                        data-out="a:false;"
                                      >
                                        <img
                                          src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                          alt="Slide"
                                          title="Homepage"
                                          className="rev-slidebg tp-rs-img rs-lazyload"
                                          data-lazyload="//avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/transparent.png"
                                          data-bg="c:#17374d;"
                                          data-parallax="off"
                                          data-no-retina=""
                                        />
                                        {/*						*/}
                                        <rs-zone
                                          id="rrzt_43"
                                          className="rev_row_zone_top"
                                          style={{ zIndex: 15 }}
                                        >
                                          {/*

								*/}
                                          <rs-row
                                            id="slider-10-slide-43-layer-0"
                                            data-type="row"
                                            data-xy="xo:50px;yo:50px;"
                                            data-rsp_o="off"
                                            data-rsp_bd="off"
                                            data-margin="t:100,100,100,50;r:100,50,50,30;b:200,200,200,100;l:100,50,50,30;"
                                            data-border="boc:#ffffff;bow:2px,0,0,0;"
                                            data-frame_999="o:1;st:w;sR:8700;sA:6000;"
                                            style={{ zIndex: 1 }}
                                          >
                                            {/*
                                             */}
                                            <rs-column
                                              id="slider-10-slide-43-layer-1"
                                              data-type="column"
                                              data-xy="xo:50px;yo:50px;"
                                              data-rsp_o="off"
                                              data-rsp_bd="off"
                                              data-column="w:100%;"
                                              data-frame_999="o:1;st:w;sR:8700;sA:6000;"
                                              style={{
                                                zIndex: 2,
                                                width: "100%",
                                              }}
                                            >
                                              {/*
                                               */}
                                              <rs-layer
                                                id="slider-10-slide-43-layer-2"
                                                data-type="shape"
                                                data-dim="w:100%;h:2px;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-frame_0="x:-100%;o:1;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:500;sp:2000;sR:500;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:6500;auto:true;"
                                                style={{
                                                  zIndex: 3,
                                                  backgroundColor: "#ffffff",
                                                }}
                                              ></rs-layer>
                                              {/*

										*/}
                                              <rs-layer
                                                id="slider-10-slide-43-layer-8"
                                                data-type="text"
                                                data-text="s:30,30,20,20;l:30,30,20,20;fw:500;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-margin="t:90;b:45,45,25,25;"
                                                data-frame_0="o:1;"
                                                data-frame_0_chars="d:5;x:-105%;o:0;rZ:-90deg;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:300;sp:1200;sR:300;"
                                                data-frame_1_chars="e:power4.inOut;dir:backward;d:3;rZ:0deg;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:6870;auto:true;"
                                                style={{
                                                  zIndex: 4,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                AVTAV
                                                <br />
                                                Training, Vetting <br />
                                                and Recruitment services
                                              </rs-layer>
                                              {/*

										*/}
                                              <rs-layer
                                                id="slider-10-slide-43-layer-5"
                                                data-type="text"
                                                data-text="w:nowrap,normal,normal,normal;s:170,140,80,45;l:130,110,65,50;ls:-5px,-5px,-2px,-2px;fw:700;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-frame_0="o:1;"
                                                data-frame_0_chars="d:5;y:100%;o:0;rZ:-35deg;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:400;sp:1200;sR:400;"
                                                data-frame_1_chars="e:power4.inOut;d:5;rZ:0deg;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:6900;auto:true;"
                                                style={{
                                                  zIndex: 5,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                AVSEC
                                                <br />
                                                TRAINING
                                              </rs-layer>
                                              {/*
                                               */}
                                            </rs-column>
                                            {/*
                                             */}
                                          </rs-row>
                                          {/*
                                           */}
                                        </rs-zone>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-43-layer-6"
                                          className="rs-pxl-2"
                                          data-type="image"
                                          data-xy="x:r;xo:250px,71px,206px,46px;y:b;yo:350px,374px,433px,412px;"
                                          data-text="s:20,16,12,7;l:0,20,15,9;"
                                          data-dim="w:700px,578px,439px,364px;h:700px,578px,439px,364px;"
                                          data-border="bor:200px,200px,200px,200px;"
                                          data-clip="u:true;t:circle;o:clr;"
                                          data-frame_0="o:1;cp:0%;"
                                          data-frame_1="e:power4.inOut;st:800;sp:2000;sR:800;cp:50%;"
                                          data-frame_999="st:w;sp:600;sR:6200;auto:true;"
                                          style={{ zIndex: 13 }}
                                        >
                                          <img
                                            loading="lazy"
                                            src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                            className="tp-rs-img rs-lazyload"
                                            width={1600}
                                            height={1600}
                                            data-lazyload="//avtav.co.uk/wp-content/uploads/2021/07/vesctor-fly.png"
                                            data-no-retina=""
                                          />
                                        </rs-layer>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-43-layer-7"
                                          className="rs-pxl-12"
                                          data-type="image"
                                          data-xy="x:r;xo:0,4px,-7px,5px;y:m;yo:80px,156px,-15px,82px;"
                                          data-text="s:20,16,12,7;l:0,20,15,9;"
                                          data-dim="w:600px,495px,376px,400px;h:600px,495px,376px,400px;"
                                          data-border="bor:400px,400px,400px,400px;"
                                          data-clip="u:true;t:circle;o:clr;"
                                          data-frame_0="o:1;cp:0%;"
                                          data-frame_1="e:power4.inOut;st:700;sp:2000;sR:700;cp:50%;"
                                          data-frame_999="st:w;sp:600;sR:6300;auto:true;"
                                          style={{ zIndex: 11 }}
                                        >
                                          <img
                                            loading="lazy"
                                            src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                            className="tp-rs-img rs-lazyload"
                                            width={1600}
                                            height={1600}
                                            data-lazyload="//avtav.co.uk/wp-content/uploads/2021/07/world-map.png"
                                            data-no-retina=""
                                          />
                                        </rs-layer>
                                        {/*
                                         */}{" "}
                                      </rs-slide>
                                      <rs-slide
                                        data-key="rs-42"
                                        data-title="Slide"
                                        data-thumb="//avtav.co.uk/wp-content/uploads/2021/07/Screenshot-2021-07-12-at-11.24.04.png"
                                        data-in="o:0;"
                                        data-out="a:false;"
                                      >
                                        <img
                                          src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                          alt="Slide"
                                          title="Homepage"
                                          className="rev-slidebg tp-rs-img rs-lazyload"
                                          data-lazyload="//avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/transparent.png"
                                          data-bg="c:#17374d;"
                                          data-parallax="off"
                                          data-no-retina=""
                                        />
                                        {/*						*/}
                                        <rs-zone
                                          id="rrzt_42"
                                          className="rev_row_zone_top"
                                          style={{ zIndex: 15 }}
                                        >
                                          {/*

								*/}
                                          <rs-row
                                            id="slider-10-slide-42-layer-0"
                                            data-type="row"
                                            data-xy="xo:50px;yo:50px;"
                                            data-rsp_o="off"
                                            data-rsp_bd="off"
                                            data-margin="t:100,100,100,50;r:100,50,50,30;b:200,200,200,100;l:100,50,50,30;"
                                            data-border="boc:#ffffff;bow:2px,0,0,0;"
                                            data-frame_999="o:1;st:w;sR:8700;sA:6000;"
                                            style={{ zIndex: 1 }}
                                          >
                                            {/*
                                             */}
                                            <rs-column
                                              id="slider-10-slide-42-layer-1"
                                              data-type="column"
                                              data-xy="xo:50px;yo:50px;"
                                              data-rsp_o="off"
                                              data-rsp_bd="off"
                                              data-column="w:100%;"
                                              data-frame_999="o:1;st:w;sR:8700;sA:6000;"
                                              style={{
                                                zIndex: 2,
                                                width: "100%",
                                              }}
                                            >
                                              {/*
                                               */}
                                              <rs-layer
                                                id="slider-10-slide-42-layer-2"
                                                data-type="shape"
                                                data-dim="w:100%;h:2px;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-frame_0="x:-100%;o:1;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:500;sp:2000;sR:500;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:6500;auto:true;"
                                                style={{
                                                  zIndex: 3,
                                                  backgroundColor: "#ffffff",
                                                }}
                                              ></rs-layer>
                                              {/*

										*/}
                                              <rs-layer
                                                id="slider-10-slide-42-layer-8"
                                                data-type="text"
                                                data-text="s:30,30,20,20;l:30,30,20,20;fw:500;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-margin="t:90,90,90,100;b:45,45,25,25;"
                                                data-frame_0="o:1;"
                                                data-frame_0_chars="d:5;x:-105%;o:0;rZ:-90deg;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:300;sp:1200;sR:300;"
                                                data-frame_1_chars="e:power4.inOut;dir:backward;d:3;rZ:0deg;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:6870;auto:true;"
                                                style={{
                                                  zIndex: 4,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                AVTAV
                                              </rs-layer>
                                              {/*

										*/}
                                              <rs-layer
                                                id="slider-10-slide-42-layer-5"
                                                data-type="text"
                                                data-text="w:nowrap,normal,normal,normal;s:110,80,55,40;l:110,90,55,50;ls:-5px,-5px,-2px,-2px;fw:700;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-frame_0="o:1;"
                                                data-frame_0_chars="d:5;y:100%;o:0;rZ:-35deg;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:400;sp:1200;sR:400;"
                                                data-frame_1_chars="e:power4.inOut;d:5;rZ:0deg;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:6900;auto:true;"
                                                style={{
                                                  zIndex: 5,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                RECRUITMENT
                                                <br />
                                                SERVICES
                                              </rs-layer>
                                              {/*

										*/}
                                              <rs-layer
                                                id="slider-10-slide-42-layer-9"
                                                data-type="text"
                                                data-text="s:40,30,20,18;l:38,38,20,20;fw:700,600,600,600;"
                                                data-dim="w:auto,auto,auto,380px;h:77px;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-margin="t:45,45,25,25;r:0,0,0,10;b:45,45,25,0;"
                                                data-frame_0="o:1;"
                                                data-frame_0_chars="d:5;x:-105%;o:0;rZ:-90deg;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:300;sp:1200;sR:300;"
                                                data-frame_1_chars="e:power4.inOut;dir:backward;d:3;rZ:0deg;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:6870;auto:true;"
                                                style={{
                                                  zIndex: 6,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                SPECIALISING IN AVIATION &amp;{" "}
                                                <br />
                                                FOOD PRODUCTION <br />
                                                SUPPLY CHAINS
                                              </rs-layer>
                                              {/*
                                               */}
                                            </rs-column>
                                            {/*
                                             */}
                                          </rs-row>
                                          {/*
                                           */}
                                        </rs-zone>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-42-layer-6"
                                          className="rs-pxl-2"
                                          data-type="image"
                                          data-xy="x:r;xo:252px,262px,14px,35px;y:b;yo:409px,439px,513px,530px;"
                                          data-text="s:20,16,12,7;l:0,20,15,9;"
                                          data-dim="w:700px,578px,439px,357px;h:700px,578px,439px,357px;"
                                          data-border="bor:200px,200px,200px,200px;"
                                          data-clip="u:true;t:circle;o:clr;"
                                          data-frame_0="o:1;cp:0%;"
                                          data-frame_1="e:power4.inOut;st:800;sp:2000;sR:800;cp:50%;"
                                          data-frame_999="st:w;sp:600;sR:6200;auto:true;"
                                          style={{ zIndex: 13 }}
                                        >
                                          <img
                                            loading="lazy"
                                            src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                            className="tp-rs-img rs-lazyload"
                                            width={1600}
                                            height={1600}
                                            data-lazyload="//avtav.co.uk/wp-content/uploads/2021/07/vesctor-fly.png"
                                            data-no-retina=""
                                          />
                                        </rs-layer>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-42-layer-7"
                                          className="rs-pxl-12"
                                          data-type="image"
                                          data-xy="x:r;xo:0,0,-5px,-35px;y:m;yo:80px,0,0,-4px;"
                                          data-text="s:20,16,12,7;l:0,20,15,9;"
                                          data-dim="w:600px,495px,376px,400px;h:600px,495px,376px,400px;"
                                          data-border="bor:400px,400px,400px,400px;"
                                          data-clip="u:true;t:circle;o:clr;"
                                          data-frame_0="o:1;cp:0%;"
                                          data-frame_1="e:power4.inOut;st:700;sp:2000;sR:700;cp:50%;"
                                          data-frame_999="st:w;sp:600;sR:6300;auto:true;"
                                          style={{ zIndex: 11 }}
                                        >
                                          <img
                                            loading="lazy"
                                            src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                            className="tp-rs-img rs-lazyload"
                                            width={1600}
                                            height={1600}
                                            data-lazyload="//avtav.co.uk/wp-content/uploads/2021/07/world-map.png"
                                            data-no-retina=""
                                          />
                                        </rs-layer>
                                        {/*
                                         */}{" "}
                                      </rs-slide>
                                      <rs-slide
                                        data-key="rs-40"
                                        data-title="Slide"
                                        data-in="o:0;"
                                        data-out="a:false;"
                                      >
                                        <img
                                          src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                          alt="Slide"
                                          title="Homepage"
                                          className="rev-slidebg tp-rs-img rs-lazyload"
                                          data-lazyload="//avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/transparent.png"
                                          data-bg="c:#18384d;"
                                          data-parallax="off"
                                          data-no-retina=""
                                        />
                                        {/*						*/}
                                        <rs-zone
                                          id="rrzt_40"
                                          className="rev_row_zone_top"
                                          style={{ zIndex: 16 }}
                                        >
                                          {/*

								*/}
                                          <rs-row
                                            id="slider-10-slide-40-layer-0"
                                            data-type="row"
                                            data-xy="xo:50px;yo:50px;"
                                            data-rsp_o="off"
                                            data-rsp_bd="off"
                                            data-margin="t:100,100,100,50;r:100,50,50,30;b:200,200,200,100;l:100,50,50,30;"
                                            data-border="boc:#ffffff;bow:2px,0,0,0;"
                                            data-frame_999="o:1;st:w;sR:8700;sA:6000;"
                                            style={{ zIndex: 1 }}
                                          >
                                            {/*
                                             */}
                                            <rs-column
                                              id="slider-10-slide-40-layer-1"
                                              data-type="column"
                                              data-xy="xo:50px;yo:50px;"
                                              data-rsp_o="off"
                                              data-rsp_bd="off"
                                              data-column="w:100%;"
                                              data-frame_999="o:1;st:w;sR:8700;sA:6000;"
                                              style={{
                                                zIndex: 2,
                                                width: "100%",
                                              }}
                                            >
                                              {/*
                                               */}
                                              <rs-layer
                                                id="slider-10-slide-40-layer-2"
                                                data-type="shape"
                                                data-dim="w:100%;h:2px;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-frame_0="x:-100%;o:1;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:500;sp:2000;sR:500;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:6500;auto:true;"
                                                style={{
                                                  zIndex: 3,
                                                  backgroundColor: "#ffffff",
                                                }}
                                              ></rs-layer>
                                              {/*

										*/}
                                              <rs-layer
                                                id="slider-10-slide-40-layer-8"
                                                data-type="text"
                                                data-text="s:30,30,20,20;l:30,30,20,20;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-margin="t:90;b:45,45,25,25;"
                                                data-frame_0_chars="d:5;x:-105%;o:0;rZ:-90deg;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:300;sp:1200;sR:300;"
                                                data-frame_1_chars="e:power4.inOut;dir:backward;d:3;rZ:0deg;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:7500;auto:true;"
                                                style={{
                                                  zIndex: 4,
                                                  fontFamily:
                                                    "Major Mono Display",
                                                }}
                                              >
                                                your single
                                                <br />
                                                aviation
                                                <br />
                                                solution
                                              </rs-layer>
                                              {/*

										*/}
                                              <rs-layer
                                                id="slider-10-slide-40-layer-5"
                                                data-type="text"
                                                data-color="#18384d"
                                                data-text="w:normal;s:130,140,70,45;l:120,110,65,50;ls:-5px,-5px,-2px,-2px;fw:700;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-margin="b:20;"
                                                data-frame_0_chars="d:5;y:100%;o:0;rZ:-35deg;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:400;sp:1200;sR:400;"
                                                data-frame_1_chars="e:power4.inOut;d:5;rZ:0deg;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:7400;auto:true;"
                                                style={{
                                                  zIndex: 5,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                CARGO
                                                <br />
                                                SECURITY
                                              </rs-layer>
                                              {/*

										*/}
                                              <rs-layer
                                                id="slider-10-slide-40-layer-22"
                                                data-type="text"
                                                data-color="#fff"
                                                data-xy="xo:109px,90px,68px,41px;yo:589px,486px,369px,227px;"
                                                data-text="w:normal;s:50,41,33,20;l:55,45,35,21;fw:800,800,600,600;"
                                                data-dim="minh:0px,none,none,none;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-frame_999="o:0;st:w;"
                                                style={{
                                                  zIndex: 6,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                REGULATED AGENT
                                                <br />
                                                COURSES
                                              </rs-layer>
                                              {/*
                                               */}
                                            </rs-column>
                                            {/*
                                             */}
                                          </rs-row>
                                          {/*
                                           */}
                                        </rs-zone>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-40-layer-3"
                                          data-type="shape"
                                          data-xy="x:c;xo:-1090px,-1170px,-1097px,-1100px;y:m;yo:-100px,-90px,-265px,-140px;"
                                          data-dim="w:2000px;h:2000px;"
                                          data-basealign="slide"
                                          data-rsp_o="off"
                                          data-rsp_bd="off"
                                          data-btrans="rZ:45;"
                                          data-frame_0="x:-100%;o:1;"
                                          data-frame_1="sp:1000;"
                                          data-frame_999="st:w;sp:1500;sR:8000;auto:true;"
                                          style={{
                                            zIndex: 11,
                                            backgroundColor: "#608fa6",
                                          }}
                                        ></rs-layer>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-40-layer-4"
                                          className="rs-pxl-12"
                                          data-type="text"
                                          data-xy="x:r;xo:160px,90px,30px,0;yo:650px,690px,580px,450px;"
                                          data-text="w:nowrap,nowrap,normal,normal;s:20,20,20,17;l:25,25,25,20;a:right;"
                                          data-dim="w:auto,auto,200px,200px;"
                                          data-rsp_o="off"
                                          data-rsp_bd="off"
                                          data-btrans="rZ:45;"
                                          data-frame_0_chars="d:5;x:-105%;o:0;rZ:-90deg;"
                                          data-frame_0_mask="u:t;"
                                          data-frame_1="st:500;sp:1200;sR:500;"
                                          data-frame_1_chars="e:power4.inOut;dir:backward;d:3;rZ:0deg;"
                                          data-frame_1_mask="u:t;"
                                          data-frame_999="st:w;sp:600;sR:7300;auto:true;"
                                          style={{
                                            zIndex: 15,
                                            fontFamily: "Major Mono Display",
                                          }}
                                        >
                                          check out our
                                          <br />
                                          courses section!
                                        </rs-layer>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-40-layer-7"
                                          className="rs-noevents rs-pxl-12"
                                          data-type="image"
                                          data-bsh="c:rgba(0,0,0,0.35);v:30px,24px,18px,11px;b:30px,24px,18px,11px;"
                                          data-xy="x:r;xo:-90px,-160px,-170px,-180px;yo:210px,170px,200px,200px;"
                                          data-text="s:20,16,12,7;l:0,20,15,9;"
                                          data-dim="w:640px,640px,480px,360px;h:346px,346px,320px,240px;"
                                          data-rsp_o="off"
                                          data-rsp_bd="off"
                                          data-btrans="rZ:45;"
                                          data-frame_0="x:right;"
                                          data-frame_1="e:power4.inOut;sp:1500;"
                                          data-frame_999="st:w;sp:1000;sR:7500;auto:true;"
                                          style={{ zIndex: 14 }}
                                        >
                                          <img
                                            loading="lazy"
                                            src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                            className="tp-rs-img rs-lazyload"
                                            width={1583}
                                            height={857}
                                            data-lazyload="//avtav.co.uk/wp-content/uploads/2021/07/recruitment.jpg"
                                            data-no-retina=""
                                          />
                                        </rs-layer>
                                        {/*
                                         */}{" "}
                                      </rs-slide>
                                      <rs-slide
                                        data-key="rs-41"
                                        data-title="Slide"
                                        data-in="o:0;"
                                        data-out="a:false;"
                                      >
                                        <img
                                          src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                          alt="Slide"
                                          title="Homepage"
                                          className="rev-slidebg tp-rs-img rs-lazyload"
                                          data-lazyload="//avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/transparent.png"
                                          data-bg="c:#7497a8;"
                                          data-parallax="off"
                                          data-no-retina=""
                                        />
                                        {/*						*/}
                                        <rs-zone
                                          id="rrzt_41"
                                          className="rev_row_zone_top"
                                          style={{ zIndex: 18 }}
                                        >
                                          {/*

								*/}
                                          <rs-row
                                            id="slider-10-slide-41-layer-0"
                                            data-type="row"
                                            data-xy="xo:50px;yo:50px;"
                                            data-rsp_o="off"
                                            data-rsp_bd="off"
                                            data-margin="t:100,100,100,50;r:100,50,50,30;b:200,200,200,100;l:100,50,50,30;"
                                            data-border="boc:#ffffff;bow:2px,0,0,0;"
                                            data-frame_999="o:1;st:w;sR:8700;sA:6000;"
                                            style={{ zIndex: 1 }}
                                          >
                                            {/*
                                             */}
                                            <rs-column
                                              id="slider-10-slide-41-layer-1"
                                              data-type="column"
                                              data-xy="xo:50px;yo:50px;"
                                              data-rsp_o="off"
                                              data-rsp_bd="off"
                                              data-column="w:100%;"
                                              data-frame_999="o:1;st:w;sR:8700;sA:6000;"
                                              style={{
                                                zIndex: 2,
                                                width: "100%",
                                              }}
                                            >
                                              {/*
                                               */}
                                              <rs-layer
                                                id="slider-10-slide-41-layer-2"
                                                data-type="shape"
                                                data-dim="w:100%;h:2px;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-frame_0="x:-100%;o:1;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:500;sp:2000;sR:500;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:6500;auto:true;"
                                                style={{
                                                  zIndex: 3,
                                                  backgroundColor: "#ffffff",
                                                }}
                                              ></rs-layer>
                                              {/*

										*/}
                                              <rs-layer
                                                id="slider-10-slide-41-layer-8"
                                                data-type="text"
                                                data-color="#18384d"
                                                data-text="s:30,30,20,20;l:30,30,20,20;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-margin="t:70,70,90,70;b:45,45,25,25;"
                                                data-frame_0_chars="d:5;x:-105%;o:0;rZ:-90deg;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:300;sp:1200;sR:300;"
                                                data-frame_1_chars="e:power4.inOut;dir:backward;d:3;rZ:0deg;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:6870;auto:true;"
                                                style={{
                                                  zIndex: 4,
                                                  fontFamily:
                                                    "Major Mono Display",
                                                }}
                                              >
                                                aviation
                                                <br />
                                                security
                                                <br />
                                                training
                                              </rs-layer>
                                              {/*

										*/}
                                              <rs-layer
                                                id="slider-10-slide-41-layer-5"
                                                data-type="text"
                                                data-text="w:normal;s:140,100,60,45;l:120,90,55,50;ls:-5px,-5px,-2px,-2px;fw:700;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-margin="b:20;"
                                                data-frame_0_chars="d:5;y:100%;o:0;rZ:-35deg;"
                                                data-frame_0_mask="u:t;"
                                                data-frame_1="st:400;sp:1200;sR:400;"
                                                data-frame_1_chars="e:power4.inOut;d:5;rZ:0deg;"
                                                data-frame_1_mask="u:t;"
                                                data-frame_999="st:w;sp:600;sR:7000;auto:true;"
                                                style={{
                                                  zIndex: 5,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                AVSEC
                                                <br />
                                                TRAINING
                                              </rs-layer>
                                              {/*

										*/}
                                              <rs-layer
                                                id="slider-10-slide-41-layer-14"
                                                data-type="text"
                                                data-color="#fff"
                                                data-text="w:normal;s:30,27,16,14;l:40,31,25,18;fw:700,600,600,500;"
                                                data-dim="w:795.986px,646.99px,418.983px,257px;h:151px,124px,94px,57px;minh:0px,none,none,none;"
                                                data-rsp_o="off"
                                                data-rsp_bd="off"
                                                data-margin="l:20,17,13,8;"
                                                data-frame_999="o:0;st:w;"
                                                style={{
                                                  zIndex: 6,
                                                  fontFamily: "Poppins",
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                gsat, Regulated agent 2021
                                                syllabus
                                                <br />
                                                Known Consigner, hold baggage
                                                reconciliation
                                              </rs-layer>
                                              {/*
                                               */}
                                            </rs-column>
                                            {/*
                                             */}
                                          </rs-row>
                                          {/*
                                           */}
                                        </rs-zone>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-41-layer-4"
                                          className="rs-pxl-12"
                                          data-type="text"
                                          data-xy="x:r;xo:100px,50px,522px,42px;yo:698px,690px,524px,632px;"
                                          data-text="w:nowrap,nowrap,normal,normal;s:20,20,20,17;l:25,25,25,20;a:right;"
                                          data-dim="w:auto,auto,200px,200px;"
                                          data-rsp_o="off"
                                          data-rsp_bd="off"
                                          data-frame_0_chars="d:5;x:-105%;o:0;rZ:-90deg;"
                                          data-frame_0_mask="u:t;"
                                          data-frame_1="st:500;sp:1200;sR:500;"
                                          data-frame_1_chars="e:power4.inOut;dir:backward;d:3;rZ:0deg;"
                                          data-frame_1_mask="u:t;"
                                          data-frame_999="st:w;sp:600;sR:6640;auto:true;"
                                          style={{
                                            zIndex: 17,
                                            fontFamily: "Major Mono Display",
                                          }}
                                        >
                                          classroom-based
                                          <br />
                                          client’s premises
                                          <br />
                                          or via e-learning
                                        </rs-layer>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-41-layer-7"
                                          className="rs-noevents rs-pxl-12"
                                          data-type="image"
                                          data-bsh="c:rgba(0,0,0,0.35);v:30px,24px,18px,11px;b:30px,24px,18px,11px;"
                                          data-xy="x:r;xo:100px,50px,50px,3px;yo:150px,170px,360px,352px;"
                                          data-text="s:20,16,12,7;l:0,20,15,9;"
                                          data-dim="w:600px,500px,400px,250px;h:528px,500px,400px,250px;"
                                          data-rsp_o="off"
                                          data-rsp_bd="off"
                                          data-clip="u:true;t:circle;o:clr;"
                                          data-frame_0="x:-100px;o:1;cp:0%;"
                                          data-frame_1="e:power4.inOut;sp:1500;cp:85%;"
                                          data-frame_999="st:w;sp:1000;sR:7500;auto:true;"
                                          style={{ zIndex: 16 }}
                                        >
                                          <img
                                            loading="lazy"
                                            src="https://avtav.co.uk/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                            className="tp-rs-img rs-lazyload"
                                            width={973}
                                            height={857}
                                            data-lazyload="//avtav.co.uk/wp-content/uploads/2021/07/class-room.jpg"
                                            data-no-retina=""
                                          />
                                        </rs-layer>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-41-layer-11"
                                          className="rs-pxl-4"
                                          data-type="shape"
                                          data-xy="x:r;xo:-500px,-500px,-260px,-250px;y:m;yo:0,0,0,-40px;"
                                          data-dim="w:1000px,1000px,500px,400px;h:1000px,1000px,500px,400px;"
                                          data-rsp_o="off"
                                          data-rsp_bd="off"
                                          data-border="bor:500px,500px,500px,500px;"
                                          data-clip="u:true;t:circle;o:clr;"
                                          data-frame_0="x:-100px;o:1;cp:0%;"
                                          data-frame_1="e:power4.inOut;st:700;sp:2000;sR:700;cp:50%;"
                                          data-frame_999="st:w;sp:1000;sR:6300;auto:true;"
                                          style={{
                                            zIndex: 13,
                                            backgroundColor: "#18384d",
                                          }}
                                        ></rs-layer>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-41-layer-12"
                                          className="rs-pxl-4"
                                          data-type="shape"
                                          data-xy="x:r;xo:600px,474px,400px,-20px;y:m;yo:-300px,-257px,120px,140px;"
                                          data-dim="w:125px,125px,100px,100px;h:125,125,100px,100px;"
                                          data-rsp_o="off"
                                          data-rsp_bd="off"
                                          data-border="bor:500px,500px,500px,500px;"
                                          data-clip="u:true;t:circle;o:clr;"
                                          data-frame_0="x:-100px;o:1;cp:0%;"
                                          data-frame_1="e:power4.inOut;st:800;sp:2000;sR:800;cp:50%;"
                                          data-frame_999="st:w;sp:1000;sR:6200;auto:true;"
                                          style={{
                                            zIndex: 14,
                                            backgroundColor: "#ffffff",
                                          }}
                                        ></rs-layer>
                                        {/*

							*/}
                                        <rs-layer
                                          id="slider-10-slide-41-layer-13"
                                          className="rs-pxl-4"
                                          data-type="shape"
                                          data-xy="x:r;xo:50px,0,65px,65px;y:m;yo:-180px,-180px,-230px,-170px;"
                                          data-dim="w:100px,100px,50px,50px;h:100px,100px,50px,50px;"
                                          data-rsp_o="off"
                                          data-rsp_bd="off"
                                          data-border="bor:500px,500px,500px,500px;"
                                          data-clip="u:true;t:circle;o:clr;"
                                          data-frame_0="x:-100px;o:1;cp:0%;"
                                          data-frame_1="e:power4.inOut;st:900;sp:2000;sR:900;cp:50%;"
                                          data-frame_999="st:w;sp:1000;sR:6100;auto:true;"
                                          style={{
                                            zIndex: 15,
                                            backgroundColor: "#ffffff",
                                          }}
                                        ></rs-layer>
                                      </rs-slide>
                                    </rs-slides>
                                    <rs-static-layers></rs-static-layers>
                                  </rs-module>
                                </rs-module-wrap>
                                {/* END REVOLUTION SLIDER */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                {/* .site-main */}
              </div>
              {/* .content-area */}
            </div>
          </section>
        </div>
        {/* .site-content */}
      </div>
    </>
  );
};

export default Slider;
