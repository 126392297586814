import Footer from "../../components/Organisms/Footer/Footer";
import ExamCourse from "../../containers/TrainingScreen/ExamCourse/ExamCourse";
import React from "react";

const ExamCoursePage = () => {
  return (
    <>
      <ExamCourse />
      <Footer />
    </>
  );
};

export default ExamCoursePage;
