import * as Styled from "./style";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";

const AntdTable = ({
  data,
  columns,
  numberOfTableData = 10,
  rowSelection,
  onChange,
  ...props
}) => {
  const columnsWithCustomRender = columns?.map((column) => {
    const defaultColumnProps = {
      ...column,
      title: column.label,
      dataIndex: column.mappedkey,
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === "ascend") {
          return <MdArrowUpward data-testid="ascend-sort" fontSize="14px" />;
        } else if (sortOrder === "descend") {
          return <MdArrowDownward data-testid="descend-sort" fontSize="14px" />;
        }
        // return <BiExpandVertical data-testid="no-sort" fontSize="14px" />;
      },
    };
    if (column.customRender) {
      return {
        ...defaultColumnProps,
        render: column?.customRender
          ? (text, row) => {
              return column?.customRender(
                text,
                row,
                // columnCommonProps,
                column.action,
                column.mappedkey
              );
            }
          : null,
      };
    }
    return defaultColumnProps;
  });
  return (
    <>
      <Styled.AntdTable
        size="small"
        columns={columnsWithCustomRender}
        dataSource={data}
        pagination={{ pageSize: numberOfTableData, ...props.pagination }}
        rowSelection={rowSelection}
        onChange={onChange}
        loading={props?.loading || false}
      />
    </>
  );
};
export default AntdTable;
