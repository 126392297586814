import styled from "styled-components";
export const MainContainer = styled.div`
  background: #6091a8;
  padding: 40px 0px;
  position: relative;
  margin-top: 10px;
  .leftBox {
    width: 100%;
    flex: 0.4;
    border-right: 2px solid #ffffff;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0px 20px 0px 0px;
  }
  .recIcon {
    width: 50%;
    height: auto;
  }
  .mainBox {
    display: flex;
    flex-wrap: wrap;
  }
  .middleBox {
    flex: 0.35;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 50px;
    border-right: 2px solid #ffffff;
    padding: 0px 30px;
    justify-content: center;
  }
  .text {
    color: #ffffff;
    font-size: 24px;
    line-height: 1.2em;
    font-family: "Poppins", sans-serif;
  }
  .rightContainer {
    flex: 0.25;
    width: 54%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
  }
  .rightBox {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 11px;
  }
  .socialIcon {
    background-color: #17374c;
    color: #ffffff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
    padding: 10px;
  }
  .boxContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
  }
  .followText {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    margin-top: 10px;
    width: 100%;
  }
`;
export const WhiteIcon = styled.div`
  position: absolute;
  left: 48%;
  bottom: 166px;
  .triangleIcon {
    font-size: 63px;
    color: #ffffff;
  }
`;
