import * as Styled from "./style";
import AntdTable from "../../../components/Atoms/Table";
import DownloadDeclarationForm from "../../Corporate/BusinessUserLicense/DownloadDeclarationForm";
import React, { useEffect, useState } from "react";
import XLSX from "xlsx";
import useCommonHook from "../../../hooks/CommonHook";
import { saveAs } from "file-saver";
import { AiOutlineSearch } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegFileExcel } from "react-icons/fa";
import { LuListFilter } from "react-icons/lu";
import { useSelector } from "react-redux";
import { useSingleDeclarationHook } from "../../../hooks/SingleDeclarationHook";
import { DeclarationModalPreview } from "../../Corporate/BusinessUserLicense/DeclarationModal";
import { dataItem } from "./data";

export const Signedbyyou = () => {
  const {
    SignedbyyouTableCourse,
    singleDeclarationForm,
    declarationPreview,
    downloadData,
    setDeclarationPreview,loading
  } = useSingleDeclarationHook();
  const [sortOrder, setSortOrder] = useState("DESC");
  const soleTraderDeclarationData = useSelector(
    (state) =>
      state?.declarationReducer?.singleDeclarationForm?.declarationForms?.data
        ?.cources
  );
  const declarationCount = useSelector(
    (state) => state?.declarationReducer?.singleDeclarationForm?.count
  );

  const [downloadSheet, setDownloadSheet] = useState();
  const { useDebounce } = useCommonHook();
  const [search, setSearch] = useState({
    page: 1,
    limit: 5,
    name: "",
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      total: declarationCount,
      current: 1,
      pageSize: 5,
    },
  });
  useEffect(() => {
    setTableParams({
      pagination: {
        total: declarationCount,
        current: 1,
        pageSize: 5,
      },
    });
  }, [declarationCount]);
  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };  
  const handleSearch = (e) => {
    setSearch({ ...search, name: e.target.value });
  };
  const handleType = (e) => {
    setSortOrder(e.target.value);
  };
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    singleDeclarationForm({
      isEmployee: 0,
      sort: `createdAt|${sortOrder}`,
      ...debouncedSearch,
      page: tableParams.pagination.current,
    });
  }, [debouncedSearch, tableParams, sortOrder]);

  useEffect(() => {
    if (downloadSheet) {
      const handleDeclaration = async () => {
        const response = await singleDeclarationForm({ isEmployee: 0 });
        if (response && response?.declarationForms) {
          const dataToDownload = response?.declarationForms?.data?.cources?.map(
            (item) => ({
              "Date Added": item?.createdAt
                ? new Date(item?.createdAt).toLocaleDateString("en-GB")
                : "",
              "Course Name": item?.Course?.title,
              "Status": item?.isApproved === 0 ? "Pending" : "Aprroved",
            })
          );

          const ws = XLSX.utils.json_to_sheet(dataToDownload);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });
          saveAs(data, "Declaration Form Data.xlsx");
        } else {
          console.error("No data to download");
        }
      };

      handleDeclaration();
      setDownloadSheet(false);
    }
  }, [downloadSheet]);
  const handleClick = () => {
    setDownloadSheet(true);
  };
  return (
    <div>
      <Styled.CompletedCourses>
        <span className="courses">{dataItem.Completed}</span>
        <Styled.Pipe />
        <span className="number">{declarationCount}</span>
      </Styled.CompletedCourses>
      <Styled.BelowText>{dataItem.BelowText}</Styled.BelowText>
      <Styled.MainContainer>
        <Styled.TableHeader className="container">
          <div className="tablecontainer">
            <Styled.SearchContainer>
              <AiOutlineSearch className="searchIcon" size={12} />
              <Styled.Input
                type="text"
                placeholder="Search by course name"
                value={search?.name}
                onChange={handleSearch}
              />
            </Styled.SearchContainer>

            <Styled.Filter>
              <LuListFilter className="filter" />
              <Styled.NewestFirst>
                <select
                  name="language"
                  id="language"
                  className="newest"
                  onChange={handleType}
                  value={sortOrder}
                >
                  {/* <option value="" disabled >
                    {dataItem.Selectcourse}
                  </option> */}
                  <option value="DESC">{dataItem.Newestfirst}</option>
                  <option value="ASC">{dataItem.Oldestfirst}</option>
                </select>
              </Styled.NewestFirst>
            </Styled.Filter>
            <Styled.Spreadsheet onClick={handleClick}>
              <FaRegFileExcel />
              <span>{dataItem.DownloadSpreadsheet}</span>
            </Styled.Spreadsheet>
          </div>

          <Styled.DotIcon>
            <BsThreeDotsVertical className="dots" />
          </Styled.DotIcon>
        </Styled.TableHeader>
        <div>
          <AntdTable
            data={soleTraderDeclarationData}
            columns={SignedbyyouTableCourse}
            numberOfTableData={tableParams.pagination.pageSize}
            onChange={handleTableChange}
            {...tableParams}
            loading={loading}
          />
        </div>
      </Styled.MainContainer>
      <div
        className="declaration-form"
        style={{
          position: "absolute",
          zIndex: "-1000",
          overflow: "hidden",
          height: "1px",
          width: "75%",
          opacity: "0",
        }}
      >
        <div>
          <DownloadDeclarationForm item={downloadData} isSoleTrader={true} singleDeclaration={true}/>
        </div>
      </div>
      <div>
        {declarationPreview ? (
          <DeclarationModalPreview
            isSoleTrader={true}
            openModal={true}
            setOpenModal={setDeclarationPreview}
            item={declarationPreview}
            isTraineeData={true}
            singleDeclaration={true}
          />
        ) : null}
      </div>
    </div>
  );
};
