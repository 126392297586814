import styled from "styled-components";
import { color } from "../../../../theme/Color";
import { Modal } from "antd";

export const MainContainer = styled.div`
  padding: 20px 30px;
`;

export const Invoice = styled.div`
  .psNo {
    color: ${color._6090a8};
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .invoice {
    color: ${color._01384f};
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

export const Client = styled.div`
  margin-top: 36px;
  .denmark {
    color: ${color._6090a8};
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .client {
    color: ${color._16384e};

    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const InvoiceNumber = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${color._16384e};

  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Icon = styled.div`
  position: relative;
  left: 92%;
  width: 38px;
  height: 38px;
  background: ${color._EFF3F9};
  border-radius: 28px;
  cursor: pointer;
  .closeIcon {
    height: 24px;
    width: 24px;
    color: ${color._6d7d8b};
    margin-top: 7px;
    margin-left: 7px;
  }
`;
export const TableData = styled.div`
  .invoiceTable {
    width: 100%;
    height: 100%;
  }
  .number {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 800;
  }
  .subtotal {
    color: ${color._0f2a3c};

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  margin-top: 30px;
  td,
  th {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  table tr {
    border-bottom: 1px solid ${color._c9c9c9};
  }
  table th {
    border-top: 1px solid ${color._c9c9c9};
    border-bottom: 1px solid ${color._c9c9c9};
    color: #16384e;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const ModalContainer = styled(Modal)`
  &.ant-modal {
    max-width: calc(100% - 32px);
  }
`;
