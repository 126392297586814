import * as Styled from "./style";
import React from "react";
import { Steps } from "antd";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import useCommonHook from "../../../hooks/CommonHook";
import { useSelector } from "react-redux";
import { message } from "antd";

const MultiStepProgressBar = ({
  status,
  avtavNextButton,
  avtavPrevButton = false,
  nextOrder,
  steps,
  nextButton,
  previousButton,
  buttonHide,
  next,
  prev,
  current,
  signUpData,
  errors,
  setErrors,
  type,
  handleData,
  declareData,
  handleConfirmation,
  userConfirmation,
  emailData,
}) => {
  const { Step } = Steps;
  const { updateCommonState } = useCommonHook();
  const userDetail = useSelector((state) => state?.authReducer?.user);
  const name =
    userDetail?.type === "Single User"
      ? userDetail?.firstName + " " + userDetail?.lastName
      : userDetail?.companyName;
  const jobrole =
    userDetail?.type === "Single User"
      ? declareData?.role
      : declareData?.jobrole;

  const handleClick = (e) => {
    const newErrors = {};
    if (type === "corporate" || type === "single") {
      if (current === 0) {
        if (
          signUpData?.firstName?.length === 0 &&
          signUpData?.lastName?.length === 0 &&
          signUpData?.email?.length === 0 &&
          signUpData?.mobile?.length === 0
        ) {
          message.error("Please fill out the required details");
        } else {
          if (!signUpData?.firstName.trim()) {
            newErrors.firstName = "Firstname is required";
          }
          if (!signUpData?.lastName.trim()) {
            newErrors.lastName = "Lastname is required";
          }
          if (!signUpData?.email.trim()) {
            newErrors.email = "Email is required";
          } else if (
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(
              signUpData?.email
            )
          ) {
            newErrors.email = "Invalid email address";
          }
          if (!signUpData?.mobile.trim()) {
            newErrors.mobile = "Mobile number is required";
          } else if (!/^\d{10,15}$/.test(signUpData?.mobile)) {
            newErrors.mobile = "Please enter a valid mobile number";
          }
          if (Object.keys(newErrors).length === 0) {
            next();
          }
        }
      } else if (current === 1) {
        if (
          signUpData?.companyName?.length === 0 &&
          signUpData?.companyRegNo?.length === 0 &&
          signUpData?.landingNo?.length === 0 &&
          signUpData?.addressLine1?.length === 0 &&
          signUpData?.addressLine2?.length === 0 &&
          signUpData?.city?.length === 0 &&
          signUpData?.postcode?.length === 0
        ) {
          message.error("Please fill out the required details");
        } else {
          if (!signUpData?.companyName.trim()) {
            newErrors.companyName = "Company Name is required";
          }
          if (!signUpData?.companyRegNo.trim()) {
            newErrors.companyRegNo = "Company Reg Number is required";
          }
          if (!signUpData?.landingNo.trim()) {
            newErrors.landingNo = "Landing Number is required";
          } else if (!/^\d{10,15}$/.test(signUpData?.landingNo)) {
            newErrors.landingNo = "Please enter a valid landing number";
          }
          if (!signUpData?.addressLine1.trim()) {
            newErrors.addressLine1 = "Address1 is required";
          }
          if (!signUpData?.addressLine2.trim()) {
            newErrors.addressLine2 = "Address2 is required";
          }
          if (!signUpData?.city.trim()) {
            newErrors.city = "City is required";
          }
          if (!signUpData?.postcode.trim()) {
            newErrors.postcode = "Postcode is required";
          }
          if (Object.keys(newErrors).length === 0) {
            next();
          }
        }
      }

      setErrors({
        ...errors,
        [e.target.name]: "",
      });

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      }
    } else if (steps[current]?.title === "Declaration") {
      if (
        userConfirmation?.yearbackgroundcheck &&
        userConfirmation?.isEmployee === 1
      ) {
        if (emailData) {
          if (emailData?.email?.length === 0) {
            message.error("Please fill out the required details");
          } else {
            if (!emailData?.email.trim()) {
              newErrors.email = "Email is required";
            } else if (
              !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(
                emailData?.email
              )
            ) {
              newErrors.email = "Invalid email address";
            }
            setErrors(newErrors);
            next();
          }
        }
      } else {
        if (
          declareData?.jobrole?.length === 0 &&
          (declareData?.backgroundCeck === undefined ||
            declareData?.backgroundCeck === false) &&
          (declareData?.cpsra === undefined || declareData?.cpsra === false) &&
          declareData?.sign === undefined &&
          declareData?.role === ""
        ) {
          message.error("Please fill out the required details");
        } else {
          if (declareData?.sign === undefined || declareData?.sign === "") {
            newErrors.sign = "sign is required";
          }
          if (type !== "corporate_declaration" && !declareData?.role.trim()) {
            newErrors.role = "Role is required";
          }
          if (
            declareData?.backgroundCeck === undefined ||
            declareData?.backgroundCeck === false
          ) {
            newErrors.backgroundCeck = "Background check is required";
          }

          if (
            declareData?.cpsra === undefined ||
            declareData?.cpsra === false
          ) {
            newErrors.cpsra = "CPRSA is required";
          }
          setErrors(newErrors);
          if (Object.keys(newErrors).length === 0) {
            handleData({
              ...declareData,
              jobrole: jobrole,
              name: name,
            });
            window.scrollTo(0, 0);
            if (type === "corporate_declaration") {
              updateCommonState({
                checkoutReferer: "payment-message",
              });
            }
            next();
          }
        }
      }
    } else if (steps[current]?.title === "User type confirmation") {
      if (
        userConfirmation.yearbackgroundcheck === true &&
        Object.keys(userConfirmation).length === 3
      ) {
        handleConfirmation(userConfirmation);
        window.scrollTo(0, 0);
        if (userConfirmation?.isEmployee === 1) {
          updateCommonState({
            checkoutReferer: "payment-message",
          });
        } else {
          updateCommonState({
            checkoutReferer: "payment-sole-trader",
          });
        }
        next();
      } else {
        message.error(
          "Please confirm background check and select the user type."
        );
      }
    } else {
      next();
    }
  };

  return (
    <>
      <Styled.Stepper status={status}>
        <Steps current={current} labelPlacement="vertical" size="small">
          {steps?.map((item, index) => {
            return <Step title={item.title} icon={index + 1} />;
          })}
        </Steps>
      </Styled.Stepper>

      <div>{steps[current]?.content}</div>
      {buttonHide ? (
        <Styled.PrevNextDone>
          <Styled.PreviousButton avtavPrevButton={avtavPrevButton}>
            {current > 0 && (
              <Styled.ButtonPrev onClick={() => prev()}>
                {previousButton}
                <Styled.IconPrev>
                  <AiOutlineArrowLeft className="leftIcon" />
                </Styled.IconPrev>
              </Styled.ButtonPrev>
            )}
          </Styled.PreviousButton>

          <Styled.NextButton
            avtavNextButton={avtavNextButton}
            nextOrder={nextOrder}
          >
            {current < steps?.length - 1 && (
              <Styled.Button onClick={handleClick}>
                {nextButton}
                <Styled.Icon>
                  <AiOutlineArrowRight className="rightIcon" />
                </Styled.Icon>
              </Styled.Button>
            )}
          </Styled.NextButton>
        </Styled.PrevNextDone>
      ) : null}
    </>
  );
};

export default MultiStepProgressBar;
