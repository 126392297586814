import styled from "styled-components";

export const AboutBox=styled.div`
display:flex;
text-align: justify;
margin: 60px 0px;
align-items: center;
.leftContainer{
flex:0.5;
width:100%;
display: flex;
}
.rightContainer{
flex: 0.5;
width:100%;
}
.avtavheading{
    color: #18384D;
    font-size: 34px;
    font-weight: 600;
}
.textContainer{
        width: 82%;
}
.paragraphText{
    font-size: 14px;
}
.officeImage{
    width: 100%;
    padding-right: 13px;
}
`