import styled from "styled-components";
import woodenHeader from "../../assets/image/woodenHeader.jpg";
import RecruitmentBG from "../../assets/image/AVTAVRecruitmentBG.jpg";

export const RecruitmentContainer = styled.div`
  background-image: url(${woodenHeader});
  display: flex;
  justify-content: center;
  .heading {
    text-align: center;
    color: #ffffff;
    font-size: 50px;
    font-weight: 800;
    text-transform: uppercase;
    margin: 69px 0px;
  }
`;
export const About = styled.div`
  display: flex;
  font-size: 14px;
  padding: 60px 0px 13px 0px;
  .para {
    text-align: justify;
  }
  .left {
    flex: 0.5%;
  }
  .right {
    flex: 0.5%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .heading {
    font-size: 34px;
    font-weight: bold;
    color: #262626;
    margin-bottom: 15px;
  }
  .cardImage {
    border-radius: 26px 26px 26px 26px;
    width: 76%;
    height: auto;
  }
`;
export const Timescale = styled.div`
  display: flex;
  font-size: 14px;
  padding: 13px 0px 13px 0px;
  gap: 60px;
  .left {
    flex: 0.5%;
  }
  .right {
    flex: 0.5%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .heading {
    font-size: 34px;
    font-weight: bold;
    color: #262626;
    margin-bottom: 15px;
  }
  .timeScaleImage {
    border-radius: 26px 26px 26px 26px;
    width: 100%;
    height: auto;
  }
  .timeScalePara {
    width: 100%;
    text-align: justify;
  }
  .airplane {
    font-size: 200px;
    transform: rotate(90deg);
    color: #384e96;
  }
  .imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const OverlyCardBox = styled.div`
  justify-content: space-between;
  text-align: center;
  gap: 20px;
  display: flex;
  margin-bottom: 30px;
  height: 250px;
  .heading{
        line-height: 1;
    font-weight: 600;
    font-size: 21px;
  }
  .icon {
    font-size: 50px;
    width: 100%;
    margin-bottom: 20px;
  }
  .container {
    position: relative;
    flex: 0.2;
    background-color: #17374c;
    color:#ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image {
    display: block;
    width: 100%;
    height: auto;
  }

  .overlay {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #6091a8;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container:hover .overlay {
    top: 0;
    height: 100%;
  }

  .text {
    color: white;
    font-size: 14px;
    width:100%;
    padding: 40px;
  }
`;
export const CardBox = styled.div`
  margin-top: 20px;
  .h2 {
    font-size: 57px;
    font-weight: bold;
    text-align: center;
  }
`;
export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: center;
  .flip-box {
    background-color: transparent;
    width: 360px;
    height: 296px;
    border: 1px solid #f1f1f1;
    perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateX(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-box-front {
    background-color: #17374c;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
  }

  .flip-box-back {
    background-color: #6091a8;
    color: white;
    transform: rotateX(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
  }

  .heading {
    font-size: 21px;
    font-weight: 600;
  }
  .paragraph {
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
  }
  .cardButton {
    border: 2px solid #ffffff;
    background: none;
    color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    width: 40%;
  }
  .icon {
    font-size: 50px;
    width: 100%;
    margin-bottom: 20px;
  }
  .buttonBox {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
export const CardContainer1 = styled.div`
  display: grid;
  margin-bottom: 40px;
  justify-content: center;
  .flip-box {
    background-color: transparent;
    width: 360px;
    height: 296px;
    border: 1px solid #f1f1f1;
    perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateX(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-box-front {
    background-color: #17374c;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
  }

  .flip-box-back {
    background-color: #6091a8;
    color: white;
    transform: rotateX(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
  }

  .heading {
    font-size: 21px;
    font-weight: 600;
  }
  .paragraph {
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
  }
  .cardButton {
    border: 2px solid #ffffff;
    background: none;
    color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    width: 40%;
  }
  .icon {
    font-size: 50px;
    width: 100%;
    margin-bottom: 20px;
  }
  .buttonBox {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
export const BGImageContainer = styled.div`
  color: #ffffff;
  padding: 10px 0px;
  background-image: url(${RecruitmentBG});
  background-position: center center;
  background-size: cover;
  opacity: 1;
  .para {
    font-size: 14px;
  }
  .line {
    height: 1px;
    background-color: #ffffff;
    opacity: unset;
  }
  .textContainer {
    display: flex;
    text-align: center;
    gap: 10px;
    justify-content: center;
  }
  .innerBox {
    flex: 0.4;
  }
  .icon {
    font-size: 50px;
    width: 100%;
    margin-bottom: 20px;
  }
`;
