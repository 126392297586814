import styled from "styled-components";
import vetting from "../../assets/image/vetting.jpg"

export const VettingContainer=styled.div`
background-image: url(${vetting});
padding: 67px 0px;
.heading{
    color: #FFFFFF;
    font-size: 50px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    text-align: center;
}
`
export const About=styled.div`
display: flex;
text-align: justify;
font-size: 14px;
padding: 60px 0px 13px 0px;
.left{
flex:0.5%;
}
.right{
    flex:0.5%;
    width: 100%;
    display: flex;
    justify-content: center;
}
.heading{
    font-size: 51px;
    font-weight: bold;
    color: #262626;
}
.cardImage{
        border-radius: 26px 26px 26px 26px;
        width: 76%;
}
`