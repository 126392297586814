import Emitter, { EventTypes } from "./utils/Emitter";
import Protection from "./routes/protection";
import useCommonHook from "./hooks/CommonHook";
import { ConfigProvider } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { protectedRoutes } from "./routes/allRoutes";
import { themeConfig } from "./theme/themeConfig";

function App() {
  const sessionId = useSelector((state) => state.commonReducer.sessionId);
  const { updateCommonState, logOut } = useCommonHook();
  if (sessionId === null) {
    updateCommonState({ sessionId: uuidv4() });
  }
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    Emitter.on(EventTypes.INVALID_TOKEN, () => {
      logOut();
    });
  }, [sessionId]);

  return (
    <div className="App">
      <ConfigProvider theme={themeConfig}>
        <Routes>
          {protectedRoutes?.map((route) => (
            <Route
              keys={route.id}
              path={route.path}
              element={
                route.isProtected ? (
                  <>
                    <Protection>{route.data}</Protection>
                  </>
                ) : (
                  route.data
                )
              }
            />
          ))}
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default App;
