export const color = {
  _primary_color: "#005CBC",
  _F1F7F1: "#F1F7F1",
  _ffbe3224: "#ffbe3224",
  _0073DE: "#0073DE",
  _00BC35: "#00BC35",
  _f3f7f9: "#f3f7f9",
  _f1fbff: "#f1fbff",
  _16384E: "#16384E",
  _949494: "#949494",
  _000000: "#000000",
  _FFBE32: "#FFBE32",
  _ffffff: "#ffffff",
  _FFC636: "#FFC636",
  _82f170: "#82f170",
  _eaedef: "#eaedef",
  _16384e: "#16384e",
  _767676: "#767676",
  _ffc636: "#ffc636",
  _0f2a3c: "#0f2a3c",
  _071B28: "#071B28",
  _4a4a4a: "#4a4a4a",
  _5193aa: "#5193aa",
  _ff0000: "#ff0000",
  _ebf6fb: "#ebf6fb",
  _0073de: "#0073de",
  _transparent: "transparent",
  _3a3a3a: "#3a3a3a",
  _e3e7eb: "#e3e7eb",
  _ced4da: "#ced4da",
  _dee2e6: "#dee2e6",
  _6b88a4: "#6b88a4",
  _f4f4f4: "#f4f4f4",
  _669dd7: "#669dd7",
  _d0d0d0: "#d0d0d0",
  _1d1d1d: "#1d1d1d",
  _a52636: "#a52636",
  _e8f4ee: "#e8f4ee",
  _359ed5: "#359ed5",
  _a0a0a0: "#a0a0a0",
  _00e676: "#00e676",
  _ff1744: "#ff1744",
  _2196f3: "#2196f3",
  _cdcdcdcf: "#cdcdcdcf",
  _616161: "#616161",
  _313131: "#313131",
  _E8E8E8: "#E8E8E8",
  _445565: "#445565",
  _008240: "#008240",
  _008553: "#008553",
  _818181: "#818181",
  _dbd8d8: "#dbd8d8",
  _0096D1: "#0096D1",
  _C0C0C0: "#C0C0C0",
  _FBFCFE: "#FBFCFE",
  _EFF5FC: "#EFF5FC",
  _D40020: "#D40020",
  _272727: "#272727",
  _E7EFFB: "#E7EFFB",
  _F3F7FD: "#F3F7FD",
  _003771: "#003771",
  _B05788: "#B05788",
  _117D9F: "#117D9F",
  _0085CA: "#0085CA",
  _F6CCD2: "#F6CCD2",
  _DFEAF9: "#DFEAF9",
  _cce4ee: "#cce4ee",
  _90EE90: "#90EE90",
  _efefef: "#efefef",
  _c0c0c0: "#c0c0c0",
  _D7E5F8: "#d7e5f8",
  _D32F2F: "#d32f2f",
  _ED6C02: "#ed6c02",
  _0288D1: "#0288d1",
  _2E7D32: "#2e7d32",
  _D20000: "#D20000",
  _0A4B5F: "#0A4B5F",
  _70B1C5: "#70B1C5",
  _005CBC: "#005CBC",
  _0E647F: "#0E647F",
  _deebff: "#deebff",
  _005A7D: "#005A7D",
  _CCE4EE: "#CCE4EE",
  _F7F8F9: "#f7f8f9",
  _DFDFDF: "#DFDFDF",
  _005CBC1F: "#005cbc1f",
  _4d4d4d: "#4d4d4d",
  _fafafa: "#fafafa",
  _94D6F0: "#94D6F0",
  _0078A7: "#0078A7",
  _B3DACB: "#B3DACB",
  _C2C8CF: "#C2C8CF",
  _EEE: "#eee",
  _INPUT_ERROR_COLOR: "#ff1744",
  _fbfbfb: "#fbfbfb",
  _ebebeb: "#ebebeb",
  _08232f: "#08232f",
  _656565: "#656565",
  _DADADA: "#DADADA",
  _D1F0FF: "#D1F0FF",
  _F1FBFFE3: "#F1FBFFE3",
  _0090BD: "#0090BD",
  _ededee: "#ededee",
  _fff: "#fff",
  _0000001a: "#0000001a",
  _071b28: "#071b28",
  _6091a8: "#6091a8",
  _6090a8: "#6090a8",
  _f20000: "#f20000",
  _0F2A3C: "#0F2A3C",
  _08232F: "#08232F",
  _009BE3: "#009BE3",
  _6d7d8b: "#6d7d8b",
  _007cd7: "#007cd7",
  _F3FBFF: "#F3FBFF",
  _000000A8: "#000000A8",
  _EFF3F9: "#EFF3F9",
  _F00E00: "#F00E00",
  _FBFBFB: "#FBFBFB",
  _3ba32a: "#3ba32a",
  _000: " #000",
  _333: "#333",
  _ffbe32: "#ffbe32",
  _15374D: "#15374D",
  _D9D9D9: "#D9D9D9",
  _15374C: "#15374C",
  _009be3: " #009be3",
  _f44336: "#f44336",
  _17374C: "#17374c",
  _68828e: "#68828e",
  _565656: "#565656",
  _5F5F5F: "#5F5F5F",
  _002E4D: "#002E4D",
  _6072a812: "#6072a812",
  _ffffff1f: "#ffffff1f",
  _0d2331: "#0d2331",
  _f5f5f5: "#f5f5f5",
  _FFFFFF75: "#FFFFFF75",
  _177a9c: "#177a9c",
  _ededde: "#ededde",
  _F8F8F8: "#F8F8F8",
  _0c1f2d: "#0c1f2d",
  _d4d4d4: "#d4d4d4",
  _01384f: "#01384f",
  _c9c9c9: "#c9c9c9",
  _6200DE: "#6200DE",
  _082429: "#082429",
  _999: "#999",
  _9747ff: "#9747ff",
  _f6fafb: "#f6fafb",
  _0f2e43: "#0f2e43",
  _6d7d8b1f: "#6d7d8b1f",
  _15374d: "#15374d",
  _37738c: "#37738c",
  _007097: "#007097",
  _0c293d: "#0c293d",
  _6d7d8b: "#6d7d8b",
  _fbfeff: "#fbfeff",
  _858585: "#858585",
  _f7f7f7: "#f7f7f7",
  _f3fbff: "#f3fbff",
  _d6d6d6: "#d6d6d6",
  _5f5f5f: "#5f5f5f",
  _009ed5: "#009ed5",
  _eef0fc: "#eef0fc",
  _f5fbfe: "#f5fbfe",
  _0089A0: "#0089A0",
  _bac2c5: "#bac2c5",
  _007DD7: "#007DD7",
  _262626:"#262626",
  _222E65:"#222E65",
  _222222:"#222222",
  _050404:"#050404",
  _1C2568:"#1C2568",
  _b6b6b6:"#b6b6b6"
};
