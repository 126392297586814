import {
  FaChartBar,
  FaCompressArrowsAlt,
  FaRegClipboard,
  FaRegCreditCard,
  FaRegLightbulb,
  FaRocket,
  FaTruck,
  FaUser,
} from "react-icons/fa";
import { RiFlightTakeoffLine } from "react-icons/ri";
import { FaPeopleArrows } from "react-icons/fa";
import { IoPeopleSharp } from "react-icons/io5";
import { FaBasketShopping } from "react-icons/fa6";
import { FaBox } from "react-icons/fa6";
import { FaBoxes } from "react-icons/fa";
import { TbChessQueenFilled } from "react-icons/tb";
import { MdOutlineFlightTakeoff, MdOutlineWatchLater } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";

export const data = {
  para1:
    "AVTAV offer temporary and permanent staffing solutions across the aviation and supply chain sector. We cover all disciplines from entry to board level. We understand recruiting the right candidates is critical to the success of any organisation.",
  para2:
    "AVTAV provide a consultative, partnership approach to recruitment where the specifications and needs of both organisations and candidates are fully understood and aligned. Our strategic relationships, labour market insight, and expertise in Aviation recruitment ensure that we deliver an exceptional experience to our clients and career satisfaction to our candidates. From application through to placement, candidates will have undergone the recruitment, selection, vetting and relevant Avsec training processes all under one roof.",
  para3:
    "This model not only ensures that the compliance and adherence to regulatory requirements have been met to the highest standards it also ensures that our clients will experience from the benefits of partnering with a single solution provider. And it doesn’t stop there, once our workers are placed our service begins. From fully managed service models to daily Adhoc needs we can cater to all types of staffing needs. In addition, we conduct worker wellbeing sessions, performance reviews and follow ups alongside rota and availability management.",
  para4:
    "Our clients appointed account managers will undertake consultation to fully understand operational and business objectives and align labour provision service accordingly. Service delivery KPI achievement is demonstrated through MI reporting",
  heading: "Recruitment",
  Timescales:
    "As we deliver our own training there is no waiting in line for an external training provider to allocate a slot which can be few and far between. We can immediately deliver the appropriate training as soon as vetting has been completed meaning the process is seamless and total turnaround time to placement is significantly reduced.",
  TimescalesHeading: "Timescales",
  Security: "Security & Quality Assurance",
  SecurityPara:
    "Having the entire process under one provider minimises the risk of a reduction in quality, security and consistency ensuring that the stringent measures required throughout the entire recruitment vetting and training process are upheld. Nothing is outsourced therefore the chain of diligence remains between us and our client and we are fully accountable for our service.",
  Retention: "Retention",
  RetentionPara:
    "Our ability to manage our candidates’ expectations and guide them through every step of the way towards their role reduces the likelihood of dissatisfaction and the possibility of them finding alternative work due to a lack of trust and communication. Having full control over timeframes and process means our candidates are reassured that they will be placed in their role in a timely and professional manner. This ultimately impacts our ability to meet and exceed our client’s needs.",
  service: "Full service Package Partnerships",
  servicePara1:
    "Our senior leadership team have over 25 years’ experience in supporting high volume and adhoc operations in the aviation supply chain sectors with their ongoing staffing requirements and management.",
  servicePara2:
    "We have built an extensive understanding of the operational needs, recruitment challenges, and the service level requirements needed to create a successful partnership.",
  servicePara3:
    "Extensive aviation labour supply knowledge and expertise. Identifying candidates suitable and competent for an industrial role within this sector requires expert skills matching and understanding of the specific role to be undertaken. We understand that there is no one size fits all approach to placing candidates in to this sector.",
};
export const cardData = [
  {
    id: 1,
    icon: <FaTruck />,
    Hedaing1: "Transport",
    heading2: "Roles We Cover",
    para: `HGV1 Days, Nights  HGV2 Days, Nights 7.5TN Days, Nights 3.5TN Days, Nights Transport Manager, Transport Supervisor,Transport Clerk, Transport Planner, Driver Trainer Drivers Mate, Porter`,
  },
  {
    id: 2,
    icon: <FaBoxes />,
    Hedaing1: "Freight",
    heading2: "Roles We Cover",
    para: "Freight forwarding clerks (Import -Export - Road – Ocean), Customs audit clerk, Account Manager, Customs clearance",
  },
  {
    id: 3,
    icon: <FaPeopleArrows />,
    Hedaing1: "Ground Handler	",
    heading2: "Roles We Cover",
    para: "Customer relations, Lounge executives, Passenger service support, Cabin presentation, Ramp & Baggage, Banksmen",
  },
  {
    id: 4,
    icon: <RiFlightTakeoffLine />,
    Hedaing1: "Cargo Regulated Agents",
    heading2: "Roles We Cover",
    para: "Cargo Manager, Cargo Supervisor, Cargo Screener (X-ray -Visual -Hand search), Cargo Drivers, Aircraft Security, Security, Operations Manger.",
  },
  {
    id: 5,
    icon: <IoPeopleSharp />,
    Hedaing1: "Managerial and Administration",
    heading2: "Roles We Cover",
    para: "HR Manager, Operations Managers, Customer Service, Administration",
  },
  {
    id: 6,
    icon: <FaBox />,
    Hedaing1: "Known Consignor	",
    heading2: "Roles We Cover",
    para: "Dispatchers , Loaders, Drivers, KC Supervisor, KC Manager, Operations Manager",
  },
];

export const cardData1 = [
  {
    id: 1,
    icon: <FaBasketShopping />,
    Hedaing1: "Retail",
    heading2: "Roles We Cover",
    para: "Sales associate, Cashier, Customer service representative, Store Manager, Waiting staff, Chefs",
  },
];
export const BannerData = [
  {
    id: 1,
    icon: <TbChessQueenFilled />,
    para: "Exclusive access to our AVSEC courses for the duration of AVTAV workers placements. Offer Extended to direct staff on allocation per course.",
  },
  {
    id: 2,
    icon: <FaChartBar />,
    para: "Discounted training rates to partner labour providers to contribute to the successful delivery of joint supply partnering.",
  },
  {
    id: 3,
    icon: <FaRegClipboard />,
    para: "Average turnaround time from booking to placement 12 working days.",
  },
];
export const BannerData1 = [
  {
    id: 1,
    icon: <FaRegCreditCard />,
    para: "Dedicated Account Manager. Daily check in information. Weekly welfare surgeries, Quarterly wellbeing sessions.",
  },
  {
    id: 2,
    icon: <MdOutlineFlightTakeoff />,
    para: "Specialist aviation Driving division dedicated solely to the transport requirements.Full driving compliance expertise and transparency. Drivers Negligence as standard.",
  },
  {
    id: 3,
    icon: <MdOutlineWatchLater />,
    para: "24/7 on call MI reporting (can be linked with partner suppliers for joint supplier partnership & accountability approach)",
  },
];
export const OverlayCardData = [
  {
    id: 1,
    icon: <FaRegLightbulb />,
    Heading: "Discovery & Dialogue",
    para: "We listen to our clients to understand their specific recruiting challenges, needs, goals and culture",
  },
  {
    id: 2,
    icon: <FaCompressArrowsAlt />,
    Heading: "Strategy",
    para: "We create customized solutions specific to organizational needs",
  },
  {
    id: 3,
    icon: <FaRocket />,
    Heading: "Action",
    para: "We collaborate with clients to put our agreed strategy in to action",
  },
  {
    id: 4,
    icon: <IoMdSettings />,
    Heading: "Delivery",
    para: "We constantly review our progress to ensure our clients talent acquisition goals are met",
  },
  {
    id: 5,
    icon:<FaUser />,
    Heading: "Talent Acquisition",
    para: "We combine proven traditional methods alongside cutting-edge technologies to deliver the quality talent needed",
  },
];
