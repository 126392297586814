import * as Styled from "./style";
import Button from "../../components/Atoms/Button/button";
import CheckableTag from "antd/es/tag/CheckableTag";
import React, { useState } from "react";
import { Checkbox } from "antd";
import { course, second } from "./data";

const Malpractice = ({ handleTrainingButton }) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
 
  return (
    <Styled.MainComponent className="container">
      <Styled.MalContainer>
        <div>
          <div className="policy">{second.policy}</div>
          <div className="pro">{second.procedure}</div>
        </div>
      </Styled.MalContainer>
      <div className="count">
        <div className="assessEquality">
          <Styled.Scope>
            <div className="scope">{second.scope}</div>
            <div className="conj">{second.conjunction}</div>
            <div>
              <ul className="conj">
                <li>{second.assess}</li>
                <li>{second.equality}</li>
                <li>{second.data}</li>
                <li>{second.appeal}</li>
                <li>{second.comp}</li>
              </ul>
            </div>
          </Styled.Scope>
          <Styled.Scope>
            <div className="scope">{second.define}</div>
            <div className="conj">{second.authority}</div>
            <div>
              <ul className="conj">
                <li>{second.collab}</li>
                <li>{second.someone}</li>
                <li>{second.another}</li>
                <li>{second.work}</li>
                <li>{second.trainee}</li>
                <li>{second.assistance}</li>
                <li>{second.instructor}</li>
              </ul>
              <div className="conj">{second.deficiencies}</div>
            </div>
          </Styled.Scope>
          <Styled.Scope>
            <div className="scope">{second.commit}</div>
            <div className="conj">{second.assessors}</div>
            <div className="those">{second.those}</div>
          </Styled.Scope>
          <Styled.Scope>
            <div className="scope">{second.pro}</div>
            <div className="conj">{second.minimise}</div>
            <div>
              <ul className="conj">
                <li>{second.clients}</li>
                <li>{second.instructors}</li>
                <li>{second.questions}</li>
                <li>{second.practices}</li>
                <li>{second.suspected}</li>
              </ul>
            </div>
          </Styled.Scope>
          <Styled.Scope>
            <div className="scope">{second.malPro}</div>
            <div className="conj">{second.delegate}</div>

            <div className="stage">{second.stage1}</div>
            <div className="conj">{second.notified}</div>
            <div className="conj">{second.investigated}</div>

            <div>
              <ul className="conj">
                <li>{second.scrutinising}</li>
                <li>{second.discussing}</li>
                <li>{second.dis}</li>
              </ul>
            </div>
            <div className="conj">{second.iaq}</div>

            <div>
              <ul className="conj">
                <li>{second.warn}</li>
                <li>{second.fail}</li>
                <li>{second.grade}</li>
                <li>{second.from}</li>
              </ul>
            </div>
            <div className="conj">{second.records}</div>
            <div className="stage">{second.stage2}</div>
            <div className="conj">{second.must}</div>
            <div className="conj">{second.inc}</div>

            <div>
              <ul className="conj">
                <li>{second.document}</li>
                <li>{second.inter}</li>
                <li>{second.acc}</li>
                <li>{second.qual}</li>
              </ul>
            </div>
            <div className="conj">{second.manager}</div>
            <div className="conj">{second.prog}</div>
            <div className="stage">{second.stage3}</div>
            <div className="conj">{second.eviden}</div>

            <div>
              <ul className="conj">
                <li>{second.cli}</li>
                <li>{second.avai}</li>
                <li>{second.ann}</li>
              </ul>
            </div>
            <div className="found">
              {second.found}
              <a href="" className="http">
                {second.ht}
              </a>
            </div>
          </Styled.Scope>
        </div>
        <Styled.StartAction>
          <div>
            <Checkbox
              className="agr"
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}>
              {second.agr}
            </Checkbox>
            <Styled.Start>
              <Button
                className="start"
                title={second.start}
                onClick={() => handleTrainingButton(isCheckboxChecked)}
              />
            </Styled.Start>
          </div>
        </Styled.StartAction>
      </div>
    </Styled.MainComponent>
  );
};

export default Malpractice;
