import * as Styled from "./style";
import React from "react";
import { acceptable } from "./data";

const AcceptableUser = () => {
  return (
    <>
      <Styled.Terms>{acceptable.accept}</Styled.Terms>
      <Styled.Accept className="container">
        <div>
          <div className="about">{acceptable.policy}</div>
          <div className="register">{acceptable.sets}<span className="web">{acceptable.website}</span></div>
          
          <div className="register">
            {acceptable.agreeing}
            <span className="web">{acceptable.Ifyoudo}</span>
          </div>
          <div className="register">{acceptable.updates}</div>
          <div className="register">
            {acceptable.alongside}
            <span className="web">{acceptable.terms}</span>
            {acceptable.found}
          </div>
        </div>
        <div>
          <div className="about">{acceptable.we}</div>
          <div className="register">
            {acceptable.drayton} <span className="web">{acceptable.us}</span>
          </div>
        </div>
        <div>
          <div className="about">{acceptable.using}</div>
          <div className="register">{acceptable.not}</div>
          <div className="register">{acceptable.national}</div>
          <div className="register">{acceptable.fraudulent}</div>
          <div className="register">{acceptable.harming}</div>
          <div className="register">{acceptable.humiliate}</div>
          <div className="register">{acceptable.content}</div>
          <div className="register">{acceptable.spam}</div>
          <div className="register">{acceptable.operation}</div>
          <div className="register">{acceptable.terrorist}</div>
        </div>
        <div>
          <div className="about">{acceptable.res}</div>
          <div className="register">{acceptable.must}</div>
          <div className="register">{acceptable.unlawful}</div>
          <div className="register">{acceptable.rights}</div>
          <div className="register">{acceptable.distress}</div>
          <div className="register">{acceptable.messaging}</div>
          <div className="register">{acceptable.denial}</div>
          <div className="register">
            {acceptable.contravenes}
            <div className="register">
              {acceptable.re}
              <br />
              {acceptable.without}
              <br />
              {acceptable.damaging}
            </div>
          </div>
        </div>
        <div>
          <div className="about">{acceptable.generate}</div>
          <div className="register">
            {acceptable.enables}
            <span className="web">{acceptable.userr}</span>
            {acceptable.you}
          </div>
          <div className="register">{acceptable.hatred}</div>
          <div className="register">{acceptable.harass}</div>
          <div className="register">{acceptable.sexually}</div>
          <div className="register">{acceptable.libellous}</div>
          <div className="register">{acceptable.activities}</div>
          <div className="register">{acceptable.violating}</div>
          <div className="register">{acceptable.shall}</div>
          <div className="register">{acceptable.inaccurate}</div>
          <div className="register">{acceptable.sweepstakes}</div>
          <div className="register">{acceptable.exploit}</div>
          <div className="register">{acceptable.third}</div>
          <div className="register">{acceptable.links}</div>
        </div>
        <div>
          <div className="about">{acceptable.beach}</div>

          <div className="register">{acceptable.occurred}</div>
          <div className="register">{acceptable.issue}</div>
          <div className="register">{acceptable.part}</div>
          <div className="register">{acceptable.remove}</div>
          <div className="register">{acceptable.suspends}</div>
          <div className="register">{acceptable.authorities}</div>
          <div className="register">{acceptable.take}</div>
        </div>
        <div>
          <div className="about">{acceptable.comp}</div>
          <div className="register">{acceptable.please}</div>
        </div>
        <div>
          <div className="about">{acceptable.laws}</div>
          <div className="register">{acceptable.kingdom}</div>
        </div>
      </Styled.Accept>
    </>
  );
};

export default AcceptableUser;
