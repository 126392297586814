import React from "react"
import { useDispatch } from "react-redux"
import HttpApis from "../services/HttpApis";
import { OrderCancelFormFailure, OrderCancelFormStart, OrderCancelFormSuccess } from "../redux/slices/orderCancelFormSlice";
const useOrderCancelForm=()=>{
    const dispatch=useDispatch();
    const OrderCancleAction = async (data) => {
        try {
          dispatch(OrderCancelFormStart());
          const response = await HttpApis.postOrderCancelForm(data);
          dispatch(OrderCancelFormSuccess(response.data));
          return response.data;
        } catch (err) {
          dispatch(OrderCancelFormFailure(err.message));
          return null;
        }
      };


return {OrderCancleAction

}
}
export default useOrderCancelForm