import React, { useState } from "react";
import * as Styled from "./style";
import Button from "../../components/Atoms/Button";
import useAccountDetailHook from "../../hooks/AccountDetailHook";
import { message } from "antd";

const AccountantDetail = ({ courseId, next }) => {
  const { postAccountData } = useAccountDetailHook();
  const [accountData, setAccountData] = useState({
    name: "",
    email: "",
    accountName: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    postCode: "",
    courseId: courseId,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async () => {
    const listData = { ...accountData };
    if (listData?.postCode) {
      delete listData?.postCode;
    }
    const response = await postAccountData(listData);
    if (response?.status) {
      message.success(response?.message);
      setAccountData({
        name: "",
        email: "",
        accountName: "",
        phoneNumber: "",
        address1: "",
        address2: "",
        city: "",
        postCode: "",
        courseId: courseId,
      });
    }
    return response;
  };
  return (
    <>
      <Styled.NextLine />
      <Styled.InputCheck>
        <Styled.Box>
          <div className="backCheck">
            Background Check Declaration
            <span className="single">| Accountant's Details</span>
          </div>
          <Styled.EmailPara>
            <Styled.CheckboxEmail>
              <Styled.AccountantField>
                <div className="add-space">
                  <Styled.Label>
                    {"Name of Contact"}
                    <span className="star">*</span>
                  </Styled.Label>
                  <Styled.Input
                    type={"text"}
                    className="inputAccountant"
                    name="name"
                    value={accountData?.name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="add-space">
                  <Styled.Label>
                    {"Email"}
                    <span className="star">*</span>
                  </Styled.Label>
                  <Styled.Input
                    type={"text"}
                    className="inputAccountant"
                    name="email"
                    value={accountData?.email}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Styled.AccountantField>
              <Styled.AccountantField>
                <div className="add-space">
                  <Styled.Label>
                    {"Accountancy Name"}
                    <span className="star">*</span>
                  </Styled.Label>
                  <Styled.Input
                    type={"text"}
                    className="inputAccountant"
                    name="accountName"
                    value={accountData?.accountName}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="add-space">
                  <Styled.Label>
                    {"Phone Number"}
                    <span className="star">*</span>
                  </Styled.Label>
                  <Styled.Input
                    type={"text"}
                    className="inputAccountant"
                    name="phoneNumber"
                    value={accountData?.phoneNumber}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Styled.AccountantField>
            </Styled.CheckboxEmail>
            <div className="add-space">
              <Styled.Label>
                {"Address Line 1"}
                <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type={"text"}
                className="inputAddress"
                name="address1"
                value={accountData?.address1}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="add-space">
              <Styled.Label>
                {"Address Line 2"}
                <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type={"text"}
                className="inputAddress"
                name="address2"
                value={accountData?.address2}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <Styled.CheckboxEmail>
              <Styled.AccountantField>
                <div className="add-space">
                  <Styled.Label>
                    {"City/Town"}
                    <span className="star">*</span>
                  </Styled.Label>
                  <Styled.Input
                    type={"text"}
                    className="inputAccountant"
                    name="city"
                    value={accountData?.city}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Styled.AccountantField>
              <Styled.AccountantField>
                <div className="add-space">
                  <Styled.Label>
                    {"Postcode"}
                    <span className="star">*</span>
                  </Styled.Label>
                  <Styled.Input
                    type={"text"}
                    className="inputAccountant"
                    name="postCode"
                    value={accountData?.postCode}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Styled.AccountantField>
            </Styled.CheckboxEmail>
            <Styled.ParaDec>
              <p>
                {
                  "Once payment is completed, AVTAV will contact your accountant directly to obtain a written and signed reference with confirmation dates of self employment."
                }
              </p>
            </Styled.ParaDec>
            <div>
              <Button
                title={"Submit Details"}
                className={"empbtn"}
                onClick={handleSubmit}
              />
            </div>
          </Styled.EmailPara>
        </Styled.Box>
      </Styled.InputCheck>
      <Styled.NextLine />
    </>
  );
};

export default AccountantDetail;
