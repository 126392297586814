import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useEffect, useRef, useState } from "react";
import { Checkbox, message } from "antd";
import { exam } from "../ExamCourse/data";
import { induction } from "./data";
import useInductionHook from "../../../hooks/InductionHook";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/routes";
import SignatureModal from "../../../components/Molecules/SignatureModal/SignatureModal";

const InductionCheckData = ({ location }) => {
  const { getChecklistData, handlePostChecklist } = useInductionHook();
  const [parentCheckboxState, setParentCheckboxState] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [finalSign, setFinalSign] = useState();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = dd + "/" + mm + "/" + yyyy;
  const [postListData, setPostListData] = useState({
    induction: [],
    comment: "",
    sign: null,
    formdate: today,
  });

  const checklistData = useSelector(
    (state) => state?.inductionReducer?.checklist
  );

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const listData = { ...postListData, sign: finalSign };
    const response = await handlePostChecklist(listData);
    if (response?.status === 200) {
      message.success(response?.data?.message);
      navigate(Routes?.courseCompletion, { state: location?.state?.title });
    }
    return response;
  };

  const handleTickAll = (questionId, e, questionIndex) => {
    const isChecked = e.target.checked;
    const childrenAnswers =
      checklistData[questionIndex]?.children?.map((child) => child?.id) || [];
    setPostListData((prevData) => {
      let updatedData = prevData?.induction?.filter(
        (data) => data?.question_id !== questionId
      );
      if (isChecked) {
        updatedData?.push({
          courseId: location?.state?.courseId,
          question_id: questionId,
          answers_id: childrenAnswers,
        });
      }
      return {
        ...prevData,
        induction: updatedData,
      };
    });
  };

  const handleChange = (answerId, e, questionIndex) => {
    const questionId = checklistData[questionIndex]?.id;
    setPostListData((prevData) => {
      let updatedData = [...prevData?.induction];
      const questionIndexInData = updatedData?.findIndex(
        (data) => data?.question_id === questionId
      );

      if (questionIndexInData === -1) {
        updatedData?.push({
          courseId: location?.state?.courseId,
          question_id: questionId,
          answers_id: [answerId],
        });
      } else {
        let questionData = updatedData?.find(
          (data) => data?.question_id === questionId
        );

        if (e.target.checked) {
          if (!questionData.answers_id?.includes(answerId)) {
            questionData?.answers_id?.push(answerId);
          }
        } else {
          questionData.answers_id = questionData?.answers_id?.filter(
            (id) => id !== answerId
          );
        }
        updatedData[questionIndexInData] = questionData;
      }

      return {
        ...prevData,
        induction: updatedData,
      };
    });
  };

  const handleCommentChange = (e) => {
    setPostListData({
      ...postListData,
      comment: e.target.value,
    });
  };

  useEffect(() => {
    const newParentCheckboxState = {};

    checklistData &&
      checklistData?.forEach((item) => {
        const childrenIds = item?.children?.map((child) => child?.id) || [];
        const questionData = postListData?.induction?.find(
          (data) => data?.question_id === item?.id
        );
        const allChildrenChecked = childrenIds?.every((id) =>
          questionData?.answers_id.includes(id)
        );

        newParentCheckboxState[item?.id] = {
          checked: allChildrenChecked,
        };
      });

    setParentCheckboxState(newParentCheckboxState);
  }, [postListData, checklistData]);

  useEffect(() => {
    getChecklistData();
  }, []);

  return (
    <>
      <Styled.ExamData>
        <div className="exam">
          {induction.induction}
          <span className="form">{induction.check}</span>
        </div>
        <Styled.Security>
          <div>
            <div className="name">
              {exam.name}
              <span className="title">{location?.state?.username}</span>
            </div>
            <div className="name">
              {exam.title}
              <span className="title">{location?.state?.title}</span>
            </div>
          </div>
        </Styled.Security>
        <div className="how">{induction?.boxes}</div>
        {checklistData &&
          checklistData?.map((item, index) => (
            <Styled.Tables key={item?.question.split(" ").join("") + index}>
              <table style={{ width: "651px" }} className="tableData">
                <colgroup>
                  <col span="1" style={{ width: "87%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>{item?.question}</th>
                    <th className="column-style">
                      <Checkbox
                        checked={parentCheckboxState[item.id]?.checked || false}
                        onChange={(e) => handleTickAll(item?.id, e, index)}
                      />
                      <div className="tick">{induction.tick}</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {item?.children &&
                    item?.children?.map((child, ind) => (
                      <tr key={child?.question.split(" ").join("") + ind}>
                        <td>{child?.question}</td>
                        <td className="column-style">
                          <Checkbox
                            checked={postListData?.induction?.some(
                              (data) =>
                                data?.question_id === item?.id &&
                                data?.answers_id.includes(child?.id)
                            )}
                            onChange={(e) => handleChange(child?.id, e, index)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Styled.Tables>
          ))}
        <Styled.Acknowledge>
          <div className="ackno">
            <div className="train">{induction.trainee}</div>
            <div className="cover">{induction.covering}</div>
          </div>
          <div>
            <div className="comment">{induction.comm}</div>
            <Styled.Text
              name="comment"
              value={postListData?.comment}
              onChange={(e) => handleCommentChange(e)}
            />
            <Styled.Line />
            <Styled.Sign>
              {finalSign ? (
                <img
                  src={finalSign}
                  alt="signature"
                  style={{
                    width: "55%",
                    height: "100%",
                  }}
                />
              ) : (
                <button className="add" onClick={() => setModalOpen(true)}>
                  Add Signature
                </button>
              )}
              {/* {!finalSign && errors?.sign && (
                  <Styled.Error>{errors?.sign}</Styled.Error>
                )} */}

              <div className="datesS">
                {induction.dateS}
                <Styled.InputDate>{postListData?.formdate}</Styled.InputDate>
              </div>
            </Styled.Sign>
          </div>
        </Styled.Acknowledge>
        <div className="secured">
          <Button
            title={"Submit Checklist"}
            className="start"
            onClick={() => handleSubmit()}
          />
        </div>
      </Styled.ExamData>
      {modalOpen && (
        <SignatureModal
          setFinalSign={setFinalSign}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
        />
      )}
    </>
  );
};

export default InductionCheckData;
