import * as Styled from "./style";
import React from "react";
import { MdClose } from "react-icons/md";
import { ModalItem } from "../data";
import { Tabs } from "antd";
import { Certificate } from "./Certificate";
import TrainingRecord from "../../../TraineeRecord/TrainingRecord";

const CompletedCourseModal = ({
  certificatePreview,
  setCertificatePreview,
}) => {
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Certificate",
      children: (
        <Certificate
          certificatePreview={certificatePreview}
        />
      ),
    },
    {
      key: "2",
      label: "Training Record",
      children: (
        <TrainingRecord
          certificatePreview={certificatePreview}
        />
      ),
    },
  ];

  return (
    <>
      <Styled.ModalContainer
        style={{
          top: 20,
        }}
        width={850}
        footer={null}
        closeIcon={false}
        open={certificatePreview}
        onOk={() => setCertificatePreview(false)}
        onCancel={() => setCertificatePreview(false)}
      >
        <Styled.ModalHeader>
          <Styled.ModalTitle>{ModalItem.certificateModal}</Styled.ModalTitle>
          <Styled.Icon onClick={() => setCertificatePreview(false)}>
            <MdClose className="closeIcon" />
          </Styled.Icon>
        </Styled.ModalHeader>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </Styled.ModalContainer>
    </>
  );
};
export default CompletedCourseModal;
