import Footer from "../../components/Organisms/Footer/Footer";
import React from "react";
import { CheckoutHeader } from "../../components/Atoms/CheckoutHeader/CheckoutHeader";
import { CheckoutPage } from "../../containers/Checkoutpage/CheckoutPage";

const CheckOutPage = () => {
  return (
    <>
      <CheckoutHeader isCheck={true} />
      <CheckoutPage />
      <Footer />
    </>
  );
};

export default CheckOutPage;
