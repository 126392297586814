import { createSlice } from "@reduxjs/toolkit";
const UploadCSVSlice = createSlice({
  name: "uplaodCSV",
  initialState: {
    uplaodCSV: [],
    loading: false,
    error: null,
  },
  reducers: {
    uploadStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    uplaodSuccess: (state, action) => {
      state.loading = false;
      state.uplaodCSV = action.payload;
    },
    uplaodFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { uplaodStart, uplaodSuccess, uplaodFailure } =
  UploadCSVSlice.actions;
export default UploadCSVSlice.reducer;
