import { message, Tabs } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import * as Styled from "./style";
import Signature from "../../Atoms/Signature/Signature";
import HttpApis from "../../../services/HttpApis";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";

const SignatureModal = ({
  fetchLicensesList,
  setFinalSign,
  setModalOpen,
  modalOpen,
  setSign = () => null,
}) => {
  const [tabIndex, setTabIndex] = useState(1);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageError, setSelectedImageError] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [text, setText] = useState("");
  const signatureRef = useRef();
  const [signFile, setSignFile] = useState();
  const dragCounter = useRef(0);
  const textRef = useRef(null);
  const userDetail = useSelector((state) => state?.authReducer?.user);

  const handleModalOk = async () => {
    let response;
    if (
      userDetail?.type === "Finance Account" ||
      userDetail?.type === "Operations"
    ) {
      response = await HttpApis.licensesSignature({ sign: signFile });
      if (response?.status === 200) {
        message.success(response?.data?.message);
        fetchLicensesList();
      }
    }
    setFinalSign(signFile);
    setSign(signFile);
    setModalOpen(false);
    return response;
  };

  const handleGenerateImage = async () => {
    localStorage.setItem("sign", true);
    const element = textRef.current;
    html2canvas(element).then((canvas) => {
      const screenshot = canvas.toDataURL("image/png");
      setSignFile(screenshot);
    });
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func();
      }, delay);
    };
  };

  const debouncedGenerateImage = useMemo(
    () => debounce(handleGenerateImage, 500),
    []
  );

  const handleTextInput = (e) => {
    setText(e.target.value);
    debouncedGenerateImage();
  };

  const onFileChange = async (file) => {
    setSelectedImage(file);
    if (file && file?.size < 5 * 1024 * 1024) {
      setSelectedImageError(false);
      localStorage.setItem("sign", true);
      let base64String;
      const reader = new FileReader();
      reader.onloadend = () => {
        base64String = reader.result;
        setSignFile(base64String);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImageError(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragging(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current = 0;
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onFileChange(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  const handleSubmit = async () => {
    localStorage.setItem("sign", true);
    const data = signatureRef?.current?.toDataURL();
    setSignFile(data);
  };

  const tabChange = (key) => {
    setTabIndex(key);
    setSignFile(null);
    setText("");
    setSelectedImage("");
    setSelectedImageError(false);
    localStorage.setItem("sign", false);
    signatureRef?.current?.clear();
  };

  const items = [
    {
      key: "1",
      label: "Draw",
      children: (
        <Signature
          signatureRef={signatureRef}
          handleSubmit={handleSubmit}
          userDetail={userDetail}
          fetchLicensesList={fetchLicensesList}
        />
      ),
    },
    {
      key: "2",
      label: "Image",
      children: (
        <>
          <Styled.SignUploadInputWrapper
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            dragging={dragging}
          >
            <div>
              <label for="files" className="uploadImageText">
                Select Image
              </label>
              <input
                id="files"
                style={{ display: "none" }}
                type="file"
                onChange={(e) => onFileChange(e.target.files[0])}
              />

              {selectedImage?.name && <p>{selectedImage?.name}</p>}
            </div>
          </Styled.SignUploadInputWrapper>
          <Styled.ChildText>
            <div className="imageError">
              {!selectedImageError ? "" : "Image size should be less than 5MB"}
            </div>
            {dragging ? "Drop the image here..." : "Select or Drag Image"}
          </Styled.ChildText>
        </>
      ),
    },
    {
      key: "3",
      label: "Type",
      children: (
        <>
          <Styled.SignText>
            <Styled.TextInput
              type="text"
              value={text}
              onChange={(e) => handleTextInput(e)}
              ref={textRef}
            />
          </Styled.SignText>
          <Styled.ChildText>Sign Here</Styled.ChildText>
        </>
      ),
    },
  ];
  return (
    <div>
      <Styled.ModalContainer
        style={{
          top: 40,
        }}
        width={700}
        closeIcon={false}
        open={modalOpen}
        onOk={handleModalOk}
        okText="Done"
        okButtonProps={{
          disabled: selectedImageError,
        }}
        onCancel={() => setModalOpen(false)}
      >
        <Styled.ModalHeader>
          <Styled.ModalTitle>Add Signature</Styled.ModalTitle>
          <Styled.Icon onClick={() => setModalOpen(false)}>
            <MdClose className="closeIcon" />
          </Styled.Icon>
        </Styled.ModalHeader>
        <Tabs defaultActiveKey={tabIndex} items={items} onChange={tabChange} />
      </Styled.ModalContainer>
    </div>
  );
};

export default SignatureModal;
