import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Pipe = styled.div`
  display: flex;
  background-color: ${color._6091a8};
  color: ${color._6091a8};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 2px;
  height: 20px;
  margin-top: 4px;
`;
export const TextContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
  .courses {
    color: ${color._0f2a3c};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .number {
    color: ${color._6091a8};
    font-family: Open Sans;
    margin-top: 1px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Belowtext = styled.div`
  color: ${color._68828e};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const MainContainer = styled.div`
  margin-top: 26px;
  border-radius: 5px;
  border: 0.7px solid ${color._ededde};
  background: ${color._ffffff};
`;
export const TableHeader = styled.div`
  padding: 13px 20px 13px 17px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 14px;
  .tablecontainer {
    display: flex;
    gap: 12px;
  }
`;
export const SearchContainer = styled.div`
  gap: 5px;
  .searchIcon {
    color: ${color._16384E};
    margin-top: 3px;
    margin-left: 6px;
  }
  display: flex;
  align-items: center;
  width: 210px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
`;

export const NewestFirst = styled.div`
  .newest {
    border: none;
    padding-right: 82px;
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Filter = styled.div`
  padding: 10px 0px;
  display: flex;
  width: 210px;
  gap: 5px;
  height: 36px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 8px;

  .filter {
    font-size: 12px;
  }
`;

export const DotIcon = styled.div`
  width: 38px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  align-items: center;
  display: flex;
  justify-content: center;
`;
export const ProgressText = styled.div`
  color: #ffffff;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 0px 0px 20px 0px;
`;
export const ShaunText = styled.div`
  color: ${color._0073DE};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const ShaunLine = styled.div`
  width: 78px;
  height: 3px;
  background: ${color._0073de};
  margin-top: 17px;
`;
export const BorderLine = styled.hr`
  margin: 0;
  height: 1px;
  color: ${color._D9D9D9};
`;
export const Text = styled.div`
  color: rgba(96, 145, 168, 1);
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 30px;
`;
export const ModalBox = styled.div`
  /* .ant-modal .ant-modal-content {
    padding: 0px !important;
  } */
  .ant-modal-content {
    padding: 0px !important;
  }
  .modalStyle .ant-modal-content {
    padding: 0px !important;
  }
  /* .ant-modal-content {
    padding: 40px 30px;
  } */

  &.ant-modal {
    max-width: calc(100% - 32px);
  }
`;
export const HeaderContainer = styled.div`
  .ant-progress-tex {
    color: #ffffff;
  }
  background: rgba(22, 56, 78, 1);
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  .modalStyle .ant-modal-content {
    padding: 0px !important;
  }
  .progressBar {
    width: 20%;
    color: #ffffff;
    .ant-progress .ant-progress-text.ant-progress-text-outer {
      color: #ffffff;
    }
  }
`;
export const MainBoxContainer = styled.div`
  padding: 0px 25px;
`;

export const CoursesBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
  .course {
    font-size: 14px;
    font-weight: 400;
  }
  .courseName {
    font-size: 18px;
    font-weight: 700;
    color: rgba(22, 56, 78, 1);
  }
`;
export const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-start;
  .boxContainer1 {
    flex: 0.7;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;
export const Spreadsheet = styled.div`
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 32px 0px 11px;
  display: flex;
  align-items: center;
  width: 210px;
  height: 36px;
  gap: 10px;
  color: ${color._000000};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Input = styled.input`
  outline: none;
  border: none;
  flex-shrink: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  color: ${color._000000};
  &::placeholder {
    color: ${color._000000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const CoursesList = styled.div`
  flex: 0.7;
  padding: 10px;
  background: rgba(245, 251, 254, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .completeBox {
    display: flex;
    align-items: center;
    gap: 7px;
  }
  .heading {
    color: rgba(23, 122, 156, 1);
    font-size: 15px;
    font-weight: 500;
  }
  .text {
    font-size: 16px;
    font-weight: 600;
  }
`;
export const RightBox = styled.div`
  flex: 0.3;
  background: rgba(241, 251, 255, 1);
  flex: 0.3;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  .numberText {
    color: #15374d;
    font-size: 16px;
    font-weight: 600;
  }
  .num {
    font-size: 40px;
    font-weight: 900;
    color: #17394f;
  }
  .numBox {
    font-size: 40px;
    font-weight: 900;
    color: #5193aa;
  }
  .boxContainer {
    padding: 25px;
  }
`;
