import React from 'react'
import MainLayout from '../../layout/MainLayout'
import Contact from '../../containers/ContactPage/Contact'

export const ContactPage = () => {
  return (
    <MainLayout>
    <Contact/>
    </MainLayout>
  )
}
