import Footer from "../components/Organisms/Footer/Footer";
import React from "react";
import { Navigation } from "../components/Organisms/Navigation/Navigation";

const MainLayout = ({ children }) => {
  return (
    <>
      <Navigation />
      <div>{children}</div>
      <Footer />
    </>
  );
};

export default MainLayout;
