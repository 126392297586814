import styled from "styled-components";
import { color } from "../../theme/Color";
import BackgroundImageUn from "../../assets/image/BackgroundImageUn.jpg";

export const Container = styled.div`
  display: flex;
  overflow-x: hidden;
  @media only screen and (max-width: 800px) {
    justify-content: center;
    background: url(${BackgroundImageUn});
    background-size: cover;
    background-position: center;
  }
`;
export const SidebarContainer = styled.div`
  flex: 0.35;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export const ImgContainer = styled.div`
  display: none;

  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding-bottom: 10%;
  }
`;

export const Log = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImgLogo = styled.img`
  width: 116.245px;
  height: 134.121px;
`;

export const ParaDiv = styled.img`
  position: relative;
  top: 19%;
`;

export const IconContainer = styled.div`
  display: none;
  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Img2 = styled.p`
  margin-bottom: 30px;
`;

export const LogoImg = styled.img`
  width: 155.912px;
  height: 36.032px;
`;

export const Icons = styled.p`
  color: ${color._ffffff};
  transform: scale(2);
  display: flex;
  justify-content: center;
  gap: 5%;

  .icon {
    width: 13.852px;
    height: 13.852px;
  }
`;

export const FormContainer = styled.div`
  flex: 0.65;

  @media only screen and (max-width: 800px) {
    padding-top: 40%;
    padding-bottom: 80%;
    height: 100%;
  }
`;

export const ImageContainer = styled.div`
  background: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  @media (min-height: 850px) {
    height: 100vh;
  }
`;

export const LabelCount = styled.div`
  padding-top: 30px;
  position: relative;
`;

export const Head = styled.div`
  padding-bottom: 28px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media only screen and (max-width: 800px) {
    padding-bottom: 52px;
  }
`;
export const MainContainer = styled.div`
  height: 100%;
  padding: 79px 0px 79px 0px;

  @media only screen and (max-width: 800px) {
    padding: 15% 0%;
  }
`;
export const EmployeeContainer = styled.div`
  margin: 0 auto;
  width: 88%;
  margin-top: 25px;
  margin-bottom: 35px;
`;
export const Box = styled.div`
  background: ${color._fff};
  margin: 0 auto;
  height: 450px;
  width: 84%;
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${color._eaedef};
  margin: 10px auto;
`;
export const Paragraph = styled.div`
  padding-top: 5px;
`;
export const ParaDec = styled.div`
  word-break: break-word;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.66);
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const EmailPara = styled.div`
  margin: 0 auto;
  width: 94%;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;
export const Columns = styled.div``;
export const Label = styled.label`
  color: ${color._000000};
  padding-bottom: 4px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;

  line-height: 95%;
  .star {
    color: ${color._f20000};
  }
  gap: 3px;
  display: flex;
`;

export const Input = styled.input`
  &.inputEmp {
    width: 304px;
    height: 38px;
    border-radius: 3px;

    background: ${color._F6F6F6};
  }
  width: 86%;
  height: 35px;
  border-radius: 3px;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
  margin-top: 8px;
`;
export const EmpBtn = styled.div`
  flex: 0.4;
  display: flex;
  align-items: end;
  padding-bottom: 1px;
  .ant-btn-primary {
    height: 54%;
    background: ${color._009BE3};
    border-radius: 0;
    text-align: center;

    width: 286px;
    box-shadow: none;
    height: 37px;
    flex-shrink: 0;
    border-radius: 2px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
  }
`;
export const EmpData = styled.div`
  margin-top: 10px;
  flex: 0.6;
`;
export const CheckboxEmail = styled.div`
  display: flex;
  margin: 0px auto;
  width: 100%;
  justify-content: space-between;
`;

export const SubmitMessage = styled.div`
  .ant-result .ant-result-title {
    font-size: 21px;
  }
  .ant-result-subtitle {
    color: ${color._0090BD};
  }
  .ant-result-icon {
    margin-bottom: 0px;
    text-align: center;
  }
  width: 70%;
  margin: 0 auto;
  background-color: ${color._F1FBFFE3};
  position: relative;
  top: 9%;
  color: ${color._16384e};
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Message = styled.div`
  height: 420px;
`;
export const SubmitDeclaration = styled.div`
  width: 76%;
  margin: 0 auto;
  margin-top: 50px;
  background-color: ${color._F1FBFFE3};
  height: 16rem;
  color: ${color._16384e};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  .ant-result .ant-result-title {
    font-size: 21px;
  }
  .ant-result-subtitle {
    color: ${color._0090BD};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ant-result-icon {
    margin-bottom: 0px;
    text-align: center;
  }
`;
export const Para = styled.div`
  margin: 0 auto;
  word-spacing: 3px;
  color: rgba(0, 0, 0, 0.66);
`;

export const ContainerSingle = styled.div`
  width: 846px;
  height: 550px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #eff3f9;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  .backInfo {
    color: ${color._f1fbff};
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Open Sans;
    height: 53px;
    font-size: 16px;
    background: ${color._5193aa};
    border-radius: 20px 20px 2px 2px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .rows {
    padding: 43px 54px;
  }
`;
