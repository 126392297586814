import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useEffect, useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { CompletedCourses } from "../BusinessUserCompletedCourses/CompletedCourses";
import { dataItem } from "./ButtonData";
import { useCompletedCourse } from "../../../hooks/CompletedCourseHook";
import { CoursesInProgress } from "../BusinessUserCoursesInProgress/CoursesInProgress";
import useCommonHook from "../../../hooks/CommonHook";

const CoursesDetail = ({ show, setShow }) => {
  const [showComponent, setShowComponent] = useState(0);
  const {
    completedCourse,
    completedDataFetched,
    getCompleteCourseData,
    loading,
    tableParams,
    sortOrder,
    search,
    handleSearch,
    handleType,
    handleTableChange,
    certificatePreview,
    setCertificatePreview,
    inProgressCourse,
    modal,
    courseCount,
    setModal,
    downloadData,
  } = useCompletedCourse();
  const { useDebounce } = useCommonHook();
  const debouncedSearch = useDebounce(search, 500);
  let type = completedDataFetched && completedDataFetched[0]?.type;

  const handleClick = (id) => {
    setShowComponent(id);
  };

  useEffect(() => {
    getCompleteCourseData({
      ...debouncedSearch,
      sort: `createdAt|${sortOrder}`,
      page: tableParams.pagination.current,
      type: showComponent === 0 ? "Pass" : "Incomplete",
      courseId: show?.courseId,
    });
  }, [debouncedSearch, tableParams, sortOrder, showComponent]);

  const buttonData = [
    {
      id: 0,
      title: `Complete`,
      type: "Pass",
    },
    {
      id: 1,
      title: "In Progress",
      type: "Incomplete",
    },
  ];
  return (
    <>
      <Styled.Container>
        <Styled.BackContainer onClick={() => setShow(false)}>
          <FaLongArrowAltLeft className="icon" />
          <Styled.Back>{dataItem.Back}</Styled.Back>
        </Styled.BackContainer>
        <Styled.CourseName>
          {show?.course?.title ? show?.course?.title : ""}
        </Styled.CourseName>
        <Styled.Line />
        <Styled.Main>
          {buttonData?.map((item, index) => {
            return (
              <Styled.Wrapper>
                <Button
                  key={index}
                  title={`${item?.title} | ${
                    item.type === type ? courseCount : 0
                  }`}
                  className={
                    item?.id === showComponent ? "active" : "nonActive"
                  }
                  onClick={() => handleClick(item?.id)}
                />
              </Styled.Wrapper>
            );
          })}
        </Styled.Main>
      </Styled.Container>
      <Styled.Component>
        {showComponent === 0 && (
          <CompletedCourses
            completedCourse={completedCourse}
            completedDataFetched={completedDataFetched}
            loading={loading}
            tableParams={tableParams}
            certificatePreview={certificatePreview}
            setCertificatePreview={setCertificatePreview}
            sortOrder={sortOrder}
            search={search}
            handleSearch={handleSearch}
            handleTableChange={handleTableChange}
            handleType={handleType}
            downloadData={downloadData}
          />
        )}
        {showComponent === 1 && (
          <CoursesInProgress
            inProgressCourse={inProgressCourse}
            completedDataFetched={completedDataFetched}
            loading={loading}
            tableParams={tableParams}
            modal={modal}
            setModal={setModal}
            sortOrder={sortOrder}
            search={search}
            handleSearch={handleSearch}
            handleTableChange={handleTableChange}
            handleType={handleType}
          />
        )}
      </Styled.Component>
    </>
  );
};

export default CoursesDetail;
