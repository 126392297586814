import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Maincontainer = styled.div`
  margin-top: 59px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  .non-active {
    border-radius: 2px;
    background: ${color._f1fbff};
    border: none;
    color: ${color._177a9c};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    width: 155px;
    height: 35px;
  }
  .active {
    background: ${color._177a9c};
    color: ${color._ffffff};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    border: none;
    border-radius: 2px;
    width: 155px;
    height: 35px;
  }
`;

export const Header = styled.div`
  padding-bottom: 21px;
  padding-top: 46px;
  .invitedText {
    color: ${color._16384E};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .text {
    padding-top: 7px;
    color: ${color._68828e};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const Progress = styled.div`
  border-radius: 0px 0px 5px 5px;
  background: ${color._f1fbff};
  padding: 11px;
  .yourProgress {
    color: ${color._16384E};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete {
    color: ${color._000};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .progress {
    justify-content: space-between;
    background: none;
  }
`;
export const StartButton = styled.div`
  .start {
    margin-top: 10px;
    margin-bottom: 7px;
    border: none;
    color: ${color._177a9c};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 5px;
    background: ${color._f1fbff};
    width: 145px;
    height: 35px;
  }
`;
export const CardBox = styled.div`
    display: flex;
    gap: 40px;
    padding-bottom: 50px;
    flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
