import * as Styled from "./style";
import React from "react";
import { Checkbox, Row } from "antd";
import { constant } from "../../constants/Constants";

import {
  declarationSingle,
  employeeDetailConstant,
} from "../SingleSignUp/SingleSignData";

const DeclarationFormSingle = (props) => {
  const { userConfirmation, handleUserConfirmation } = props;
  return (
    <>
      <Styled.NextLine />
      <Styled.CheckRadio>
        <Styled.ContainerSingle>
          <div className="backInfo">{declarationSingle.backInfo}</div>
          <div className="rows">
            <Row span={8}>
              <Checkbox
                value={userConfirmation?.yearbackgroundcheck}
                name="yearbackgroundcheck"
                onChange={handleUserConfirmation}
                className="content"
              >
                {constant.Confirm}
              </Checkbox>
            </Row>
            <Styled.Lined />
            <Row span={8}>
              <Styled.UserRadioButton>
                <input
                  type="radio"
                  name="userType"
                  value="isEmployee"
                  className="content"
                  onChange={handleUserConfirmation}
                />
                <label>{constant.Industry}</label>
              </Styled.UserRadioButton>
              <Styled.Paragraph>
                <Styled.Para>{constant.Employer}</Styled.Para>
              </Styled.Paragraph>
            </Row>
            <Styled.Lined />
            <Row span={8}>
              <Styled.UserRadioButton>
                <input
                  type="radio"
                  name="userType"
                  value="isRoleTrader"
                  className="content"
                  onChange={handleUserConfirmation}
                />
                <label> {constant.SelfSole}</label>
              </Styled.UserRadioButton>
              <Styled.Paragraph>
                <Styled.Para>{employeeDetailConstant.confirmation}</Styled.Para>
              </Styled.Paragraph>
            </Row>
          </div>
        </Styled.ContainerSingle>
      </Styled.CheckRadio>
      <Styled.NextLine />
    </>
  );
};

export default DeclarationFormSingle;
