import Checkbox from "../../../assets/Svg/Checkbox.svg";

export const Checkboxdata = [
  {
    id: 1,
    name: <img src={Checkbox} alt="" checkbox />,
  },
  {
    id: 2,
    name: <img src={Checkbox} alt="" checkbox />,
  },
  {
    id: 3,
    name: <img src={Checkbox} alt="" checkbox />,
  },
  {
    id: 4,
    name: <img src={Checkbox} alt="" checkbox />,
  },
  {
    id: 5,
    name: <img src={Checkbox} alt="" checkbox />,
  },
];
export const Orderdata = {
  Product: "Product",
  Quantity: "Quantity",
  Price: "Price",
  GeneralSecurity: "GENERAL SECURITY AWARENESS TRAINING",
  X5: "X5",
  value: "10.00",
  Subtotal: "Subtotal:",
  PaymentMethod: "Payment Method",
  MasterCard: "Mastercard",
  Total: "Total",
  vat: "VAT",
};
export const Paragraph = {
  Payment: "Payment Successful",
  Thankyou: "THANK YOU FOR YOUR ORDER AND WELCOME TO AVTAV LTD",
  Purchase: "Your purchase has been submitted successfully.",
  AVTAVText:
    "AVTAV Training team are currently verifying the information provided. Verification usually take  ",
  Minutes: "5-10 minutes",
  Onto: " and you will receive an email with log in details to your AVTAV Account onto  ",
  Youremail: "youremail@email.com",
  Ifyou:
    "If you do not receive log in details within 2 hours of purchase, please check junk folder or contact AVTAV Training Team on  ",
  Email: "training@avtav.co.uk.",
  If: "*If you are a",
  Corporate: " corporate user",
  Multiple:
    "purchasing multiple licenses, you will receive a link to your admin account, from which you will be able to issue licenses to individuals, along with a full User Guide for your account.",
  OrderDetails: "Order Details",
  Order: "Order No.",
  No: " #10643",
  PrintInvoice: "Print invoice",
  DownloadInvoice: "Download invoice",
  Billing: "Billing address",
  BillingAddress:
    "3886 Old Ranger 5th Avenue Warwickshire, Rugby United Kingdom",
  ThanksFor: "Thanks for your purchase",
};
