import * as Styled from "./style";
import BackgroundImageUn from "../../assets/image/BackgroundImageUn.jpg";
import MultiStepProgressBar from "../../components/Atoms/MultiStepProgressBar/MultiStepProgressBar";
import React from "react";
import Sidebar from "../../components/Organisms/Sidebar/Sidebar";
import SignupHeader from "../../components/Atoms/SignupHeader/SignupHeader";
import useStepper from "../../hooks/StepperHook";
import { constant } from "../../constants/Constants";
import LogoSide from "../../../src/assets/Svg/LogoSide.svg";
import LogoTraining from "../../../src/assets/Svg/LogoTraining.svg";
import visitTraining from "../../../src/assets/Svg/visitTraining.svg";
import { FaFacebook } from "react-icons/fa";
import { AiFillLinkedin, AiOutlineInstagram } from "react-icons/ai";

const SingleSignUp = () => {
  const {
    SignUpSteps,
    next,
    prev,
    current,
    checkbox,
    radio,
    radio2,
    signUpData,
    errors,
    setErrors,
  } = useStepper();

  return (
    <Styled.Container>
      <Styled.SidebarContainer>
        <Styled.ImageContainer src={BackgroundImageUn} alt={constant.SignUp}>
          <Sidebar />
        </Styled.ImageContainer>
      </Styled.SidebarContainer>

      <Styled.FormContainer>
        <Styled.ImgContainer>
          <Styled.Log>
            <Styled.ImgLogo src={LogoSide}></Styled.ImgLogo>
          </Styled.Log>
          <Styled.ParaDiv src={visitTraining}></Styled.ParaDiv>
        </Styled.ImgContainer>

        <Styled.MainContainer>
          <Styled.Head>
            <SignupHeader title={constant.SingleUser} />
          </Styled.Head>
          <MultiStepProgressBar
            steps={SignUpSteps}
            nextButton={constant.Next}
            previousButton={constant.Previous}
            buttonHide={true}
            next={next}
            prev={prev}
            current={current}
            checkbox={checkbox}
            radio={radio}
            radio2={radio2}
            signUpData={signUpData}
            errors={errors}
            setErrors={setErrors}
            type="single"
          />
        </Styled.MainContainer>

        <Styled.IconContainer>
          <Styled.Img2>
            <Styled.LogoImg src={LogoTraining} />
          </Styled.Img2>
          <Styled.Icons>
            <FaFacebook className="icon" />
            <AiFillLinkedin className="icon" />
            <AiOutlineInstagram className="icon" />
          </Styled.Icons>
        </Styled.IconContainer>
      </Styled.FormContainer>
    </Styled.Container>
  );
};

export default SingleSignUp;
