import HttpApis from "../services/HttpApis";
import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useDispatch } from "react-redux";
import { corporateDeclarationFormSuccess } from "../redux/slices/declarationSlice";

import {
  CourseName,
  Courses,
  DeclarationPreview,
  InvoiceDownload,
  RenderDate,
} from "../containers/Corporate/BusinessUserLicense/CustomRender";

export const useDecarationHook = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [downloadData, setDownloadData] = useState();
  const [declarationPreview, setDeclarationPreview] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const generateAndDownloadPDF = async (selector) => {
    const captureParent = document.querySelector(selector);
    captureParent.scrollTop = captureParent.scrollHeight;
    captureParent.style.opacity = 1;
    captureParent.style.height = "1px";
    captureParent.style.overflow = "auto";
    const capture = document.querySelector(`${selector} > div`);
    capture.style = {};
    var height = capture.offsetHeight;
    capture.style.width = `1300px`;
    capture.style.height = `${height}px`;
    html2canvas(capture).then((canvas) => {
      const doc = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("img/jpg");
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save("Declaration Form.pdf");
      captureParent.style.opacity = 0;
      captureParent.style.height = "1px";
      captureParent.style.overflow = "auto";
    });
  };

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const corporateDeclarationForm = async (params) => {
    try {
      setLoading(true);
      const response = await HttpApis.getCorporateDeclarationForm(params);
      dispatch(corporateDeclarationFormSuccess(response.data));
      if (response.status === 200) {
        setLoading(false);
      }
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (downloadData) {
      setTimeout(() => {
        generateAndDownloadPDF(".declaration-form");
      }, 1000);
    }
  }, [downloadData]);

  const TableDeclaration = [
    {
      label: "Date Added",
      mappedkey: "createdAt",
      defaultSortOrder: "descend",
      sorter: true,
      customRender: RenderDate,
    },
    {
      label: "Trainee Name",
      mappedkey: "traineeName",
      customRender: Courses,
    },
    {
      label: "Course Name",
      mappedkey: "course",
      customRender: CourseName,
    },
    {
      label: "Declaration Form",
      mappedkey: "declarationForm",
      action: (row) => {
        setDeclarationPreview(row);
      },
      customRender: DeclarationPreview,
    },
    {
      label: "Download",
      mappedkey: "Download",
      action: (row) => {
        setDownloadData(row);
      },
      customRender: InvoiceDownload,
    },
  ];

  return {
    TableDeclaration,
    corporateDeclarationForm,
    showModal,
    downloadData,
    declarationPreview,
    setDeclarationPreview,
    loading,
  };
};
