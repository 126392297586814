import AccountSecurity from "./AccountSecurity/AccountSecurity";
import SingleProfile from "./Profile/SingleProfile";
import { BillingSingleUser } from "./BillingSingleUser/BillingSingleUser";
import { CertificateScreen } from "./Certificate/CertificateScreen";
import { MyCourses } from "./MyCourses/MyCourses";
import { SingleDeclarationScreen } from "./SingleDeclarationForm/SingleDeclarationScreen";

export const tabData = [
  {
    key: "1",
    label: "Profile",
    children: <SingleProfile />,
    align: false,
  },
  {
    key: "2",
    label: "Account Security",
    children: <AccountSecurity />,
    align: false,
  },
  {
    key: "3",
    label: "Declaration Form",
    children: <SingleDeclarationScreen />,
    align: false,
  },
  {
    key: "4",
    label: "Billing",
    children: <BillingSingleUser />,
    align: false,
  },
  {
    key: "5",
    label: "My Courses",
    children: <MyCourses />,
    align: true,
  },
  {
    key: "6",
    label: "Certificates",
    children: <CertificateScreen />,
    align: false,
  },
];
