import React from 'react'
import * as Styled from "./style"
import { data } from './data'
import finalOfficeIdea from "../../../assets/image/finalOfficeIdea.jpg";


 const AboutBanner = () => {
  return (
    <div className="container">
    <Styled.AboutBox>
      <div className="leftContainer">
        <div className="textContainer">
          <div className="avtavheading">{data.heading}</div>
          <p className="paragraphText">{data.paragraph1}</p>
          <p className="paragraphText">{data.paragraph2}</p>
        </div>
      </div>
      <div className="rightContainer">
        <img
          src={finalOfficeIdea}
          alt="finalOfficeIdea"
          className="officeImage"
        />
      </div>
    </Styled.AboutBox>
    </div>
  )
}

export default AboutBanner