import MainLayout from "../../layout/MainLayout";
import React from "react";
import TermsAndUse from "../../containers/InvitedUserSignUp/TermsAndUse";

const TermsPage = () => {
  return (
    <MainLayout>
      <TermsAndUse />
    </MainLayout>
  );
}

export default TermsPage;
