import styled from "styled-components";
import { color } from "../../theme/Color";

export const MainContainer = styled.div`
  .ltd {
    margin: 15px auto;
    color: ${color._000000};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const Record = styled.div`
  margin: 34px auto;
  display: flex;
  gap: 20px;
  justify-content: center;
  .img {
    display: flex;
    gap: 10px;
  }
  .avtav {
    width: 210.938px;
    height: 50px;
  }
`;
export const Training = styled.div`
  margin-top: 40px;
  .exam {
    color: ${color._177a9c};
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .form {
    color: ${color._000000};
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const NameJohn = styled.div`
  margin: 17px auto;
  display: flex;
  gap: 6px;
  .emp {
    width: 420px;
    height: 38px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._f3fbff};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .john {
    width: 293px;
    height: 38px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._f3fbff};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .deo {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: ${color._f3fbff};
  }

  .nameemp {
    color: ${color._16384e};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
`;
export const ModuleCompleted = styled.div`
  .dataCourse {
    color: ${color._15374d};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 22px auto;
  }
`;
export const InputDate = styled.div`
  flex-shrink: 0;
  outline: none;
  border: none;
  background: ${color._F3FBFF};
  text-transform: capitalize;
  &::placeholder {
    color: ${color._15374d};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const Syllabus = styled.div`
  .datetime {
    display: flex;
    color: ${color._15374d};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  border: 0.5px solid ${color._DADADA};
  width: 716px;
  .issue {
    color: ${color._000000};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .syllabus {
    height: 39px;
    flex-shrink: 0;
    background: ${color._15374d};
  }
  .certificate {
    height: 37px;
    display: flex;
    padding: 10px;
    flex-shrink: 0;
    background: ${color._eef0fc};
    gap: 10px;
  }
  .general {
    display: flex;
    padding: 10px;
    gap: 20px;
    color: ${color._ffffff};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .date {
    align-items: center;
    height: 39px;
    flex-shrink: 0;
    background: ${color._f3fbff};
    display: flex;
    justify-content: space-between;
    padding: 8px;
  }
  .complete {
    height: 39px;
    background: ${color._5193aa};
    color: ${color._ffffff};
    padding: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .threat {
    height: 39px;
    color: ${color._5f5f5f};
    padding: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const SingleLine = styled.div`
  width: 716px;
  height: 0.5px;
  background: ${color._DADADA};
`;

export const Sign = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .sign {
    align-items: center;
    display: flex;
    gap: 10px;
  }
  .cin {
    align-items: center;
    display: flex;
    gap: 15px;
    width: 40%;
    font-size: 14px;
    font-weight: 600;
  }
  .instruct {
    color: ${color._15374d};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  margin: 40px auto;
`;
