import { styled } from "styled-components";
import { color } from "../../../theme/Color";

export const Container = styled.div`
  // box-shadow: 4px 4px 25px 3px rgba(21, 55, 77, 0.28);
`;
export const BGImage = styled.div`
  box-shadow: 0px -12px 1px 8px ${color._6091a8};
  background-image: url(${(props) => props.src});
  height: 575px;
  border-radius: 2px;
  margin-bottom: 40px;
  @media only screen and (max-width: 800px) {
    height: 100%;
    margin-bottom: 50px;
  }
`;
export const CenterImage = styled.div`
  padding-top: 15px;
  padding-bottom: 30px;
`;
export const SecurityCheck = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const MainContainer = styled.div`
  display: flex;
  height: 44px;
  .add_to_cart {
    width: 347px;
    border-radius: 2px;
    background: ${color._ffc636};
    color: ${color._15374d};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    @media only screen and (max-width: 800px) {
      font-size: 30px;
    }
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
  }
`;
export const FirstRow = styled.div`
  display: inline-block;
  width: 82%;
  color: ${color._fff};
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 32px;
  }
`;
export const Icon = styled.div`
  color: ${color._fff};
  text-align: center;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  .code {
    font-family: math;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
  }
  @media only screen and (max-width: 800px) {
    .code {
      font-size: 37px;
    }
    font-size: 37px;
  }
`;
export const SecondRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 8%;
  margin-bottom: 35px;
  .add_ {
    display: flex;
    background: none;
    border-radius: 5px;
    color: ${color._fff};
    border: 0.5px solid ${color._6091a8};
    align-items: center;
    width: 31px;
    height: 31px;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
      width: 31px;
      height: 31px;
    }
  }
  .decrement {
    background: none;
    display: flex;
    border-radius: 5px;
    color: ${color._fff};
    border: 0.5px solid ${color._6091a8};
    align-items: center;
    width: 31px;
    height: 31px;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
      width: 31px;
      height: 31px;
    }
  }
  .count {
    display: flex;
    border-radius: 5px;
    border: 0.5px solid ${color._6091a8};
    align-items: center;
    background: ${color._fff};
    width: 40px;
    text-align: center;
    height: 31px;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
  }
`;

export const Enroll = styled.div`
  display: flex;
  height: 44px;
  .enroll {
    width: 347px;
    border-radius: 2px;
    background: ${color._007cd7};
    color: ${color._fff};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    @media only screen and (max-width: 800px) {
      font-size: 30px;
    }
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
  }
`;
export const Buttons = styled.div`
  margin-bottom: 13px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ButttonContainer = styled.div`
  display: flex;
  gap: 2px;
  .decrementIcon {
    width: 18.41px;
    height: 18.41px;
    color: #fff;
  }
`;

export const Input = styled.input`
  -moz-appearance: textfield;
  outline: none;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
`;
