import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Header = styled.div`
  background: #5193aa;
  height: 130px;
  display: flex;
  justify-content: center;
`;

export const HeaderContent = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
`;

export const Heading = styled.div`
  color: #177a9c;
  display: flex;
  font-weight: 700;
  font-size: 28px;
  margin-top: 30px;
`;

export const ItText = styled.div`
  color: ${color._16384E};
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const Date = styled.input`
  color: ${color._177a9c};
  text-align: justify;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  outline: none;
  border: none;
  &.fulldate {
    padding: 12px;
  }
`;
export const ListText = styled.div`
  .rulesText {
    padding-bottom: 13px;
  }
  padding-top: 13px;
  .backgroundtext {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  color: ${color._16384E};
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const InputTextRoll = styled.input`
  border: none;
  border-bottom: 1.5px dotted ${color._16384E};
  width: 20%;
  outline: none;
  color: ${color._177a9c};
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0px 10px;
`;

export const InputText = styled.input`
  border: none;
  border-bottom: 1.5px dotted ${color._16384E};
  width: 25%;
  outline: none;
  color: ${color._177a9c};
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0px 10px;
`;
export const LearnerName = styled.div`
  width: 100%;
  .confirm {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 157.4%;
  }
  .nameborder {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0px 10px;
  }
`;
export const BackgroundCheck = styled.div`
  .check {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 145.9%;
  }
  color: ${color._16384E};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.9%;
  padding-top: 7px;
`;
export const PleaseText = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 10px 0px;
  .please {
    color: ${color._177a9c};

    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
  .please::before {
    content: "*";
    color: ${color._F00E00};
    font-size: 15px;
    font-weight: 400;
  }
  .inputstyled {
    width: 17px;
    height: 17px;
  }
`;

export const BoxSize = styled.div`
  .airside {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .textContainer {
    color: ${color._16384E};
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 12px;
  }
`;
export const MALPRACTICE = styled.div`
  padding-top: 20px;
  .malpracticeText {
    padding-top: 15px;
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    line-height: normal;
  }
  .malpractice {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const AppropriateContainer = styled.div`
  padding-bottom: 21px;
  .appropriate {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 21px;
  }
  .purchasing {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 15px;
    text-align: justify;
  }
  .dftText {
    color: ${color._16384E};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 15px;
    text-align: justify;
  }
`;
export const BoxContainer = styled.div`
  display: flex;
`;
export const YourEmail = styled.div`
  flex: 0.3;
  display: flex;
  align-items: end;
  .company {
    padding-bottom: 8px;
    color: ${color._68828e};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .textContainer {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const CompanyRegNumber = styled.div`
  width: 50%;
  .company {
    padding-bottom: 8px;
    color: ${color._68828e};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .textContainer {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 17px;
  }
  .textContainer1 {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 65px;
  }
  .textContainer2 {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const CompanyName = styled.div`
  width: 50%;
  .nameContainer {
    display: flex;
    flex-direction: column;
  }
  .company {
    padding-bottom: 8px;
    color: ${color._68828e};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .companytext {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 16px;
  }
  .companytext1 {
    padding-bottom: 31px;
    color: ${color._177a9c};
    text-align: justify;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .companytext2 {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const Error = styled.div`
  color: #ff0000;
`;
export const LastContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 66px 39px;
  .add {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
  .date {
    color: ${color._68828e};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .fulldate {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .crossicon {
    color: ${color._15374D};
    text-align: justify;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 5px;
    align-items: center;
    border-bottom: 1px solid ${color._000000};
    padding-right: 25px;
  }
`;
export const JobRole = styled.div`
  margin-top: 22px;
`;
export const Input = styled.input`
  width: 299px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px solid ${color._DADADA};
  outline: none;
  background: ${color._ffffff};
`;
export const SoleTrader = styled.div`
  margin-top: 5px;
  .trader {
    padding: 20px;
    color: ${color._16384e};
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    height: 127px;
    flex-shrink: 0;
    background: rgba(242, 0, 0, 0.03);
  }
`;
export const Label = styled.label`
  color: ${color._68828e};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 95%;
  .star {
    color: ${color._f20000};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  .nameContainer {
    margin-bottom: 35px;
  }
  .company {
    padding-bottom: 18px;
    color: ${color._68828e};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
`;

export const InputField = styled.input`
  border: none;
  border-bottom: 2px solid ${color._000};
  width: 100%;
  outline: none;
  color: ${color._177a9c};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0px 5px;
`;

export const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MainScreen = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0px 50px 0px;
  .btn {
    border-radius: 2px;
    background: #5193aa;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
  }
`;

export const SignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  .add {
    color: ${color._177a9c};
    text-align: justify;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border: none;
    border-radius: 5px;
  }
`;

export const SignedImage = styled.div`
  margin-top: 5px;
  background-color: rgba(0, 0, 0, 0.02);
  width: 320px;
`;
