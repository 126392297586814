import { createSlice } from "@reduxjs/toolkit";

const myCourseSlice = createSlice({
  name: "myCourse",
  initialState: {
    invitedCourse: [],
    IndividualCourses: [],
    loading: false,
    error: null,
  },
  reducers: {
    myCourseSuccess: (state, action) => {
      state.loading = false;
      state.invitedCourse = action.payload;
    },
    myCourseFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.data;
    },

    individualCoursesSuccess: (state, action) => {
      state.IndividualCourses = action.payload;
    },
  },
});
export const { myCourseSuccess, myCourseFail, individualCoursesSuccess } =
  myCourseSlice.actions;
export default myCourseSlice.reducer;
