import React from 'react'
import { OrderConfirmation } from '../../containers/OrderConfirmationPage/OrderConfirmation'
import { CheckoutHeader } from '../../components/Atoms/CheckoutHeader/CheckoutHeader'

const OrderConfirmPage = () => {
  return (
    <div>
      <CheckoutHeader isCheck={false}/>
      <OrderConfirmation />
    </div>
  )
}

export default OrderConfirmPage
