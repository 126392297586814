import { createSlice } from "@reduxjs/toolkit";

const invoicePaymentSlice = createSlice({
  name: "invoiceList",
  initialState: {
    invoiceList: [],
    loading: false,
    error: null,
  },
  reducers: {
    invoiceSuccess: (state, action) => {
      state.loading = false;
      state.invoiceList = action.payload;
    },
    invoiceFail: (state, action) => {
      state.loading = false;
      state.error = action.payload.data;
    },
  },
});
export const { invoiceSuccess, invoiceFail } = invoicePaymentSlice.actions;
export default invoicePaymentSlice.reducer;
