import * as Styled from "./style";
import Button from "../../components/Atoms/Button";
import React, { useState } from "react";
import { EmpData } from "../../components/Molecules/SignAboutyou/Data";
import { constant } from "../../constants/Constants";
import { employeeDetailConstant } from "../SingleSignUp/SingleSignData";
import useDeclarationHook from "../../hooks/DeclarationDataHook";
import useStepper from "../../hooks/StepperHook";
import { message } from "antd";

const CheckDeclaration = ({ emailData, setEmailData }) => {
  const { userTypeConfirmationEmailApi } = useDeclarationHook();
  const [isClick, setIsClick] = useState(false);
  const { courseIds } = useStepper();

  const handleChangeEmail = (e) => {
    setEmailData({ ...emailData, [e?.target?.name]: e?.target?.value });
  };
  const handleEmail = async (data) => {
    setIsClick(true);
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("courseId", courseIds.toString());

    const resp = await userTypeConfirmationEmailApi(formData);
    if (resp?.status) {
      message.success("Email Sent Successfully");
    }
  };
  return (
    <>
      <Styled.NextLine />
      <Styled.InputCheck>
        <Styled.Box>
          <div className="backCheck">
            Background Check Declaration{" "}
            <span className="single">| Single User Declaration</span>
          </div>
          <Styled.EmailPara>
            <Styled.CheckboxEmail>
              <Styled.EmpData>
                {EmpData?.map((item, index) => {
                  return (
                    <Styled.Columns key={index}>
                      <Styled.Label>
                        {item?.label}
                        <span className="star">*</span>
                      </Styled.Label>
                      <Styled.Input
                        type={item?.type}
                        className="inputEmp"
                        name="email"
                        onChange={handleChangeEmail}
                      />
                    </Styled.Columns>
                  );
                })}
              </Styled.EmpData>
              <Styled.EmpBtn>
                {isClick == true ? (
                  <Button
                    title={constant.EmpButton}
                    onClick={() => handleEmail(emailData)}
                    className={"mailbtn"}
                    disabled
                  />
                ) : (
                  <Button
                    title={constant.EmpButton}
                    onClick={() => handleEmail(emailData)}
                    className={"empbtn"}
                  />
                )}
              </Styled.EmpBtn>
            </Styled.CheckboxEmail>
            <Styled.ParaDec>
              <p>{employeeDetailConstant.declare}</p>
            </Styled.ParaDec>
          </Styled.EmailPara>
        </Styled.Box>
      </Styled.InputCheck>
      <Styled.NextLine />
    </>
  );
};

export default CheckDeclaration;
