export const enquiryTypes = [
  { type: "Training" },
  { type: "Vetting" },
  { type: "Recruitment" },
];

export const formFieldLabels = {
  heading: "ENQUIRE NOW",
  enquiryType: "Enquiry Type",
  firstname: "First name",
  surname: "Surname",
  companyName: "Company Name (if applicable)",
  mobile: "Mobile",
  phone: "Phone",
  email: "Email",
  comments: "Comments",
  submit: "Submit",
  getInTouch: "Get In Touch",
};
