import styled from "styled-components";
import { color } from "../../../theme/Color";
import { Modal } from "antd";

export const ModalContainer = styled(Modal)`
  & .ant-modal-content {
    padding: 10px 40px;
  }
  &.ant-modal {
    max-width: calc(100% - 32px);
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalBox = styled.div`
  height: 200px;
  flex-shrink: 0;
  border-radius: 2px;
  background: ${color._0f2a3c};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
`;
export const Icon = styled.div`
  width: 38px;
  height: 38px;
  background: rgba(239, 243, 249, 0.38);
  border-radius: 28px;
  cursor: pointer;
  .closeIcon {
    height: 24px;
    width: 24px;
    color: rgba(109, 125, 139, 1);
    margin-top: 7px;
    margin-left: 7px;
  }
`;

export const ModalTitle = styled.div`
  color: ${color._16384e};
  font-family: Raleway;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 25px;
`;

export const SignUploadInputWrapper = styled.div`
  width: 620px;
  height: 200px;
  border: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .uploadImageText {
    border: 1px solid #f0f0f0;
    background: rgba(0, 0, 0, 0.08);
    padding: 5px 10px;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const SignText = styled.div`
  width: 70%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
export const SignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const SignedImage = styled.div`
  margin-top: 5px;
  background-color: rgba(0, 0, 0, 0.02);
  width: 320px;
`;

export const TextInput = styled.input`
  width: 60%;
  font-size: 18px;
  border-radius: 5px;
  padding: 10px 15px;
  border: 2px solid #f0f0f0;
`;

export const ChildText = styled.div`
  margin-top: 5px;
  text-align: center;
  .imageError {
    color: red;
  }
`;
