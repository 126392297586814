export const feedback = {
  feed: `Feedback`,
  how: `HOW DID WE DO?`,
  form: ` Form`,
  ques1: `QUESTION 1.`,
  ques2: `QUESTION 2.`,
  ques3: `QUESTION 3.`,
  ques4: `QUESTION 4.`,
  ques5: `QUESTION 5.`,
  navigate: `The course was easy to navigate`,
  strongagree: `Strongly Agree`,
  agree: ` Agree`,
  disagree: ` Disagree`,
  strongDis: ` Strongly Disagree.`,
  clear: `The course was put together in a clear, logical way.`,
  knowledge: `I have gained knowledge and confidence in the subject covered in all modules.`,
  colleague: `Based on your experience, you would recommend this course to a colleague?`,
  below: `If you have answered with ‘Disagree’ on any of the questions, or if you have any additional feedback, please state it in the box below:`,
  important: `Your feedback is important to us`,
  learn: `Learner`,
};
