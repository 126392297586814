import { styled } from "styled-components";
import contactbg from "../../assets/image/contactbg.jpg";
import { color } from "../../theme/Color";

export const ContactPageWrapper = styled.div`
  height: max-content;
`;
export const ImageWrapper = styled.div`
  height: 210px;
  background: url(${contactbg});
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ContactHeading = styled.div`
  color: ${color._ffffff};
  font-size: 50px;
  font-weight: bold;
`;

export const ContactDetailsWrapper = styled.div`
  width: 80%;
  margin: auto;
`;
export const DetailsHeading = styled.div`
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  line-height: 1;
  font-size: 34px;
  color: ${color._262626};
  font-weight: bold;
  height: 124px;
  display: flex;
  align-items: flex-end;
  padding: 10px;
`;

export const DetailsMainSection = styled.div`
  display: flex;
`;
export const DetailsSubSectionLeft = styled.div`
  width: 50%;
  border-right: 3px solid ${color._222E65};
  padding: 10px;
  display: flex;
  justify-content: space-around;
  /* align-items:center; */
  flex-direction: column;
`;
export const EmailSection = styled.div`
  font-size: 21px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${color._222222};
  padding: 10px;
  border-bottom: 1px solid black;
  height: 30%;
  .emailIcon {
    color: ${color._1C2568};
    font-size: 30px;
  }
  .emailText {
    color: ${color._050404};
    padding-left: 9px;
  }
  width: 95%;
`;
export const MediaIconWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px;
  .mediaIcon {
    font-size: 30px;
    color: ${color._ffffff};
    border-radius: 9px;
  }
`;
export const MediaIcon = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color._1C2568};
  border-radius: 9px;
`;
export const DetailsSubSectionRight = styled.div`
  width: 50%;
  padding: 0px 0px 0px 40px;
`;

export const TelephoneDetail = styled.div`
  font-size: 21px;
  display: flex;
  align-items: center;
  color: ${color._222222};
  padding: 14px 10px;

  .number {
    color: ${color._050404};
    padding-left: 15px;
  }
  .phoneIcon {
    color: ${color._1C2568};
    font-size: 25px;
  }
`;

export const MapWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom:30%;
  height: 0;
  .mapIframe{
    border: 0;
    height:100%;
    width:100%;
    left:0;
    top:0;
    position: absolute;
  }
`;
export const SubSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 10px;
`;
export const Tile = styled.div`
  cursor: pointer;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  font-weight: bold;
  color: ${color._17374C};
  border-radius: 9px;
  .tileIcon {
    font-size: 50px;
  }
  &:hover {
    color: ${color._ffffff};
    background-color: ${color._17374C};
    transition: background 1ms, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  }
`;
