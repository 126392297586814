import * as Styled from "./style";
import "animate.css";
import Button from "../../../components/Atoms/Button/button";
import React, { useEffect, useRef, useState } from "react";
import SideBarPage from "./SideBarPage";
import TrainingAwareness from "./TrainingAwareness";
import { FaChevronLeft, FaChevronRight, FaAngleDown } from "react-icons/fa";
import { RxSlash } from "react-icons/rx";
import { HeaderTraining } from "../Header/HeaderTraining";
import { course } from "./data";
import Reveal from "reveal.js";
import "reveal.js/dist/reveal.css";
import "reveal.js/dist/theme/white.css";
import "./style.css";
import parse, { domToReact } from "html-react-parser";
import { useCourseDetails } from "../../../hooks/CourseDetailsHook";
import { useSliderDetails } from "../../../hooks/SliderHook";
import HttpApis from "../../../services/HttpApis";
import { quizFailure, quizSuccess } from "../../../redux/slices/quizSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/routes";
import CustomAudioPlayer from "./customAudioPlayer";
import { reviewListSuccess } from "../../../redux/slices/reviewSllice";
import { message } from "antd";
import { Spin } from "antd";

const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return <a>Previous</a>;
  }
  if (type === "next") {
    return <a>Next</a>;
  }
  return originalElement;
};

const CoursesModule = () => {
  const [isListClick, setIsListClick] = useState(true);
  const [openQuiz, setOpenQuiz] = useState(false);
  const deckDivRef = useRef(null);
  const deckRef = useRef(null);
  const isLoader = useRef(false);
  const [isCourseDetailsFetched, setIsCourseDetailsFetched] = useState(false);
  const [isCourseQuizFetched, setIsCourseQuizFetched] = useState(false);
  const dispatch = useDispatch();
  const { coursesDetails } = useCourseDetails();
  const {
    postProgressList,
    getProgress,
    postCheckList,
    getCheckList,
    getQuizList,
    getCourseExpired,
  } = useSliderDetails();
  const [moduleName, setModuleName] = useState("");
  const timerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [formatedSliderData, setFormatedSliderData] = useState();
  const [formatData, setFormated] = useState({});
  const [moduleDurations, setModuleDurations] = useState({});
  const [moduleCount, setModuleCount] = useState(0);
  const courseId = coursesDetails?.course?.id;
  const moduleNameRef = useRef("");
  const quizNameRef = useRef("");

  const visitedModules = useRef([]);
  const moduleIndexRef = useRef(0);
  const slideIndex = useRef(0);
  const [revertedUserIndex, setRevertedUserIndex] = useState(0);
  const [quizData, setQuizData] = useState({
    courseId: courseId,
    module: "",
    question_id: 0,
    answers_id: [],
  });
  const courseName = coursesDetails?.course?.slug;
  const quiz = useSelector((state) => state?.SliderSlice?.getQuiz?.data);
  const isCourseExpired = useSelector(
    (state) => state?.SliderSlice?.getCourseExpired?.data
  );

  const quizDataRef = useRef(quiz || []);

  useEffect(() => {
    quizDataRef.current = quiz;
  }, [quiz]);

  useEffect(() => {
    if (isCourseExpired?.isExpired) {
      message.error("Your course has been expired!");
      navigate(Routes?.homePage);
    }
  }, [isCourseExpired?.isExpired]);

  const getReviewList = async () => {
    try {
      const response = await HttpApis.getReviewListApi(courseName, {
        params: {
          page: tablePagination,
          limit: 5,
        },
      });
      dispatch(reviewListSuccess(response?.data));
    } catch (error) {
      return null;
    }
  };

  const review_count = useSelector(
    (state) => state.reviewReducer?.reviewList?.data?.count
  );
  const [tablePagination, setTablePagination] = useState(1);
  const handlePageChange = (pagination) => {
    setTablePagination(pagination);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const progressData = useSelector((state) => state?.SliderSlice?.getProgress);
  const checkListData = useSelector(
    (state) => state?.SliderSlice?.getCheckList?.data?.checklistData
  );
  const userId = progressData?.data?.userId;

  let handleExamRoute = () => {
    const currentSlideIndex = deckRef.current.getState().indexh;

    if (currentSlideIndex == slider_HTMLData.length - 1) {
      navigate(Routes.examCourse, {
        state: {
          ...location?.state,
          moduleDurations: moduleDurations,
        },
      });
    } else {
      message.error("Please complete the course");
    }
  };

  const handleQuizChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setQuizData({
      ...quizData,
      answers_id: [Number(value)],
      question_id: Number(name),
      module: moduleName,
    });
  };

  useEffect(() => {
    getCheckList(courseId);
  }, [courseId]);

  useEffect(() => {
    getCourseExpired(courseId);
  }, [courseId]);

  useEffect(() => {
    setInterval(() => {
      getCourseExpired(courseId);
    }, 900000);
  }, [courseId]);

  const reordertheModules = (data = []) => {
    const map = new Map();
    data &&
      data?.forEach((item, index) => {
        map?.set(item?.moduleName, index);
      });
    const result = [];
    map?.forEach((_, moduleName) => {
      const matchingItems =
        data && data?.filter((item) => item?.moduleName === moduleName);
      result.push(...matchingItems);
    });
    return result;
  };

  let slider_HTMLData =
    coursesDetails?.course?.slider_html &&
    reordertheModules(JSON.parse(coursesDetails?.course?.slider_html));

  const [htmlData, setHtmlData] = useState(
    slider_HTMLData && slider_HTMLData[0]?.html
  );
  const [cssData, setCssData] = useState(
    slider_HTMLData && slider_HTMLData[0]?.css
  );
  const [audioUrl, setAudioUrl] = useState(
    slider_HTMLData && slider_HTMLData[0]?.audioUrl
  );

  const quizPostData = (param) => async (dispatch) => {
    try {
      const res = await HttpApis.postQuiz(param);
      dispatch(quizSuccess(res));
      return res;
    } catch (error) {
      return dispatch(quizFailure(error?.message));
    }
  };
  const handlePostQuiz = async (param) => {
    const res = await dispatch(quizPostData(param));
    return res;
  };
  const isSubmit = useRef(false);

  const handleSubmitQuiz = async () => {
    setIsCourseQuizFetched(true);
    const response = await handlePostQuiz([quizData]);
    isLoader.current = false;
    if (response.status === 200) {
      await getQuizList(courseId);
      isSubmit.current = true;
      const currentSlideIndex = deckRef.current.getState().indexh;
      if (currentSlideIndex == slider_HTMLData.length - 1) {
        handleExamRoute();
        setIsCourseQuizFetched(false);
      } else {
        setTimeout(() => {
          const data =
            quizDataRef.current &&
            quizDataRef.current?.find((f) => f?.module == quizNameRef?.current);
          if (data?.isCorrect_answer == 1) {
            handleNextClick(true);
            setIsListClick(true);
          } else {
            if (data?.question_attended < 2) {
              setOpenQuiz(!openQuiz);
            } else {
              message.error("Quiz max submitted for this course");
              handleNextClick(true);
              setIsListClick(true);
            }
          }
          isLoader.current = false;
          setIsCourseQuizFetched(false);
        }, 2000);
      }

      await handleProgressUpdate();
      const nextSlide = slider_HTMLData[currentSlideIndex + 1];
      if (nextSlide?.pageType === "module") {
        const slides = document.querySelectorAll(".reveal .slides > section");
        const nextSlideElement = slides[currentSlideIndex + 1];
        if (nextSlideElement) {
          nextSlideElement.classList.add("after-quiz");
        }
      }
    }
    return response;
  };

  const pushUniqueName = (arr, name) => {
    if (!arr.includes(name)) {
      arr.push(name);
    } else {
      console.log(`${name} is already in the list.`);
    }
    return arr;
  };

  useEffect(() => {
    try {
      if (!checkListData)
        postProgressList({
          courseId: courseId,
          progress: 0,
        });
      getProgress(courseId);
    } catch (error) {
      console.warn("Progress Error");
    }
  }, [checkListData, userId, courseId]);

  //for slider page
  const formatted_data = (checkedValueIndex) => {
    const commonPages = {};

    slider_HTMLData &&
      slider_HTMLData?.map((p, index) => {
        if (commonPages[p?.moduleName] === undefined) {
          pushUniqueName(visitedModules.current, p?.moduleName);

          commonPages[p?.moduleName] = [
            { ...p, checkedValue: index === 0 || checkedValueIndex },
          ];
        } else {
          commonPages[p?.moduleName]?.push({ ...p, checkedValue: false });
        }
      });

    //module count
    const checkLisLength = Object?.keys(commonPages)?.length;
    setModuleCount(checkLisLength);
    setFormatedSliderData(commonPages);
    setFormated(commonPages);
    moduleNameRef.current = commonPages && Object.keys(commonPages)[0];
    if (quizNameRef.current?.length === 0) {
      quizNameRef.current = commonPages && Object.keys(commonPages)[0];
    }
  };

  useEffect(() => {
    if (checkListData && Object.keys(JSON.parse(checkListData))?.length > 0) {
      const parsedData = JSON.parse(checkListData);
      const updatedData = { ...parsedData };

      // Compare with slider_HTMLData to find new pages or quizzes
      Object.keys(formatData)?.forEach((moduleName) => {
        const pages = formatData[moduleName];

        if (!updatedData[moduleName]) {
          updatedData[moduleName] = [];
        }

        pages.forEach((page, index) => {
          if (!updatedData[moduleName][index]) {
            updatedData[moduleName][index] = { ...page, checkedValue: false };
          }
        });
      });

      setFormatedSliderData(updatedData);

      // Find the last checked item for setting the audio
      let lastCheckedIndex = 0;
      let lastCheckedPage = null;

      Object.keys(parsedData).forEach((moduleName) => {
        parsedData[moduleName].forEach((page, index) => {
          if (page.checkedValue) {
            lastCheckedIndex = index;
            lastCheckedPage = page;
          }
        });
      });
      setAudioUrl(lastCheckedPage?.audioUrl);
    }
  }, [checkListData, formatData]);

  const handleNextClick = async () => {
    setIsCourseDetailsFetched(true);
    const currentSlideIndex = deckRef.current.getState().indexh;
    slideIndex.current = currentSlideIndex + 1;

    // if all coursed are completed then redirect to exam page
    if (currentSlideIndex >= slider_HTMLData.length - 1) {
      handleExamRoute();
    }

    const nextSlide = slider_HTMLData[currentSlideIndex + 1];
    const currentPageType = nextSlide?.pageType;
    if (currentPageType === "module") {
      deckRef.current.next();
      clearInterval(timerRef.current);
      setAudioUrl(nextSlide?.audioUrl);
    } else if (currentPageType === "quiz") {
      setIsListClick(false);
      setOpenQuiz(false);
      deckRef.current.next();
      setHtmlData(nextSlide?.html);
      setCssData(nextSlide?.css);
      setModuleName(nextSlide?.moduleName);
    }
    setIsPlaying(false);

    await handleProgressUpdate();
    let currentModule = [];
    let checkedCount = 0;
    if (moduleNameRef.current != nextSlide?.moduleName) {
      moduleNameRef.current = nextSlide?.moduleName;
      quizNameRef.current = nextSlide?.moduleName;
      pushUniqueName(visitedModules.current, nextSlide?.moduleName);
      currentModule = formatedSliderData[moduleNameRef.current];
      checkedCount = 0;
      slideIndex.current = 0;
    }

    const currentProgress = progressData?.data?.progress || 0;
    const progressPercentage = Math.round(deckRef.current.getProgress() * 100);
    const newProgress = Math.max(currentProgress, progressPercentage);

    currentModule = formatedSliderData[moduleNameRef.current];
    currentModule?.forEach((page) => {
      if (page.checkedValue && newProgress > currentProgress) {
        checkedCount++;
      }
    });

    // Check the next checkbox in the current module
    if (checkedCount < currentModule?.length && newProgress > currentProgress) {
      const updatedModule = formatedSliderData[moduleNameRef.current].map(
        (page, index) => {
          if (index === checkedCount) {
            return { ...page, checkedValue: true };
          }
          return page;
        }
      );
      setFormatedSliderData((prevState) => ({
        ...prevState,
        [moduleNameRef.current]: updatedModule,
      }));

      const handleChecklistUpdate = async () => {
        try {
          let data = {
            ...formatedSliderData,
            [moduleNameRef.current]: updatedModule,
          };
          await postCheckList({
            courseId: courseId,
            checklistData: JSON.stringify(data),
          });
          console.log("Checkbox state posted successfully");
        } catch (error) {
          console.error("Failed to post checkbox state:", error);
        }
        try {
          await getCheckList(courseId);
          console.log("Fetched checkbox state successfully");
          setIsCourseDetailsFetched(false);
        } catch (error) {
          console.error("Failed to fetch checkbox state:", error);
        }
      };

      handleChecklistUpdate();
    } else {
      setIsCourseDetailsFetched(false);
    }
  };

  const handlePrevClick = () => {
    const currentSlideIndex = deckRef.current.getState().indexh;
    slideIndex.current = currentSlideIndex;
    if (currentSlideIndex <= 0) {
      return;
    }
    const currentPageType = slider_HTMLData[currentSlideIndex - 1].pageType;

    if (currentPageType === "module") {
      deckRef.current.prev();
      clearInterval(timerRef.current);
      setAudioUrl(slider_HTMLData[currentSlideIndex - 1]?.audioUrl);
    } else if (currentPageType === "quiz") {
      setIsListClick(false);
      setHtmlData(slider_HTMLData[currentSlideIndex - 1]?.html);
      setCssData(slider_HTMLData[currentSlideIndex - 1]?.css);
      setModuleName(slider_HTMLData[currentSlideIndex - 1]?.moduleName);
      deckRef.current.prev();
    }
    setIsPlaying(false);
  };

  useEffect(() => {
    if (deckRef.current) return;

    const initializeDeck = async () => {
      deckRef.current = new Reveal(deckDivRef.current, {
        transition: "slide",
        controls: false,
        controlsTutorial: false,
        controlsLayout: "bottom-right",
        controlsBackArrows: "faded",
        showNotes: false,
        center: true,
        embedded: true,
        loop: false,
        pause: true,
        autoAnimateEasing: "ease",
        jumpToSlide: false,
        progress: false,
        autoSlide: false,
      });

      deckRef.current.initialize().then(() => {
        // good place for event handlers and plugin setups
      });
    };

    initializeDeck();

    return () => {
      try {
        if (deckRef.current) {
          deckRef.current.off("slidechanged", handleProgressUpdate);
          isLoader.current = false;
          deckRef.current.destroy();
          deckRef.current = null;
        }
        clearInterval(timerRef.current);
      } catch (e) {
        console.warn("Reveal.js destroy call failed.");
      }
    };
  }, [courseId, userId]);

  useEffect(() => {
    const recallSlide = async () => {
      if (deckRef.current) {
        if (!courseId) return;
        await getProgress(courseId);
        let progressPercentage = progressData?.data?.progress || 0;
        let initialSlideIndex = Math?.floor(
          (progressPercentage / 100) * slider_HTMLData?.length
        );

        if (
          progressPercentage === 100 &&
          slider_HTMLData &&
          slider_HTMLData[slider_HTMLData?.length - 1]?.pageType === "quiz"
        ) {
          initialSlideIndex = initialSlideIndex - 1;
        }

        setRevertedUserIndex(initialSlideIndex);

        if (slider_HTMLData[initialSlideIndex]?.pageType === "quiz") {
          setIsListClick(false);
          setOpenQuiz(false);
          setHtmlData(slider_HTMLData[initialSlideIndex]?.html);
          setCssData(slider_HTMLData[initialSlideIndex]?.css);
          setModuleName(slider_HTMLData[initialSlideIndex]?.moduleName);
          deckRef.current.slide(initialSlideIndex);
        } else {
          deckRef.current.slide(initialSlideIndex);
        }
      }
    };

    recallSlide();
  }, [deckRef.current]);

  // const [open, setOpen] = useState(false);
  // const ref = useRef();

  // const useOnClickOutside = (ref, handler) => {
  //   useEffect(() => {
  //     const listener = (event) => {
  //       if (!ref.current || ref.current.contains(event.target)) {
  //         return;
  //       }
  //       handler(event);
  //     };
  //     document.addEventListener("mousedown", listener);
  //     document.addEventListener("touchstart", listener);
  //     return () => {
  //       document.removeEventListener("mousedown", listener);
  //       document.removeEventListener("touchstart", listener);
  //     };
  //   }, [ref, handler]);
  // };
  // useOnClickOutside(
  //   ref,
  //   useCallback(() => setOpen(false), [])
  // );

  const parserOptions = {
    replace(domNode) {
      if (domNode.name === "body") {
        return <section>{domToReact(domNode.children, parserOptions)}</section>;
      }
      if (domNode.name === "img") {
        return (
          <div
            id={domNode.attribs.id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={domNode.attribs.src} alt="key" />
          </div>
        );
      }
      if (domNode.name === "input") {
        return React.createElement("input", {
          ...domNode.attribs,
          onChange: handleQuizChange,
        });
      }
    },
  };

  const handleRadioChange = (e, item) => {
    const pages = document.querySelectorAll(
      "input[type='checkbox'][name='page'], input[type='checkbox'][name='quiz']"
    );
    const index = Array.prototype.indexOf.call(pages, e.target);
    if (item?.pageType === "module") {
      setIsListClick(true);
      setHtmlData(item?.html);
      setCssData(item?.css);
      setModuleName(item?.moduleName);
      setAudioUrl(item?.audioUrl);
    } else {
      setOpenQuiz(false);
      setIsListClick(false);
      setHtmlData(item?.html);
      setCssData(item?.css);
      setModuleName(item?.moduleName);
      setAudioUrl(item?.audioUrl);
    }
    deckRef.current.slide(index);
  };

  useEffect(() => {
    getProgress(courseId);
  }, [courseId]);

  const handleProgressUpdate = async () => {
    const currentProgress = progressData?.data?.progress || 0;
    const progressPercentage = Math.round(deckRef.current.getProgress() * 100);

    const newProgress = Math.max(currentProgress, progressPercentage);

    if (newProgress > currentProgress) {
      await postProgressList({
        courseId: courseId,
        progress: newProgress,
      });
      await getProgress(courseId);
    }
  };

  const calculateModuleAudioDuration = async () => {
    const durations = {};

    for (const moduleName in formatedSliderData) {
      const pages = formatedSliderData[moduleName];
      let totalDuration = 0;

      for (const page of pages) {
        if (page?.audioUrl) {
          const audio = new Audio(page?.audioUrl);
          await new Promise((resolve) => {
            audio.addEventListener("loadedmetadata", () => {
              totalDuration += audio.duration;
              resolve();
            });
          });
        }
      }

      const durationInMinutes = Math.round(totalDuration / 60);
      durations[moduleName] = durationInMinutes;
    }

    setModuleDurations(durations);
  };
  useEffect(() => {
    if (formatedSliderData) {
      calculateModuleAudioDuration();
    }
  }, [formatedSliderData, courseId]);

  return (
    <div id="main div">
      <HeaderTraining />
      <Styled.Module>
        <Styled.Training>
          <div className="secure">
            <div className="cor">
              {course.cor}
              <RxSlash className="slash" />
              {coursesDetails?.course?.title}
            </div>
            <div className="progressBar">
              <Button
                title={course.rev}
                className="rev"
                onClick={() => setIsModalOpen(!isModalOpen)}
              />
              <div>
                <div className="complete">
                  <div> {progressData?.data?.progress || 0}% Complete</div>
                  {/* for future use */}
                  {/* <Styled.DownIcon ref={ref}>
                    <FaAngleDown
                      className="faangle"
                      onClick={() => setOpen(!open)}
                    />
                    {open && (
                      <Styled.DropMenu>
                        <div className="stepsof">{course.stepsof}</div>
                        <Styled.Lined />
                        <div className="certify">{course.coursecertified}</div>
                      </Styled.DropMenu>
                    )}
                  </Styled.DownIcon> */}
                </div>
                <Styled.ProgressMain
                  percent={progressData?.data?.progress || 0}
                  showInfo={false}
                  size="1051"
                  strokeColor="rgba(4, 136, 182, 1)"
                  trailColor="white"
                />
              </div>
            </div>
          </div>
          <div
            className={
              isListClick ? `show reveal-container` : `hide reveal-container`
            }
            style={{ position: "relative" }}
          >
            {isLoader.current && (
              <div className="deck-loader">
                <Spin
                  size="large"
                  style={{ position: "absolute", left: "50%", top: "50%" }}
                />
              </div>
            )}
            <div
              id="deck"
              className="reveal"
              ref={deckDivRef}
              style={{
                width: "100%",
                height: "487px",
              }}
            >
              <div className="slides">
                {slider_HTMLData?.map((i) => {
                  return (
                    <>
                      <style>{i?.css}</style>
                      {parse(i?.html, parserOptions)}
                    </>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 20px",
                background: "#000",
                color: "#fff",
                alignItems: "center",
                position: "relative",
              }}
            >
              <CustomAudioPlayer
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                audioUrl={audioUrl}
              />

              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      gap: "5px",
                      borderLeft: "1px solid #6D7D8B",
                      borderRight: "1px solid #6D7D8B",
                      padding: "15px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handlePrevClick}
                  >
                    <FaChevronLeft />
                    PREVIOUS
                  </span>
                  <span
                    style={{
                      display: "flex",
                      gap: "5px",
                      padding: "15px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={
                      isCourseDetailsFetched ? () => {} : handleNextClick
                    }
                  >
                    NEXT
                    <FaChevronRight />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              isListClick ? `hide quiz-container` : `show quiz-container`
            }
          >
            {openQuiz ? (
              <div
                style={{
                  width: "100%",
                  background: "#ffffff",
                  padding: "50px",
                  height: "487px",
                  overflowY: "scroll",
                  position: "relative",
                }}
              >
                {isLoader.current && (
                  <div className="deck-loader">
                    <Spin
                      size="large"
                      style={{ position: "absolute", left: "50%", top: "50%" }}
                    />
                  </div>
                )}
                <div>
                  <style>{cssData}</style>
                  {parse(htmlData, parserOptions)}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "35px",
                  }}
                >
                  <button
                    style={{
                      border: "none",
                      color: "white",
                      background: isCourseQuizFetched ? "#d0d0d0" : "#15374d",
                      borderRadius: "2px",
                      padding: "6px 10px",
                    }}
                    onClick={
                      isCourseQuizFetched ? () => {} : () => handleSubmitQuiz()
                    }
                  >
                    {"Submit Quiz"}
                  </button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "487px ",
                  background: "#ffffff",
                  padding: "50px",
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    marginBottom: "10px",
                  }}
                >
                  {"Quiz"}
                </div>
                <div>{"Time to check your knowledge -"}</div>
                <Styled.QuizButton onClick={() => setOpenQuiz(!openQuiz)}>
                  Start Quiz
                </Styled.QuizButton>
              </div>
            )}
            <hr />
          </div>

          <div className="firstScreen">
            <TrainingAwareness
              coursesDetails={coursesDetails}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              getReviewList={getReviewList}
              tablePagination={tablePagination}
            />
          </div>
        </Styled.Training>
        <Styled.TickData>
          <div>
            <div>
              <div className="maincourse">
                <div className="mod">
                  {course.mod}
                  <br />
                  <span className="mainModule">{` ${moduleCount} ${course.moduleno}`}</span>
                </div>
              </div>
            </div>
            <Styled.Aims>
              <SideBarPage
                slider_HTMLData={slider_HTMLData}
                handleRadioChange={handleRadioChange}
                isExam={true}
                nextHandleExamRoute={handleExamRoute}
                slideIndex={slideIndex}
                getCheckList={getCheckList}
                postCheckList={postCheckList}
                isListClick={isListClick}
                formatedSliderData={formatedSliderData}
                setFormatedSliderData={setFormatedSliderData}
                formatted_data={formatted_data}
                courseId={courseId}
                moduleNameRef={moduleNameRef}
                moduleIndexRef={moduleIndexRef}
                visitedModules={visitedModules}
                checkListData={checkListData}
                moduleDurations={moduleDurations}
              />
            </Styled.Aims>
          </div>
        </Styled.TickData>
      </Styled.Module>
      <Styled.Page>
        <Styled.Pagin
          total={review_count}
          itemRender={itemRender}
          showQuickJumper={false}
          showSizeChanger={false}
          colorTextPlaceholder="red"
          current={tablePagination}
          pageSize={5}
          onChange={handlePageChange}
        />
      </Styled.Page>
    </div>
  );
};

export default CoursesModule;
