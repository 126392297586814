import { useEffect, useState } from "react";
import {
  RenderDate,
  UserActions,
} from "../containers/Corporate/BusinessUserLicense/CustomRender";
import HttpApis from "../services/HttpApis";
import { useDispatch, useSelector } from "react-redux";
import { usersList } from "../redux/slices/corporateUserSlice";
import useCommonHook from "./CommonHook";
import { message } from "antd";

const usePermissions = () => {
  const dispatch = useDispatch();
  const corporateUsers = useSelector(
    (state) => state?.corporateUserReducer?.corporateUsers?.users
  );
  const count = useSelector(
    (state) => state?.corporateUserReducer?.corporateUsers?.count
  );
  const { useDebounce } = useCommonHook();
  const [openModal, setOpenModal] = useState(false);

  const [search, setSearch] = useState({
    page: 1,
    limit: 5,
    search: "",
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      total: count,
      current: 1,
      pageSize: 5,
    },
  });
  useEffect(() => {
    setTableParams({
      pagination: {
        total: count,
        current: 1,
        pageSize: 5,
      },
    });
  }, [count]);
  const debouncedSearch = useDebounce(search, 500);
  const fetch_user = async () => {
    try {
      const response = await HttpApis.getCorporateUser({
        ...debouncedSearch,
        page: tableParams.pagination.current,
      });
      dispatch(usersList(response.data.data));
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    fetch_user();
  }, [debouncedSearch, tableParams]);
  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };
  const handleSearch = (e) => {
    setSearch({ ...search, search: e.target.value });
  };

  const handleManage = (row) => {
    setOpenModal(row);
  };

  const handleDelete = async (row) => {
    const response = await HttpApis.deleteUser(row?.id);
    if (response.status === 200) {
      message.success(response.data.message);
      fetch_user();
    }
    return response;
  };
  const userColumn = [
    {
      label: "Date Added",
      mappedkey: "createdAt",
      defaultSortOrder: "descend",
      sorter: true,
      customRender: RenderDate,
    },
    {
      label: "Name of holder",
      mappedkey: "firstName",
    },
    {
      label: "User Role",
      mappedkey: "type",
    },
    {
      label: "Actions",
      mappedkey: "action",
      customRender: UserActions,
      action: { handleDelete, handleManage },
    },
  ];
  return {
    userColumn,
    corporateUsers,
    count,
    handleSearch,
    handleTableChange,
    search,
    tableParams,
    openModal,
    setOpenModal,
  };
};

export default usePermissions;
