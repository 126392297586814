import styled from "styled-components";
import { color } from "../../theme/Color";

export const MainContainer = styled.div`
  display: flex;
  justify-content: end;
`;
export const Data = styled.div`
  background: ${color._16384e};
  color: ${color._ffffff};
  z-index: 9999;
  position: absolute;
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const IconData = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  @media only screen and (max-width: 800px) {
    .iconss {
      font-size: 30px;
    }
  }
`;
export const Heading = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 32px;
  }
`;
export const Mail = styled.div`
  margin-top: 5px;
  color: ${color._6091a8};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 28px;
  }
`;
export const MailData = styled.div`
  padding: 15px 0px 10px 22px;
`;
export const UserType = styled.div`
  color: #fff;
  height: 22px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px 26px;
  background: #6091a8;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  @media only screen and (max-width: 800px) {
    font-size: 34px;

    height: ${(props) => (props?.active ? "10px" : "60px")};
  }
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(96, 145, 168, 0.14);
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${(props) => (!props?.active ? color._16384e : color._FFFFFF75)};

  align-items: center;
  .allBtn {
    background: transparent;
    border: none;
    width: 231px;
    color: ${color._ffffff};
    height: 31px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 12px;
    @media only screen and (max-width: 800px) {
      font-size: 34px;
      height: 100px;
      width: 323px;
    }
  }
  &.btnStyle {
    padding-left: 22px;
  }
`;
export const ImgCr = styled.div``;
export const CourserContainer = styled.div`
  background: ${color._16384e};
  border: none;
  color: ${color._ffffff};
  .count {
    background: ${color._ffffff};
    color: ${color._16384E};
    border-radius: 50%;
    margin-top: 2px;
    margin-right: 2px;
    height: 24px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  @media only screen and (max-width: 800px) {
    .count {
      height: 67px;
      width: 71px;
      font-size: 47px;
    }
  }
  padding: 8px 26px 5px 22px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    font-size: 34px;
  }
`;
export const Business = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 3%;
  justify-content: center;
  .avtav {
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .business {
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
