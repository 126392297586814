import styled from "styled-components";
import { color } from "../../../theme/Color";
import { Modal } from "antd";

export const CompletedCourses = styled.div`
  display: flex;
  gap: 7px;
  padding-bottom: 18px;
  .courses {
    color: ${color._0f2a3c};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .number {
    color: ${color._6091a8};
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const BelowText = styled.div`
  padding-bottom: 20px;
  color: ${color._68828e};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Pipe = styled.div`
  display: flex;
  background-color: ${color._6091a8};
  color: ${color._6091a8};
  width: 2px;
  height: 20px;
  margin-top: 4px;
`;

export const SearchContainer = styled.div`
  gap: 5px;
  .searchIcon {
    color: ${color._16384E};
    margin-top: 3px;
    margin-left: 6px;
  }
  display: flex;
  align-items: center;
  width: 210px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
`;

export const Input = styled.input`
  outline: none;
  border: none;
  flex-shrink: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${color._000000};
  text-transform: capitalize;
  &::placeholder {
    color: ${color._000000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const TableHeader = styled.div`
  padding: 13px 20px 13px 17px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  padding-bottom: 14px;
`;

export const MainContainer = styled.div`
  margin-top: 26px;
  border-radius: 5px;
  border: 0.7px solid ${color._ededde};
  background: ${color._ffffff};
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
`;

export const Name = styled.div`
  font-family: Raleway;
  font-size: 25px;
  font-weight: 800;
  color: #15374d;
`;

export const Permissions = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  color: #5193aa;
`;

export const Divider = styled.div`
  border: 1px solid #d9d9d9;
  margin: 10px 0px 25px 0px;
`;

export const FormContainer = styled.div`
  display: flex;
  gap: 45px;
`;

export const ModalInput = styled.div`
  width: 251px;
  height: 41px;
  background: #e9f0f38a;
  border-radius: 2px;
  border: none;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 13px;
  font-weight: 600;
  color: #565656;
`;

export const Select = styled.select`
  width: 251px;
  height: 41px;
  background: #e9f0f38a;
  border-radius: 2px;
  border: none;
  padding: 5px;
`;

export const FooterButton = styled.div`
  margin: 30px 0px 10px 0px;
  display: flex;
  justify-content: end;
  .btn {
    border: none;
    background: #0073de;
    border-radius: 2px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
  }
`;

export const ModalContainer = styled(Modal)`
  &.ant-modal {
    max-width: calc(100% - 32px);
  }
`;
