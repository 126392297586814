import React from 'react'
import MainLayout from '../../layout/MainLayout'
import Recruitment from '../../containers/Recruitment/Recruitment'

export const RecruitmentPage = () => {
  return (
    <MainLayout>
  <Recruitment/>
    </MainLayout>
  )
}
