import React from "react";
import AVSECCoursesCard from "../../../components/Atoms/CoursesCard/AVSECCoursesCard";
import * as Styled from "./style";

export const AddCourses = () => {
  return (
    <Styled.Maincontainer>
      <AVSECCoursesCard />
    </Styled.Maincontainer>
  );
};
