import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Signature = styled.div`
  width: 620px;
  .canvas {
    border: 1px solid #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    background: none;
    border: none;
    outline: none;
    box-sizing: none;
    text-align: justify;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: ${color._5193aa};
    color: ${color._ffffff};
  }
  .butn {
    display: flex;
    gap: 10px;
    justify-content: center;
    padding-bottom: 10px;
    margin-top: 20px;
  }
`;
export const ChildText = styled.div`
  margin-top: 5px;
  text-align: center;
`;
