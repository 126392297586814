import React, { useRef, useState, useEffect } from "react";
import { FaPause, FaPlay, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import "./style.css";
const CustomAudioPlayer = ({ audioUrl, isPlaying, setIsPlaying }) => {
  const audioRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);

  useEffect(() => {
    const audio = audioRef.current;
    const updateCurrentTime = () => setCurrentTime(audio.currentTime);
    const updateDuration = () => setDuration(audio.duration);
    const handleAudioEnd = () => setIsPlaying(false);

    audio.addEventListener("timeupdate", updateCurrentTime);
    audio.addEventListener("loadedmetadata", updateDuration);
    audio.addEventListener("ended", handleAudioEnd);

    return () => {
      audio.removeEventListener("timeupdate", updateCurrentTime);
      audio.removeEventListener("loadedmetadata", updateDuration);
      audio.removeEventListener("ended", handleAudioEnd);
    };
  }, [setIsPlaying]);

  const handlePlayClick = () => {
    const audio = audioRef.current;
    audio.play();
    setIsPlaying(true);
  };
  const handlePauseClick = () => {
    const audio = audioRef.current;
    audio.pause();
    setIsPlaying(false);
  };

  const handleMuteClick = () => {
    const audio = audioRef.current;
    audio.muted = false;
    setIsMuted(false);
  };

  const handleUnmuteClick = () => {
    const audio = audioRef.current;
    audio.muted = true;
    setIsMuted(true);
  };

  const handleVolumeChange = (e) => {
    const audio = audioRef.current;
    const newVolume = parseFloat(e.target.value);
    audio.volume = newVolume;
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
    updateVolumeBackground(newVolume * 100);
  };

  const updateVolumeBackground = (value) => {
    const volumeElement = document.querySelector(".volume");
    volumeElement.style.background = `linear-gradient(to right, #177a9c ${value}%, #fff ${value}%)`;
  };

  const handleSeek = (e) => {
    const audio = audioRef.current;
    audio.currentTime = (e.target.value / 100) * duration;
    setCurrentTime(audio.currentTime);
  };

  const updateSeekBackground = (value) => {
    const seekElement = document.querySelector(".seek");
    seekElement.style.background = `linear-gradient(to right, #177a9c ${value}%, #fff ${value}%)`;
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  useEffect(() => {
    const seekElement = document.querySelector(".seek");
    if (seekElement) {
      updateSeekBackground((currentTime / duration) * 100);
    }
    const volumeElement = document.querySelector(".volume");
    if (volumeElement) {
      updateVolumeBackground(volume * 100);
    }
  }, [currentTime, duration, volume]);

  return (
    <div className="audio-player">
      <input
        className="seek"
        type="range"
        value={(currentTime / duration) * 100 || 0}
        onChange={handleSeek}
      />
      <audio ref={audioRef} src={audioUrl} />
      <div className="audio-control">
        <div>
          {isPlaying ? (
            <FaPause
              onClick={handlePauseClick}
              style={{ cursor: "pointer", marginRight: "8px" }}
            />
          ) : (
            <FaPlay
              onClick={handlePlayClick}
              style={{ cursor: "pointer", marginRight: "8px" }}
            />
          )}
          <span>{formatTime(currentTime)}</span> /{" "}
          <span>{formatTime(duration)}</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {isMuted || volume === 0 ? (
            <FaVolumeMute
              onClick={handleMuteClick}
              style={{ cursor: "pointer", marginRight: "8px" }}
            />
          ) : (
            <FaVolumeUp
              onClick={handleUnmuteClick}
              style={{ cursor: "pointer", marginRight: "8px" }}
            />
          )}
          <input
            className="volume"
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={volume}
            onChange={handleVolumeChange}
          />
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default CustomAudioPlayer;
