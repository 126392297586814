import * as Styled from "./style.js";
import CheckoutPageLogo from "../../../assets/Svg/CheckoutPageLogo.svg";
import React from "react";
import navbg from "../../../assets/image/navbg.jpg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { constant } from "../../../constants/Constants";
import { Routes } from "../../../routes/routes.js";
import { Button } from "../../Atoms/Button/button";

export const CheckoutHeader = ({ isCheck }) => {
  const navigate = useNavigate();
  const userType = useSelector((state) => state?.authReducer?.user?.type);

  const handleProfile = () => {
    userType === "Single User"
      ? navigate(Routes?.singleUserProfile)
      : navigate(Routes?.userProfile);
  };

  return (
    <>
      <Styled.Container src={navbg}>
        <Styled.Boxcontainer className="container">
          <Styled.Logo>
            <img src={CheckoutPageLogo} alt="logoimage" className="imgLogo" />
          </Styled.Logo>
          {isCheck ? (
            <Styled.Cancel>
              <Button
                className="cancel"
                title={constant.Cancel}
                onClick={() => navigate(Routes?.multiCart)}
              />
            </Styled.Cancel>
          ) : (
            <Styled.Cancel>
              <Button
                className="cancel"
                title="Go to Profile"
                onClick={() => handleProfile()}
              />
            </Styled.Cancel>
          )}
        </Styled.Boxcontainer>
      </Styled.Container>
      {isCheck && (
        <Styled.TitleLine>
          <Styled.CheckOut>{constant.checkout}</Styled.CheckOut>
          <Styled.Border />
        </Styled.TitleLine>
      )}
    </>
  );
};
