import React from 'react'
import MainLayout from '../../layout/MainLayout'
import Resources from '../../containers/Resources/Resources'

export const ResourcesPage = () => {
  return (
    <MainLayout>
   
   <Resources/>
    </MainLayout>
  )
}
