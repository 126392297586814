import { useDispatch } from "react-redux";
import {
  checklistPostFail,
  checklistPostSuccess,
  checklistSuccess,
} from "../redux/slices/inductionSlice";
import HttpApis from "../services/HttpApis";

const useInductionHook = () => {
  const dispatch = useDispatch();
  const getChecklistData = async () => {
    try {
      const response = await HttpApis?.getInductionChecklist();
      dispatch(checklistSuccess(response?.data?.data));
    } catch (error) {
      console.error("error");
    }
  };

  const postChecklistData = (param) => async (dispatch) => {
    try {
      const res = await HttpApis.postInductionChecklist(param);
      dispatch(checklistPostSuccess(res));
      return res;
    } catch (error) {
      dispatch(checklistPostFail(error?.message));
      return null;
    }
  };
  const handlePostChecklist = async (param) => {
    const res = await dispatch(postChecklistData(param));
    return res;
  };
  return {
    getChecklistData,
    handlePostChecklist,
  };
};

export default useInductionHook;
