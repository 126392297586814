import * as Styled from "./style";
import React from "react";
import { BiErrorCircle } from "react-icons/bi";
import { useSelector } from "react-redux";
import { AccountDashboard } from "../../components/Molecules/AccountDashboardPage/AccountDashboard";
import { browseData } from "./BrowseData";

const FolderDeclaration = ({
  isDeclaration = false,
  declareData,
  setDeclareData,
  errors,
  setSign,
  sign,
}) => {
  const cartList = useSelector((state) => state?.cartReducer?.cart?.data);
  return (
    <>
      <Styled.NextLine />
      <Styled.InputCheck>
        <Styled.FirstContent>
          <div className="details">{browseData.detail}</div>
          {cartList?.map((item) => {
            return (
              <Styled.CourseName>
                <div className="name">{browseData.course}</div>
                <div className="gsat">{item?.Course?.title} </div>
                <Styled.Price>
                  <div>
                    <div className="qty1">{browseData.q}</div>
                    <div className="x1">{item.qty}</div>
                  </div>
                  <div>
                    <div className="qty2">{browseData.price}</div>
                    <div className="price">£{item?.Course?.price}</div>
                  </div>
                </Styled.Price>
              </Styled.CourseName>
            );
          })}
          <Styled.Lined />
          <Styled.Note>
            <div>
              <BiErrorCircle className="circle" />
            </div>
            <div className="above">
              Please note that the above is an Aviation Security Course and it
              is mandatory that you fill in and sign the declaration form before
              <br />
              you can purchase this course.
            </div>
          </Styled.Note>
        </Styled.FirstContent>
        {isDeclaration ? (
          <Styled.Solo>
            <div className="backCheck">
              {browseData.back}
              <span className="single">{browseData.corporate}</span>
            </div>

            <Styled.Requirement>
              <AccountDashboard
                CorporateUser={true}
                declareData={declareData}
                setDeclareData={setDeclareData}
                errors={errors}
                setSign={setSign}
                isInvitedDeclaration={false}
                isStaffDetails={true}
                isLicense={false}
              />
            </Styled.Requirement>
          </Styled.Solo>
        ) : (
          <Styled.Solo>
            <div className="backCheck">
              {browseData.back}
              <span className="single">{browseData.sole}</span>
            </div>

            <Styled.Requirement>
              <AccountDashboard
                isInput={true}
                declareData={declareData}
                setDeclareData={setDeclareData}
                errors={errors}
                setSign={setSign}
                isInvitedDeclaration={false}
                isStaffDetails={true}
                isLicense={false}
              />
            </Styled.Requirement>
          </Styled.Solo>
        )}
      </Styled.InputCheck>

      <Styled.NextLine />
    </>
  );
};

export default FolderDeclaration;
