import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { UpdatedCourseDetails } from "../redux/slices/coursesSlice";
import HttpApis from "../services/HttpApis";

export const useCourseDetails = () => {
  const navigate = useNavigate();
  const avoid = useRef(false);
  const { slug, id } = useParams();
  const dispatch = useDispatch();
  const coursesDetails = useSelector(
    (state) => state?.coursesReducer?.courseDetails?.data
  );

  const handleClick = (slug) => {
    navigate(`/training-course/${slug}`);
  };

  useEffect(() => {
    const _id = slug || id;
    if (_id) {
      const fetchCourses = async () => {
        try {
          const response = await HttpApis.courseDetails(`api/courses/${_id}`);
          dispatch(UpdatedCourseDetails(response.data));
        } catch (error) {
          console.error(error);
        }
      };
      if (!avoid.current) {
        fetchCourses();
      }
    }
    return () => (avoid.current = true);
  }, [slug, id, dispatch]);

  return { coursesDetails, handleClick };
};
