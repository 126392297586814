import { FaLocationArrow, FaMailBulk, FaPhoneAlt } from "react-icons/fa";
import { IoNewspaperOutline } from "react-icons/io5";
import { TbStairsUp,TbUserQuestion } from "react-icons/tb";

export const contactPageData = [
  {
    icon: <FaPhoneAlt className="phoneIcon" />,
    label: "TRAINING",
    value: "0203 984 4195",
  },
  {
    icon: <FaPhoneAlt className="phoneIcon" />,
    label: "VETTING",
    value: "0203 984 4196",
  },
  {
    icon: <FaPhoneAlt className="phoneIcon" />,
    label: "RECRUITMENT HEATHROW",
    value: "0203 984 4197",
  },
  {
    icon: <FaPhoneAlt className="phoneIcon" />,
    label: "RECRUITMENT GATWICK",
    value: "0203 984 4198",
  },
  {
    icon: <FaPhoneAlt className="phoneIcon" />,
    label: "RECRUITMENT MANCHESTER",
    value: "0161 241 2979",
  },
  {
    icon: <FaPhoneAlt className="phoneIcon" />,
    label: "PAYROLL",
    value: "0203 479 2079",
  },
];

export const addressData = [
  {
    icon: <FaMailBulk className="emailIcon" />,
    value: "Email: info@avtav.co.uk",
  },
  {
    icon: <FaLocationArrow className="emailIcon" />,
    value: "Suite A, Ground Floor Sipson Rd, West Drayton UB7 0JE",
  },
];


export const TileData=[
    {
        icon:<TbStairsUp className="tileIcon" />,
        label:"Want to join us?"
    },
    {
        icon:<IoNewspaperOutline  className="tileIcon" />,
        label:"Read our latest news"
    },
    {
        icon:<TbUserQuestion className="tileIcon" />,
        label:"Have questions?"
    },
];