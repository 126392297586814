import styled from "styled-components";
import woodenHeader from "../../assets/image/woodenHeader.jpg"

export const ResourcesContainer =styled.div`
background-image: url(${woodenHeader});
display: flex;
justify-content: center;
.heading{
    text-align: center;
    color: #FFFFFF;
    font-size: 50px;
    font-weight: 800;
    text-transform: uppercase;
    margin: 69px 0px;
}
`
export const ButtonContainer=styled.div`
    display: flex;
    justify-content: space-around;
    margin: 70px 0px;

.buttonBox{
    background-color: #5bc0de;
    display: inline-block;
    line-height: 1;
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    border: none;
}
.leftContainer{
    flex: 0.5;
    justify-content: center;
    display: flex;
    margin-left: 47px;
}
.rightContainer{
    flex: 0.5;
    display: flex;
    justify-content: center;
    margin-right: 42px;
}
`