import HttpApis from "../services/HttpApis";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../src/routes/routes";

import {
  addtoCartFailure,
  addtoCartStart,
  addtoCartSuccess,
  qtyUpdateAction,
  qtyUpdateFailure,
} from "../redux/slices/cartSlice";
import { message } from "antd";

const useCartHook = () => {
  const userId = useSelector((state) => state?.authReducer?.user?.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cartAction = (cartData) => async (dispatch) => {
    try {
      dispatch(addtoCartStart());
      const response = await HttpApis.addToCart(cartData);
      return response?.data;
    } catch (error) {
      dispatch(addtoCartFailure(error.message));
      return null;
    }
  };
  //getCartList
  const fetchCart = async (sessionId) => {
    try {
      const headers = {
        sessionId: sessionId,
      };
      const response = await HttpApis.getCartList(userId, headers);
      dispatch(addtoCartSuccess(response?.data));
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const deleteCart = async (courseId) => {
    try {
      const response = await HttpApis.deleteCart(courseId);
      return response;
    } catch (error) {
      console.error(error, "error");
    }
  };
  //update Qty qtyUpdate
  const updateQty = async (cart, qty, cardItems) => {
    try {
      if (cardItems.length) {
        dispatch(qtyUpdateAction({ data: cardItems }));
      }
      const response = await HttpApis.qtyUpdate(cart.id, qty);

      return response?.data;
    } catch (error) {
      dispatch(qtyUpdateFailure(error.message));
      return null;
    }
  };

  const handleCart = async (cartData) => {
    const cart = await dispatch(cartAction(cartData));
    message.success(cart?.message);
    navigate(Routes?.multiCart);
    return cart;
  };
  return { cartAction, handleCart, fetchCart, deleteCart, updateQty };
};
export default useCartHook;
