import React from "react";
import SocilaMedia from "../../components/Atoms/SocilaMedia/SocilaMedia";
import * as Styled from "./style";
import Button from "../../components/Atoms/Button";
import { FaExchangeAlt } from "react-icons/fa";
import { FaRegComments } from "react-icons/fa6";
import { FaStackExchange } from "react-icons/fa";
import AboutBanner from "../../components/Atoms/AboutBanner/AboutBanner";
import Slider from "./Slider";

const Home = () => {
  return (
    <div>
      <Slider />
      <Styled.Banner>
        <div className="bannerContainer">
          <Styled.Vetting>
            AVSEC Training Vetting & Recruitment Specialist
          </Styled.Vetting>
          <p className="avtavText">
            AVTAV are a specialist provider of AVSEC Training, Vetting and
            Recruitment services across the UK specialising in the aviation and
            food production supply chains. Our services can be provided
            individually or as part of a bespoke, comprehensive full-service
            package
          </p>
        </div>
      </Styled.Banner>
      <AboutBanner />
      <Styled.CardContainer className="container">
        <div class="flip-box">
          <div class="flip-box-inner">
            <div class="flip-box-front">
              <FaExchangeAlt className="icon" />
              <div className="heading">Training</div>
              <p className="paragraph">
                A wealth of training expertise and experience.
              </p>
            </div>
            <div class="flip-box-back">
              <div className="heading">
                Aviation Training <br />
                Packages
              </div>
              <p className="paragraph">
                CAA REGISTERED AVIATION SECURITY TRAINING PROVIDER #182
              </p>
              <div className="buttonBox">
                <Button title={"Show more"} className={"cardButton"} />
              </div>
            </div>
          </div>
        </div>
        <div class="flip-box">
          <div class="flip-box-inner">
            <div class="flip-box-front">
              <FaStackExchange className="icon" />
              <div className="heading">Vetting</div>
              <p className="paragraph">
                20 years of experience in the compliant supply of staff in the
                aviation sector.
              </p>
            </div>
            <div class="flip-box-back">
              <div className="heading">Vetting</div>
              <p className="paragraph">
                We ensure selected candidates are processed through our advanced
                vetting and screening software
              </p>
              <div className="buttonBox">
                <Button title={"Show more"} className={"cardButton"} />
              </div>
            </div>
          </div>
        </div>
        <div class="flip-box">
          <div class="flip-box-inner">
            <div class="flip-box-front">
              <FaRegComments FaExchangeAlt className="icon" />
              <div className="heading">Recruitment</div>
              <p className="paragraph">
                Temporary and permanent staffing solutions across the aviation
                and supply chain sector.
              </p>
            </div>
            <div class="flip-box-back">
              <div className="heading">Recruitment</div>
              <p className="paragraph">
                We ensure selected candidates are processed through our advanced
                vetting and screening software
              </p>
              <div className="buttonBox">
                <Button title={"Show more"} className={"cardButton"} />
              </div>
            </div>
          </div>
        </div>
      </Styled.CardContainer>
      <SocilaMedia />
    </div>
  );
};
export default Home;
