import { createSlice } from "@reduxjs/toolkit";

const accountDetailSlice = createSlice({
  name: "accountDetail",
  initialState: {
    accountData: {},
    loading: false,
    error: null,
  },
  reducers: {
    accountDetailStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    accountDetailSuccess: (state, action) => {
      state.loading = false;
      state.accountData = action.payload;
    },
    accountDetailFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  accountDetailStart,
  accountDetailSuccess,
  accountDetailFailure,
} = accountDetailSlice.actions;
export default accountDetailSlice.reducer;
