
export const cartLabels = {
  courseName: "Course name",
  coursePriceLabel: "Price (per license)",
  licenses: "Licenses",
};

export const shoppingConstant = {
  cart: "Shopping Cart",
  cartPara: "You can increase the number of licenses from here.",
  cartAdded: "Cart (2 courses added)",
  onlineCourse: "Online course",
  save: "Save for later",
  Calculations: "Calculations",
  Total: "Total",
};
