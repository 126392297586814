import { styled } from "styled-components";

export const Header = styled.div`
  display: flex;
  background: ${(props) => (props.headerBackground ? "#5193AA" : "#15374D")};
  width: 100%;
  justify-content: center;
  padding: 30px 0px 17px 0px;
  gap: 13px;
`;

export const CompanyName = styled.h4`
  font-weight: 800;
  color: white;
  font-size: 20px;
`;

export const Tab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
