import React from "react";
import PreCourseInvited from "./PreCourseInvited";
import HeaderPre from "./HeaderPre";
import { course } from "./data";
import * as Styled from "./style";
import { useLocation } from "react-router-dom";

const InvitedUserPreCourse = () => {
  const location = useLocation();
  return (
    <div>
      <HeaderPre />
      <Styled.MainComponent className="container">
        <Styled.FirstRow>
          <div>
            <div className="company">
              {course?.inv}
              <span className="compName">
                {location?.state?.companyName
                  ? location?.state?.companyName
                  : ""}
              </span>
            </div>
            <div className="below">
              {course?.part}
              <span className="avtav">{course?.avtav}</span>
              {course?.takes}
            </div>
          </div>
        </Styled.FirstRow>
        <PreCourseInvited />
      </Styled.MainComponent>
    </div>
  );
};

export default InvitedUserPreCourse;
