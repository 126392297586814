import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useEffect, useState } from "react";
import useBillingHook from "./../../../hooks/BillingHook";
import useCommonHook from "./../../../hooks/CommonHook";
import { FaPen } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { useSelector } from "react-redux";
import { data } from "./data";
import { message } from "antd";

export const Billing = () => {
  const { getBillingData, handleEditAddress } = useBillingHook();
  const [addressList, setAddressList] = useState([]);
  const { fetchState, fetchCities } = useCommonHook();
  const [stateList, setStateList] = useState();
  const [cities, setCities] = useState();
  const [errors, setErrors] = useState({});
  const [updateData, setUpdateData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    id: "",
    phone: "",
    state: "",
    address: "",
    country: "",
    city: "",
    zipCode: "",
  });
  const billingAddress = useSelector(
    (state) => state?.billingReducer?.billingList?.data
  );
  const countryList = useSelector((state) => state?.commonReducer?.country);
  useEffect(() => {
    setAddressList(billingAddress);
  }, [billingAddress]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => {
      let newErrors = { ...prevErrors };
      if (!value.trim()) {
        newErrors[name] = "Field cannot be empty";
      } else if (name === "phone" && !/^\d{10,15}$/.test(value)) {
        newErrors[name] = "Please enter valid mobile number";
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
    if (e?.target?.id === "country") {
      const countryId = e.target.value;
      if (countryId) {
        const stateResponse = await fetchState(countryId);
        setStateList(stateResponse);
        const selectedCountry = countryList.find((c) => c.id == countryId);
        setUpdateData({
          ...updateData,
          [name]: value,
          Country: selectedCountry,
        });
      }
    } else if (e?.target?.id === "state") {
      const stateId = e.target.value;
      if (stateId) {
        const citiesRespose = await fetchCities(stateId);
        setCities(citiesRespose);
        const selectedState = stateList.find((c) => c.id == stateId);
        setUpdateData({ ...updateData, [name]: value, State: selectedState });
      }
    } else if (e?.target?.id === "city") {
      const selectedCity = cities.find((c) => c.id == e.target.value);
      setUpdateData({ ...updateData, [name]: value, City: selectedCity });
    } else {
      setUpdateData(() => ({
        ...updateData,
        [name]: value,
      }));
    }
  };
  const handleEdit = (item) => {
    const _addressList = [...addressList];
    let _item = { ...item };
    _item.editable = true;
    const addressIndex = _addressList.findIndex((e) => e.id === _item.id);
    _addressList.splice(addressIndex, 1, _item);
    setAddressList(_addressList);
    setUpdateData(_item);
  };
  useEffect(() => {
    getBillingData();
  }, []);

  const handleCancel = (item) => {
    const _addressList = [...addressList];
    let _item = { ...item };
    _item.editable = false;
    const addressIndex = _addressList.findIndex((e) => e.id === _item.id);
    _addressList.splice(addressIndex, 1, _item);
    setAddressList(_addressList);
  };

  const handleSave = async (data, item, e) => {
    const newErrors = {};
    const requiredFields = [
      "address",
      "country",
      "state",
      "city",
      "phone",
      "zipCode",
    ];
    const emptyFields = requiredFields.filter((field) => !data[field]);
    if (emptyFields.length > 0 || Object.keys(errors).length > 0) {
      message.error("Please fill out the empty details");
      return;
    } else {
      const response = await handleEditAddress({
        ...data,
        id: item?.id,
        firstName: item?.firstName,
        lastName: item?.lastName,
        email: item?.email,
        companyName: item?.companyName,
      });

      const _addressList = [...addressList];
      let _item = { ...data };
      _item.editable = false;
      const addressIndex = _addressList.findIndex((e) => e.id === _item.id);
      _addressList.splice(addressIndex, 1, _item);
      setAddressList(_addressList);
      setUpdateData(_item);

      return response;
    }
  };

  return (
    <>
      <Styled.MainContainer>
        <div className="billing">{data.Billing}</div>
        {addressList?.map((item) => {
          return (
            <>
              <div className="billingAddress">
                <label>{data.BillingAddress}</label>
              </div>
              <Styled.Eastlondon>
                <div>
                  <div className="eastlondon">
                    {item.editable ? (
                      <>
                        <Styled.EditAddressed>
                          <div className="editAddress">
                            <label className="label">Street Address</label>

                            <Styled.InputEdit
                              type="text"
                              name="address"
                              value={updateData?.address}
                              onChange={handleChange}
                            />
                            {errors.address && (
                              <Styled.Error>{errors.address}</Styled.Error>
                            )}
                          </div>

                          <div className="editAddress">
                            <label className="label">
                              {data.Country_Region}
                            </label>
                            <select
                              name="country"
                              id="country"
                              value={updateData?.country}
                              className="eastEdit"
                              onChange={handleChange}>
                              <option>{item?.Country?.name} </option>
                              {countryList?.map((item) => {
                                return (
                                  <>
                                    <option value={item?.id}>
                                      {item?.name}
                                    </option>
                                    ;
                                  </>
                                );
                              })}
                            </select>
                          </div>

                          <div className="editAddress">
                            <label className="label">State</label>
                            <select
                              name="state"
                              id="state"
                              value={updateData?.state}
                              className="eastEdit"
                              onChange={handleChange}>
                              <option>{item?.State?.name} </option>
                              {stateList?.map((item) => {
                                return (
                                  <>
                                    <option value={item?.id}>
                                      {item?.name}
                                    </option>
                                    ;
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          <div className="editAddress">
                            <label className="label">City</label>
                            <select
                              name="city"
                              id="city"
                              value={updateData?.city}
                              className="eastEdit"
                              onChange={handleChange}>
                              <option>{item?.City?.name} </option>
                              {cities?.map((item) => {
                                return (
                                  <>
                                    <option value={item?.id}>
                                      {item?.name}
                                    </option>
                                    ;
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          <div className="editAddress">
                            <label className="label">ZipCode</label>

                            <Styled.InputEdit
                              type="text"
                              name="zipCode"
                              value={updateData?.zipCode}
                              onChange={handleChange}
                            />
                            {errors.zipCode && (
                              <Styled.Error>{errors.zipCode}</Styled.Error>
                            )}
                          </div>
                        </Styled.EditAddressed>

                        <Styled.Mobilenumber>
                          <span className="mobileEdit">
                            {data.MobileNumber}
                          </span>
                          <Styled.InputEdit
                            type="text"
                            name="phone"
                            value={updateData?.phone}
                            className="eastlondon"
                            onChange={handleChange}
                          />
                          {errors.phone && (
                            <Styled.Error>{errors.phone}</Styled.Error>
                          )}
                        </Styled.Mobilenumber>
                      </>
                    ) : (
                      <>
                        <Styled.Address>{`${item?.address}, ${item?.City?.name}, ${item?.State?.name}, ${item?.Country?.name}, ${item?.zipCode}`}</Styled.Address>
                        <div>
                          <Styled.Mobilenumber>
                            <span className="mobile">{data.MobileNumber}</span>
                            <div className="number">{item?.phone}</div>
                          </Styled.Mobilenumber>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {item.editable ? (
                  <Styled.SaveButton>
                    <div className="cross">
                      <Button
                        title={
                          <>
                            <FiX className="crossIcon" /> {data.cancel}
                          </>
                        }
                        className="cancel"
                        onClick={() => handleCancel(item)}
                      />
                    </div>
                    <Button
                      title={data.save}
                      className="save"
                      onClick={() => handleSave(updateData, item)}
                    />
                  </Styled.SaveButton>
                ) : (
                  <Styled.EditButton>
                    <Button
                      className="editbutton"
                      icon={<FaPen />}
                      title={"Edit Address"}
                      onClick={() => handleEdit(item)}
                    />
                    <div className="line" />
                  </Styled.EditButton>
                )}
              </Styled.Eastlondon>
            </>
          );
        })}

        {/* <Styled.BillingCard>
          <div className="billingLabel">
            <label>{data.SavedBilling}</label>
          </div>
          <div className="text">{data.SomeText}</div>
        </Styled.BillingCard>
        <Styled.Cardholder className="container">
          <div className="cardholder">{data.CardholderName}</div>
          <div className="stacey">{data.StaceyMatthews}</div>
          <Styled.Textconatiner>
            <div className="cardNumber">
              <div className="card">{data.CardNumber}</div>
              <span className="number">{data.Number}</span>
            </div>
            <div className="cardNumber">
              <span className="card">{data.Expiration}</span>
              <span className="number">{data.Number}</span>
            </div>
            <div className="cardNumber">
              <span className="card">{data.CVV}</span>
              <span className="number">{data.Number}</span>
            </div>
          </Styled.Textconatiner>
          <Styled.ExpiryDate>
            <span className="expirydate">{data.ExpiryDate}</span>
            <span className="number">{data.Number}</span>
          </Styled.ExpiryDate>
        </Styled.Cardholder> */}
      </Styled.MainContainer>
    </>
  );
};
