import * as Styled from "./style";
import AntdTable from "../../../components/Atoms/Table";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LuListFilter } from "react-icons/lu";
import { dataItem } from "./data";
import { ProgressModal } from "./ProgressModal";
import { AiOutlineSearch } from "react-icons/ai";
import { FaRegFileExcel } from "react-icons/fa";

export const CoursesInProgress = (props) => {
  const {
    inProgressCourse,
    completedDataFetched,
    loading,
    tableParams,
    sortOrder,
    search,
    handleSearch,
    handleType,
    handleTableChange,
    modal,
    setModal,
  } = props;
  return (
    <div className="container">
      <Styled.MainContainer>
        <Styled.TableHeader className="container">
          <div className="tablecontainer">
            <Styled.SearchContainer>
              <AiOutlineSearch className="searchIcon" size={12} />
              <Styled.Input
                type="text"
                placeholder="Search by trainee name"
                value={search?.trainee_name}
                onChange={handleSearch}
              />
            </Styled.SearchContainer>
            <Styled.Filter>
              <LuListFilter className="filter" />
              <Styled.NewestFirst>
                <select
                  name="language"
                  id="language"
                  className="newest"
                  onChange={handleType}
                  value={sortOrder}
                >
                  <option value="DESC">{dataItem.Newestfirst}</option>
                  <option value="ASC">{dataItem.Oldestfirst}</option>
                </select>
              </Styled.NewestFirst>
            </Styled.Filter>
            <Styled.Spreadsheet>
              <FaRegFileExcel />
              <span>{"Download Spreadsheet"}</span>
            </Styled.Spreadsheet>
          </div>
          <Styled.DotIcon>
            <BsThreeDotsVertical className="dots" />
          </Styled.DotIcon>
        </Styled.TableHeader>
        <AntdTable
          data={completedDataFetched}
          columns={inProgressCourse}
          numberOfTableData={tableParams.pagination.pageSize}
          onChange={handleTableChange}
          {...tableParams}
          loading={loading}
        />
      </Styled.MainContainer>
      {modal ? <ProgressModal modal={modal} setModal={setModal} /> : null}
    </div>
  );
};
