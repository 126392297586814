import EndUser from "../../containers/InvitedUserSignUp/EndUser";
import MainLayout from "../../layout/MainLayout";
import React from "react";

const EndUserPage = () => {
  return (
    <MainLayout>
      <EndUser />
    </MainLayout>
  );
};

export default EndUserPage;
