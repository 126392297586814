import { createSlice } from "@reduxjs/toolkit";

const invitedSlice = createSlice({
  name: "invited",
  initialState: {
    invitedUser: [],
  },
  reducers: {
    invitedUserData: (state, action) => {
      state.invitedUser = action.payload;
    },
  },
});

export const { invitedUserData } =
invitedSlice.actions;
export default invitedSlice.reducer;
