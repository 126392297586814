const Auth = {
  signOut() {
    localStorage.removeItem("token");
  },
  isAuth() {
    return localStorage.getItem("token");
  },
  setToken(token) {
    try {
      localStorage.setItem("token", JSON.stringify(token));
    } catch (error) {
      console.error(error);
    }
  },
  getToken() {
    try {
      const authTokenString = localStorage.getItem("token");
      if (!authTokenString) {
        return "";
      }
      return authTokenString;
    } catch (error) {
      console.error(error);
    }
  },
  clearStorage() {
    localStorage.clear();
  },
};
export default Auth;
