import * as Styled from "./style";
import React from "react";
import { website } from "./data";

const PrivacyNotes = () => {
  return (
    <>
      <Styled.Terms>{website.notice}</Styled.Terms>
      <Styled.Lined className="container">
        <div>
          <div className="about">{website.back}</div>
          <div className="register">{website.update}</div>
          <div className="register">
            {" "}
            {website.this}
            <span className="web">{website.not}</span>
            {website.where}
          </div>
          <div className="register">{website.personal}</div>
          <div className="register">{website.regularly}</div>
        </div>
        <div>
          <div className="about">{website.impInfo}</div>
          <div className="register">{website.ltd}</div>
          <div className="register">
            {website.visit} <span className="web">{website.controller}</span>
            {website.collect}
          </div>
          <div className="register">{website.number}</div>
        </div>
        <div>
          <div className="about">{website.contactDet}</div>
          <div className="register">
            {website.touch}
            <br />

            <span className="web">{website.email} </span>
            {website.uk}
            <br />
            <span className="web">{website.postal} </span>
            {website.court}
          </div>
        </div>
        <div>
          <div className="about">{website.aboutYouInfo}</div>
          <div className="register">
            {website.no4}
            <span className="web">{website.dataPersonal}</span>
            {website.identifyInfo}
          </div>
          <div className="register">{website.second}</div>
          <div className="register">
            <span className="web">{website.ident}</span>
            {website.name}
            <br />
            <span className="web">{website.contactData}</span>
            {website.emailAddress}
            <br />
            <span className="web">{website.technial}</span>
            {website.usage}
            <br />
            <span className="web">{website.useData}</span>
            {website.system}
            <br />
            <span className="web">{website.financialData}</span>
            {website.questionnaires}
            <br />
            <span className="web">{website.locationData}</span>
            {website.remotely}
            <br />
            <span className="web">{website.feedback}</span>
            {website.completing}
            <br />
            <span className="web">{website.profileData}</span>
            {website.accessed}
            <br />
            <span className="web">{website.market}</span>
            {website.parties}
          </div>
        </div>
        <div>
          <div className="about">{website.yourInfo}</div>
          <div className="register">{website.mostrelevant}</div>
          <div className="register">
            {website.comp} <span className="web"> {website.obj}</span>
            {website.have}
            <br />
            {website.pursueOur} <span className="web"> {website.int}</span>
            {website.just}
            <br />
          </div>
          <div className="register">{website.listed}</div>
          <div className="register">
            {website.no5}
            <span className="web"> {website.legalOblig}</span>
<br/>
            <br />
            {website.marketingRecord}
            <br />
            {website.complywhere}
            <br />
            {website.retainInfo}
          </div>
          <div className="register">
            {website.no52}
            <span className="web">{website.legitimate}</span>
            <br/>
            <br/>
            <div>
              {website.pursue}
              <br />
              {website.optimise}
              <br />
              {website.enhance}
              <br />
              {website.functioning}
              <br />
              {website.defendProtect}
              <br />
              {website.infringe}
            </div>
          </div>
          <div className="register">{website.example}</div>
        </div>
        <div>
          <div className="about">{website.shareInfo}</div>
          <div className="register">{website.sharePersonal}</div>
          <div className="register">
            <span className="web">{website.ourPer}</span>
            {website.confidentiality}
            <br />
            <span className="web">{website.supplyChain}</span>
            {website.protection}
            <br />
            <span className="web">{website.regulatoryAuth}</span>
            {website.customs}
            <br />
            <span className="web">{website.professional}</span>
            {website.conduct}
          </div>
          <div className="register">{website.providing}</div>
        </div>
        <div>
          <div className="about">{website.transfer}</div>
          <div className="register">{website.servers}</div>
          <div className="register">{website.secretary}</div>
          <div className="register">{website.organisation}</div>
        </div>
        <div>
          <div className="about">{website.safe}</div>
          <div className="register">{website.being}</div>
          <div className="register">
            {website.authentication}
            <br />
            {website.network}
            <br />
            {website.review}
            <br />
            {website.staff}
            <br />
            {website.breach}
          </div>

          <div className="register">{website.informed}</div>
          <div className="register">{website.please}</div>
        </div>
        <div>
          <div className="about">{website.KEEP}</div>
          <div className="register">{website.fulfil}</div>
          <div className="register">{website.revenue}</div>
          <div className="register">{website.contractual}</div>
          <div className="register">{website.purposes}</div>
          <div className="register">{website.details}</div>
        </div>
        <div>
          <div className="about">{website.legal}</div>
          <div className="register">{website.relation}</div>
          <div className="register">{website.always}</div>
          <div className="register">{website.extension}</div>
          <div className="register">{website.instruction}</div>
          <div className="register">{website.belowyou}</div>
          <div className="register">{website.rightsInclude}</div>
          <div className="register">
            <span className="web">{website.access}</span>
            {website.usingDes}
            <br />
            <span className="web">{website.correction}</span>
            {website.new}
            <br />
            <span className="web">{website.deletion}</span>
            {website.restriction}
            <br />
            <span className="web">{website.Restriction}</span>
            {website.temporarily}
            <br/>
            <span className="web">{website.objection}</span>
            {website.explain}
            <br />
            <span className="web">{website.probability}</span>
            {website.ask}
            <br />
            <span className="web">{website.complaints}</span>
            {website.reaches}
          </div>
        </div>
        <div>
          <div className="about">{website.message}</div>
          <div className="register">{website.consented}</div>
          <div className="register">{website.affect}</div>
        </div>
      </Styled.Lined>
    </>
  );
};

export default PrivacyNotes;
