import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useEffect, useState } from "react";
import { examFormData } from "./data";
import { Routes } from "../../../routes/routes";
import { useNavigate } from "react-router-dom";
import useExamFormHook from "../../../hooks/ExamFormHook";
import { useSelector } from "react-redux";
import { message } from "antd";

const FormData = ({ data }) => {
  const { getExamList, postExamList } = useExamFormHook();
  const navigate = useNavigate();
  const examData = useSelector((state) => state?.examReducer?.examQuestion);
  const [postData, setPostData] = useState({
    courseId: data?.state?.courseId,
    questions: [],
  });
  const handleChange = (e, id, answer) => {
    let answersId = answer?.map((item) => item?.id);
    let val = e.target.value;

    setPostData((prevPostData) => {
      const questionIndex = prevPostData?.questions?.findIndex(
        (ques) => ques?.question_id === id
      );
      const newQuestion = {
        question_id: id,
        answers_id: answersId,
        correct_answer_id: Number(val),
      };

      let updatedQuestions;
      if (questionIndex !== -1) {
        updatedQuestions = [...prevPostData?.questions];
        updatedQuestions[questionIndex] = newQuestion;
      } else {
        updatedQuestions = [...prevPostData?.questions, newQuestion];
      }

      return {
        ...prevPostData,
        questions: updatedQuestions,
      };
    });
  };

  const validateAnswers = () => {
    if (postData?.questions?.length !== examData?.length) {
      return false;
    }
    return !(
      postData?.questions &&
      postData?.questions?.some((ques) => ques?.correct_answer_id === "")
    );
  };
  const handleClick = async () => {
    if (!validateAnswers()) {
      message.error("Please fill all the answers before submitting.");
      return;
    }
    const response = await postExamList(data?.state?.slug, postData);
    if (response?.status === 200) {
      message.success(response?.data?.message);
      navigate(Routes?.feedbackForm, {
        state: {
          courseId: data?.state?.courseId,
          title: data?.state?.title,
          username: data?.state?.username,
        },
      });
    }
    return response;
  };

  useEffect(() => {
    getExamList(data?.state?.slug);
  }, []);

  return (
    <>
      <Styled.ExamData>
        <div className="exam">{examFormData.courseExam}</div>
        <Styled.Security>
          <div>
            <div className="name">
              {examFormData.Name}:
              <span className="title"> {data?.state?.username}</span>
            </div>
            <div className="name">
              {examFormData.CourseTitle}:
              <span className="title"> {data?.state?.title}</span>
            </div>
          </div>
        </Styled.Security>
        <Styled.SingleLine />
        {examData &&
          examData?.map((question, index) => (
            <div key={`${question?.Course?.slug}-${index}`}>
              <Styled.Question>
                <div className="quesNum">
                  {examFormData.QUESTION} {index + 1 + "."}{" "}
                </div>
                <div className="quesText">{question?.question}</div>
                <div className="select">{examFormData.selectOne}:</div>
                <div className="options">
                  {question?.children &&
                    question?.children.map((child) => (
                      <div>
                        <input
                          type="radio"
                          value={child?.id}
                          name={question?.id}
                          onChange={(e) =>
                            handleChange(e, question?.id, question?.children)
                          }
                        />
                        <span> {child?.question}</span>
                      </div>
                    ))}
                </div>
              </Styled.Question>
            </div>
          ))}

        <div className="secured">
          <Button
            title={examFormData.finishExam}
            className="start"
            onClick={() => handleClick()}
          />
        </div>
      </Styled.ExamData>
    </>
  );
};

export default FormData;
