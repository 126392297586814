import { createSlice } from "@reduxjs/toolkit";
const individualCertificatesSlice = createSlice({
  name: "individualCertificates",
  initialState: {
    individualCertificates: [],
    loading: false,
    error: null,
  },
  reducers: {
    individualCertificatesStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    individualCertificatesSuccess: (state, action) => {
      state.loading = false;
      state.individualCertificates = action.payload;
    },
    individualCertificatesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { individualCertificatesStart, individualCertificatesSuccess, individualCertificatesFailure } =
individualCertificatesSlice.actions;
export default individualCertificatesSlice.reducer;
