import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loading: false,
    error: null,
    token: null,
    password: [],
  },
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload.data;
      state.token = action.payload.data.token;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
    },
    registerStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    registerSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload.data;
      state.token = action.payload.data;
    },
    registerFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.data;
    },

    updateEditUserData: (state, action) => {
      state.loading = true;
      state.user = { ...state.user, ...action.payload };
      state.loading = false;
    },
    updateFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updatePasswordSuccess: (state, action) => {
      state.loading = false;
      state.password = action.payload;
    },
    updatePasswordFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  registerStart,
  registerSuccess,
  registerFailure,
  logout,
  updateEditUserData,
  updateFailure,
  updatePasswordSuccess,
  updatePasswordFailure,
} = authSlice.actions;
export default authSlice.reducer;
