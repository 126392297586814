import React from "react";
import * as Styled from "./style";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { addressData, contactPageData, TileData } from "./data";
import Form from "../../components/Organisms/EnquiryForm/Form";
import SocilaMedia from "../../components/Atoms/SocilaMedia/SocilaMedia";

const Contact = () => {
  return (
    <Styled.ContactPageWrapper>
      <Styled.ImageWrapper>
        <Styled.ContactHeading>CONTACT US</Styled.ContactHeading>
      </Styled.ImageWrapper>
      <Styled.ContactDetailsWrapper>
        <Styled.DetailsHeading>Get In Touch</Styled.DetailsHeading>
        <Styled.DetailsMainSection>
          <Styled.DetailsSubSectionLeft>
            {addressData &&
              addressData?.map((item, index) => {
                return (
                  <Styled.EmailSection key={item?.value + index}>
                    {item?.icon}
                    <span className="emailText">{item?.value}</span>
                  </Styled.EmailSection>
                );
              })}
            <Styled.MediaIconWrapper>
              <Styled.MediaIcon>
                <FaFacebook className="mediaIcon" />
              </Styled.MediaIcon>
              <Styled.MediaIcon>
                <FaLinkedin className="mediaIcon" />
              </Styled.MediaIcon>
            </Styled.MediaIconWrapper>
          </Styled.DetailsSubSectionLeft>
          <Styled.DetailsSubSectionRight>
            {contactPageData &&
              contactPageData?.map((item, index) => {
                return (
                  <Styled.TelephoneDetail key={item?.label + index}>
                    {item?.icon}

                    <span className="number">
                      {item?.label}: <span>{item?.value}</span>
                    </span>
                  </Styled.TelephoneDetail>
                );
              })}
          </Styled.DetailsSubSectionRight>
        </Styled.DetailsMainSection>
      </Styled.ContactDetailsWrapper>
      <Form />
      <Styled.MapWrapper>
        <iframe
          className="mapIframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39742.27519790806!2d-0.49649942016218224!3d51.4968456832626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760e65698bffff%3A0x838a947a467d35c2!2sAvtav!5e0!3m2!1sen!2sin!4v1721739805791!5m2!1sen!2sin"
          width="100%"
          height="100%"
          style={{ border: "0" }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Styled.MapWrapper>
      <Styled.SubSectionWrapper>
        {TileData &&
          TileData?.map((item, index) => {
            return (
              <Styled.Tile key={item?.label + index}>
                <div>{item?.icon}</div>
                <div>{item?.label}</div>
              </Styled.Tile>
            );
          })}
      </Styled.SubSectionWrapper>
      <SocilaMedia />
    </Styled.ContactPageWrapper>
  );
};

export default Contact;
