import React from "react";
import * as Styled from "./style";
import AntdTable from "../../../components/Atoms/Table";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegFileExcel } from "react-icons/fa";
import { LuListFilter } from "react-icons/lu";
import { dataItem } from "./data";
import { AiOutlineSearch } from "react-icons/ai";
import CompletedCourseModal from "./BusinessCompletedCourseModal/CompletedCourseModal";
import { Certificate } from "./BusinessCompletedCourseModal/Certificate";

export const CompletedCourses = (props) => {
  const {
    completedCourse,
    completedDataFetched,
    loading,
    tableParams,
    sortOrder,
    search,
    handleSearch,
    handleType,
    handleTableChange,
    certificatePreview,
    setCertificatePreview,
    downloadData,
  } = props;

  return (
    <div className="container">
      <Styled.MainContainer>
        <Styled.TableHeader className="container">
          <div className="tablecontainer">
            <Styled.SearchContainer>
              <AiOutlineSearch className="searchIcon" size={12} />
              <Styled.Input
                type="text"
                placeholder="Search by trainee name"
                value={search?.trainee_name}
                onChange={handleSearch}
              />
            </Styled.SearchContainer>
            <Styled.Filter>
              <LuListFilter className="filter" />
              <Styled.NewestFirst>
                <select
                  name="language"
                  id="language"
                  className="newest"
                  onChange={handleType}
                  value={sortOrder}
                >
                  <option value="DESC">{dataItem.Newestfirst}</option>
                  <option value="ASC">{dataItem.Oldestfirst}</option>
                </select>
              </Styled.NewestFirst>
            </Styled.Filter>
            <Styled.Spreadsheet>
              <FaRegFileExcel />
              <span>{dataItem.DownloadSpreadsheet}</span>
            </Styled.Spreadsheet>
          </div>

          <Styled.DotIcon>
            <BsThreeDotsVertical className="dots" />
          </Styled.DotIcon>
        </Styled.TableHeader>
        <div>
          <AntdTable
            data={completedDataFetched}
            columns={completedCourse}
            numberOfTableData={tableParams?.pagination?.pageSize}
            onChange={handleTableChange}
            {...tableParams}
            loading={loading}
          />
        </div>
      </Styled.MainContainer>
      <div
        className="certificate"
        style={{
          position: "absolute",
          zIndex: "-1000",
          overflow: "hidden",
          height: "1px",
          width: "75%",
          opacity: "0",
        }}
      >
        <div>
          <Certificate certificatePreview={downloadData} />
        </div>
      </div>
      {certificatePreview && (
        <CompletedCourseModal
          certificatePreview={certificatePreview}
          setCertificatePreview={setCertificatePreview}
        />
      )}
    </div>
  );
};
