export const Button = ({
  onClick,
  className,
  title,
  icon = null,
  btn = false,
  disabled,
}) => {
  return (
    <>
      {btn ? (
        <button
          onClick={(e) => onClick(e)}
          className={className}
          disabled={disabled}
        >
          {icon}
          {title}
        </button>
      ) : (
        <button
          onClick={(e) => onClick(e)}
          className={className}
          disabled={disabled}
        >
          {title} {icon}
        </button>
      )}
    </>
  );
};

export default Button;
