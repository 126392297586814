export const FooterData = {
  Contact: "Contact Us",
  Email: "info@avtav.co.uk",
  Company: "Company",
  Services: "Services",
  Legal: "Legal",
  Socials: "Social Media",
  Avtav:
    "Avtav Limited is a company registered in England and Wales with company number 12704296. VAT Number 380839665",
};

export const ContactData = [
  {
    id: 0,
    label: "Training",
    value: "0203 984 4195",
  },
  {
    id: 1,
    label: "Vetting",
    value: "0203 984 4196",
  },
  {
    id: 2,
    label: "Recruitment Heathrow",
    value: "0203 984 4197",
  },
  {
    id: 3,
    label: "Recruitment Gatwick",
    value: "0203 984 4198",
  },
  {
    id: 4,
    label: "Recruitment Manchester",
    value: "0203 984 4194",
  },
];

export const Company = [
  {
    id: 0,
    value: "Home",
    path: "/",
  },
  {
    id: 1,
    value: "About Us",
    path: "/about-us",
  },
  {
    id: 2,
    value: "Services",
    path: "#",
  },
  {
    id: 3,
    value: "News",
    path: "#",
  },
  {
    id: 4,
    value: "Resources",
    path: "/resources",
  },
  {
    id: 5,
    value: "Contact",
    path: "/contact",
  },
];

export const Services = [
  {
    id: 0,
    value: "Training",
    path: "/training-course",
  },
  {
    id: 1,
    value: "Vetting",
    path: "/vetting",
  },
  {
    id: 2,
    value: "Recruitment",
    path: "/recruitment",
  },
];

export const Legal = [
  {
    id: 0,
    value: "Website Terms and conditions",
    path: "/terms-page",
  },
  {
    id: 1,
    value: "Privacy Policy",
    path: "/privacy-notes",
  },
  {
    id: 2,
    value: "Cookies Policy",
    path: "#",
  },
  {
    id: 4,
    value: "Disability Policy",
    path: "#",
  },
];
