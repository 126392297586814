import * as Styled from "./style";
import React from "react";
import { MdOutlineCheck } from "react-icons/md";
import { ReactComponent as LogoHead } from "../../../assets/Svg/logoHead.svg";
import { AccountDashboard } from "../../../components/Molecules/AccountDashboardPage/AccountDashboard";
import { DeclarationModalText } from "./LicenseData";

const DownloadDeclarationForm = ({ item, singleDeclaration }) => {
  return (
    <Styled.DeclarationContainer>
      <div className="corporateIcon">
        <div className="headCorporate">
          <LogoHead />
          {DeclarationModalText.corporate}
        </div>
      </div>
      <Styled.Textcontainer>
        <Styled.IconContainer>
          <Styled.IconBg>
            <MdOutlineCheck className="icon" />
          </Styled.IconBg>
          <div className="checkboxstyle">{DeclarationModalText.Text}</div>
        </Styled.IconContainer>
        <Styled.TextLine hr />
        {singleDeclaration ? (
          <AccountDashboard
            isInvitedDeclaration={true}
            item={item}
            isFormData={false}
            isName={true}
            isStaffDetails={true}
            isTraineeData={true}
          />
        ) : (
          <AccountDashboard
            isInvitedDeclaration={true}
            isFormData={false}
            item={item}
            isName={false}
            isTraineeData={true}
          />
        )}
      </Styled.Textcontainer>
    </Styled.DeclarationContainer>
  );
};

export default DownloadDeclarationForm;
