import * as Styled from "./style";
import { Progress } from "antd";

const Card = ({ data, children, ProgressBar }) => {
  const { courseLogo, banner, title, bannerDescription = " " } = data;
  const url = `${process.env.REACT_APP_URL}api/files/`;
  return (
    <>
      <div>
        <Styled.Card>
          <Styled.ImageCardContainer>
            <Styled.ImageCard
              src={`${url}${banner}`}
              alt={bannerDescription}
              className="image"
            />
            <Styled.Background />
            <Styled.HoveredItem
              className="middle"
              alt={bannerDescription}
              src={`${url}${courseLogo}`}
            />
          </Styled.ImageCardContainer>
          <Styled.Line />
          <Styled.DescriptionSection>
            <Styled.Heading>{title}</Styled.Heading>
            {children}
          </Styled.DescriptionSection>
        </Styled.Card>
        {ProgressBar && (
          <Styled.ProgressBar>
            <div className="progressbar">
              <span className="yourProgress">Your Progress</span>
              <span className="complete">50% COMPLETE</span>
            </div>
            <Progress percent={50} status="active" showInfo={false} />
          </Styled.ProgressBar>
        )}
      </div>
    </>
  );
};

export default Card;
