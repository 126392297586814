import Button from "../../../components/Atoms/Button/button";
import * as Styled from "./style";
import { useEffect, useState } from "react";
import { IndividualCertificates } from "./IndividualCertificates";
import { CorporateCertificates } from "./CorporateCertificates";
import { CertificateButton } from "./data";
import { useIndividualCertificatesHook } from "../../../hooks/IndividualCertificatesHook";

export const CertificateScreen = () => {
  const [toggle, setToggle] = useState(0);
  const {
    individualCertificatesForm,
    TableCourse,
    certificateList,
    certificatePreview,
    setCertificatePreview,
    count,
    handleSearch,
    handleTableChange,
    search,
    tableParams,
    loading,
    handleType,
    sortData,
    numberOfTableData,
    downloadData,
    handleClick,
    debouncedSearch,
    CorporateTableCourse,
  } = useIndividualCertificatesHook();
  const handleToggle = (id) => {
    setToggle(id);
  };

  useEffect(() => {
    individualCertificatesForm({
      ...debouncedSearch,
      sort: `createdAt|${sortData}`,
      type: toggle == 0 ? "Single User" : "Corporate User",
      page: tableParams?.pagination?.current,
    });
  }, [debouncedSearch, sortData, tableParams, toggle]);

  return (
    <div>
      <Styled.ButtonContainer>
        {CertificateButton?.map((item) => {
          return (
            <Button
              className={item.id === toggle ? "active" : "non-active"}
              title={item.title}
              onClick={() => handleToggle(item.id)}
            />
          );
        })}
      </Styled.ButtonContainer>

      {toggle === 0 && (
        <IndividualCertificates
          TableCourse={TableCourse}
          certificateList={certificateList}
          certificatePreview={certificatePreview}
          setCertificatePreview={setCertificatePreview}
          count={count}
          handleSearch={handleSearch}
          handleTableChange={handleTableChange}
          search={search}
          tableParams={tableParams}
          loading={loading}
          handleType={handleType}
          sortData={sortData}
          numberOfTableData={numberOfTableData}
          downloadData={downloadData}
          handleClick={handleClick}
        />
      )}
      {toggle === 1 && <CorporateCertificates
        CorporateTableCourse={CorporateTableCourse}
        certificateList={certificateList}
        certificatePreview={certificatePreview}
        setCertificatePreview={setCertificatePreview}
        count={count}
        handleSearch={handleSearch}
        handleTableChange={handleTableChange}
        search={search}
        tableParams={tableParams}
        loading={loading}
        handleType={handleType}
        sortData={sortData}
        numberOfTableData={numberOfTableData}
        downloadData={downloadData}
        handleClick={handleClick} />}
    </div>
  );
};
