import Auth from "./auth";
import Emitter, { EventTypes } from "../utils/Emitter";
import axios from "axios";
import { message } from "antd";
import { cors } from "../utils/Cors";

export default class Request {
  constructor(headers) {
    cors();
    this.http = axios.create({
      baseURL: process.env.REACT_APP_URL,
      withCredentials: false,
    });

    this.http.interceptors.request.use(async (config) => {
      let token = Auth.getToken();
      if (headers?.responseType) {
        config.responseType = headers?.responseType;
      }
      config.headers = {
        Authorization: token ? `Bearer ${token}` : "",
        // "Content-Type": "application/json",
        ...headers,
      };
      return config;
    });
    this.http.interceptors.response.use(
      async (response) => {
        return response;
      },
      async (error) => {
        console.error("error", error);
        if (error.response) {
          // forbidden: 403
          // unauthrized: 401
          // not found: 400
          if (error.response.status && error.response.status === 403) {
            return Promise.reject(error.response.data);
          } else if (error.response.status && error.response.status === 401) {
            // delete the local storage and redirect to home
            Emitter.emit(EventTypes.INVALID_TOKEN);
          } else {
            let errorData = error?.response?.data;
            if (errorData) {
              if (errorData?.message?.length > 1) {
                message.error(errorData.message);
              } else {
                message.error(errorData.errorDetail);
              }
            }
            return Promise.reject(errorData);
          }
        } else if (error.request) {
          return Promise.reject(error.request);
        } else {
          return Promise.reject(error);
        }
      }
    );
    for (const method of ["get", "post", "put", "delete"]) {
      this[method] = this.http[method];
    }
  }
}
