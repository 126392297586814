import React, { useEffect } from "react";
import * as Styled from "./style";
import { data } from "./data";
import HttpApis from "../../services/HttpApis";
import { useDispatch, useSelector } from "react-redux";
import { invitedUserData } from "../../redux/slices/invitedSlice";
import { useSearchParams } from "react-router-dom";

export const Welcome = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const invitedCode = searchParams.get("invitedCode");
  const recepientName = searchParams.get("name");
  const invitedUserDetails = useSelector(
    (state) => state.invitedReducer.invitedUser
  );

  useEffect(() => {
    const fetchInvitedUsers = async (params) => {
      try {
        const response = await HttpApis.getWelcomeInvited(params);
        dispatch(invitedUserData(response.data.data));
      } catch (error) {
        console.error(error);
      }
    };

    fetchInvitedUsers({ invitedCode, email });
  }, [email, invitedCode]);

  const companyName = invitedUserDetails?.user_data?.companyName
    ? invitedUserDetails?.user_data?.companyName
    : "";

  return (
    <Styled.Maincontainer>
      <Styled.NameContainer>
        <span className="helloText">{data.Hello}</span> {recepientName}
      </Styled.NameContainer>
      <Styled.ThankYouText>{data.thankyou}</Styled.ThankYouText>
      <Styled.Information>
        {data.info} <span className="employer">{companyName} </span>
        {data.password}
      </Styled.Information>
      <Styled.Proceed>{data.proceed}</Styled.Proceed>
    </Styled.Maincontainer>
  );
};
