export const dataItem = {
  Courses: "Courses in Progress",
  Number: "3",
  BelowText:
    "Below is a list of the courses in progress for each enrolled trainee.",
  GSAT: "GSAT",
  Newestfirst: "Newest first",
};
export const ModalText = {
  ProgressStats: "Progress Stats",
  ShaunMurphy: "Shaun Murphy",
  Stats: "Certificate not available yet",
};
export const CoursesDataList = [
  {
    id: 0,
    title: "Module 1",
    heading: "The threat to aviation ",
    time: "(15 mins)",
    text: "Completed",
  },
  {
    id: 1,
    title: "Module 2",
    heading: "Security in the aviation industry ",
    time: "(15 mins)",
    text: "Incomplete",
    color: "rgba(109, 125, 139, 1)"

  },
  {
    id: 2,
    title: "Module 3",
    heading: "The Trainee’s Role ",
    time: "(15 mins)",
    text: "Complete",
  },
  {
    id: 3,
    title: "Module 4",
    heading: "Access Control and the screening point",
    time: "(15 mins)",
    text: "Incomplete",
  },
];
