import * as Styled from "./style";
import MultiStepProgressBar from "../../components/Atoms/MultiStepProgressBar/MultiStepProgressBar";
import React from "react";
import Sidebar from "../../components/Organisms/Sidebar/Sidebar";
import SignupHeader from "../../components/Atoms/SignupHeader/SignupHeader";
import BackgroundImageUn from "../../assets/image/BackgroundImageUn.jpg";
import useStepper from "../../hooks/StepperHook";
import { constant } from "../../constants/Constants";

const InviterUserSignUp = () => {
  const { invitedSteps, current, prev, next, errors, setErrors } = useStepper();

  return (
    <Styled.Container>
      <Styled.SidebarContainer>
        <Styled.ImageContainer
          src={BackgroundImageUn}
          alt={constant.CorporateImage}>
          <Sidebar />
        </Styled.ImageContainer>
      </Styled.SidebarContainer>
      <Styled.FormContainer>
        <Styled.MainContainer>
          <Styled.Head>
            <SignupHeader title={constant.InvitedUser} />
          </Styled.Head>
          <MultiStepProgressBar
            steps={invitedSteps}
            nextButton={constant.Next}
            previousButton={constant.Previous}
            buttonHide={true}
            status="isInvited"
            current={current}
            next={next}
            prev={prev}
            errors={errors}
            setErrors={setErrors}
          />
        </Styled.MainContainer>
      </Styled.FormContainer>
    </Styled.Container>
  );
};

export default InviterUserSignUp;
