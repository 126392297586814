import * as Styled from "./style";
import FooterLogo from "../../../assets/Svg/AVTAV_logo.svg";
import React from "react";
import { AiFillLinkedin, AiOutlineInstagram } from "react-icons/ai";
import { MdCall, MdEmail, MdFacebook } from "react-icons/md";
import { Company, ContactData, FooterData, Legal, Services } from "./data";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleRoute = (path) => {
    navigate(path);
  };
  return (
    <>
      <Styled.FooterBackground>
        <Styled.LogoContainer>
          <img src={FooterLogo} alt="FooterLogo" width={283} height={65} />
        </Styled.LogoContainer>
        <Styled.InformationContainer>
          <Styled.LinksContainer>
            <Styled.CompanyContainer>
              <Styled.Company>
                <Styled.Heading>{FooterData.Company}</Styled.Heading>
                {Company?.map((item) => {
                  return (
                    <Styled.Label
                      className="value"
                      onClick={() => handleRoute(item?.path)}
                    >
                      {item?.value}
                    </Styled.Label>
                  );
                })}
              </Styled.Company>
            </Styled.CompanyContainer>
            <Styled.ServiceContainer>
              <Styled.Heading>{FooterData.Services}</Styled.Heading>
              {Services?.map((item) => {
                return (
                  <Styled.Label
                    className="value"
                    onClick={() => handleRoute(item?.path)}
                  >
                    {item?.value}
                  </Styled.Label>
                );
              })}
            </Styled.ServiceContainer>
            <Styled.LegalContainer>
              <Styled.Heading>{FooterData.Legal}</Styled.Heading>
              {Legal?.map((item) => {
                return (
                  <Styled.Label
                    className="value"
                    onClick={() => handleRoute(item?.path)}
                  >
                    {item?.value}
                  </Styled.Label>
                );
              })}
            </Styled.LegalContainer>
          </Styled.LinksContainer>
          <Styled.Socials>
            <Styled.Company>
              <Styled.Heading>{FooterData.Socials}</Styled.Heading>
            </Styled.Company>
            <MdFacebook className="socialIcon" />
            <AiFillLinkedin className="socialIcon" />
            <AiOutlineInstagram className="socialIcon" />
          </Styled.Socials>
        </Styled.InformationContainer>
        <Styled.ContactContainer>
          <Styled.Heading>{FooterData?.Contact}</Styled.Heading>
          {ContactData?.map((item, index) => {
            return (
              <Styled.Content className="content" key={index}>
                <Styled.Label>{item?.label}</Styled.Label>
                <Styled.PhoneNumber>
                  <MdCall className="icon" />
                  <Styled.Value>{item?.value}</Styled.Value>
                </Styled.PhoneNumber>
              </Styled.Content>
            );
          })}
          <Styled.EmailContainer>
            <MdEmail className="emailIcon" />
            <Styled.Value>{FooterData?.Email}</Styled.Value>
          </Styled.EmailContainer>
        </Styled.ContactContainer>
      </Styled.FooterBackground>
      <Styled.Background>
        <Styled.CompanyDescription>
          {FooterData.Avtav}
        </Styled.CompanyDescription>
      </Styled.Background>
    </>
  );
};

export default Footer;
