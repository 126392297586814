
export const dataItem = {
  Completed: "Completed Courses",
  Number: "5",
  BelowText: "Below is a list of the certificates of completed courses",
  declaration: "Below are the declaration forms you have provided",
  GSAT: "GSAT",
  Newestfirst: "Newest first",
  Oldestfirst: "Oldest first",
  DownloadSpreadsheet: "Download Spreadsheet",
  trainee: "Search by trainee name",
};
 export const ModalItem = {
   training: "This training is valid for 2 years and expires on DD/MM/YYYY.",
   expire: "Training expiration",
   download: " Download Certificate",
   preview: "Preview Certificate",
   issue: "Issue Date",
   institute: "Issuing Institute",
   recName: "Recipients name",
   stacey: "Stacey Matthews",
   email: "stacey@avtavair.co.uk",
   eng: "English",
   general: "General Security Awareness Training",
   course: "Course name",
   gsat: "(GSAT)",
   certificateModal: "Certificate of completion",
 };
 