import React from "react";
import * as Styled from "./style";
import { british, corporateInvoice } from "./data";
import AvtavLogoInvoice from "../../../assets/Svg/AvtavLogoInvoice.svg";
import { useSelector } from "react-redux";

const Invoice = () => {
  const orderDetails = useSelector(
    (state) => state.checkoutReducer.checkout.share_data
  );
  const date = new Date(orderDetails?.billing?.updatedAt);
  const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date
    .getFullYear()
    .toString()
    .substr(-2)}`;

  let cartTotal = 0;
  orderDetails?.cart &&
    orderDetails?.cart?.forEach((item) => {
      cartTotal += parseInt(item?.price);
    });

  const vat = orderDetails?.order?.vatTax ? orderDetails?.order?.vatTax : 0;
  const totalAmount = cartTotal + parseInt(vat);
  return (
    <Styled.CorporateInvoice>
      <div className="CheckboxInvoice">
        <div className="invoiceNo">
          <div className="invoice">{british.invoice} </div>
          <div className="Number">
            {british.no}
            <span className="HashNo">
              {british.hash} {orderDetails?.order?.id}
            </span>
          </div>
        </div>
        <Styled.LogoContainer>
          <Styled.Logo>
            <img src={AvtavLogoInvoice} alt="logo" />
          </Styled.Logo>
          <Styled.Checkbox>
            {corporateInvoice?.map((item, id) => {
              return <img key={id} src={item.name} alt="logo" />;
            })}
          </Styled.Checkbox>
        </Styled.LogoContainer>
      </div>
      <Styled.CorporateDescription className="container">
        <div>
          <div className="client">
            <span> {british.client}</span>
            <span> {orderDetails?.billing?.firstName}</span>
            <span>{orderDetails?.billing?.lastName}</span>
          </div>
          <div className="denmark">
            <div className="way">
              {orderDetails?.billing?.address}
              <br /> {orderDetails?.billing?.City?.name}
              {", "}
              {orderDetails?.billing?.State?.name}
              <br />
              {orderDetails?.billing?.Country?.name}
            </div>
            <div className="date"> {formattedDate}</div>
          </div>
        </div>

        <Styled.Items>
          <table style={{ width: "100%" }} className="tableData">
            <thead>
              <tr>
                <th>Item</th>
                <th>Description</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orderDetails?.cart?.map((item, index) => {
                return (
                  <>
                    <tr className="line">
                      <td>
                        <div key={index}>{index + 1}</div>
                      </td>
                      <td>
                        <div>{item?.Course?.title}</div>
                      </td>
                      <td className="value">
                        <div>{item?.price}</div>
                      </td>
                      <td>
                        <div>{item?.qty}</div>
                      </td>

                      <td>
                        <div>{item?.price}</div>
                      </td>
                    </tr>
                  </>
                );
              })}

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Subtotal</td>
                <td>
                  <div>{cartTotal}</div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>VAT</td>
                <td>
                  <div>{vat}</div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>
                  <div>{totalAmount}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </Styled.Items>
      </Styled.CorporateDescription>
    </Styled.CorporateInvoice>
  );
};

export default Invoice;
