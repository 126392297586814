import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import { usePasswordHook } from "../../../hooks/PasswordHook";
import { Security } from "../businessConstant";

const BusinessUserSecurity = () => {
  const [eyeVisible, setEyeVisible] = useState({
    oldPassword: false,
    newPassword: false,
    confirm_password: false,
  });
  const { passwordData, password, setPassword, errors } = usePasswordHook();

  const handleChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const handleOldChange = (data) => {
    setEyeVisible({
      ...eyeVisible,
      [data]: !eyeVisible[data],
    });
  };

  return (
    <>
      <Styled.MainContainer className="container">
        <Styled.Container>
          <Styled.Heading>
            <div>{Security.account}</div>
          </Styled.Heading>
        </Styled.Container>
        <Styled.PasswordContainer>
          <div className="password">Password</div>
          <div>
            <Styled.Label>
              Old Password <span className="star">*</span>
            </Styled.Label>
            <Styled.MainPassword>
              <Styled.Input
                type={eyeVisible.oldPassword ? "text" : "password"}
                name="oldPassword"
                value={password?.oldPassword}
                onChange={handleChange}
              />
              <span
                onClick={() => handleOldChange("oldPassword")}
                className="eye"
              >
                {eyeVisible.oldPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </Styled.MainPassword>
            {errors?.oldPassword && (
              <Styled.Error>{errors?.oldPassword}</Styled.Error>
            )}
          </div>
          <div>
            <Styled.Label>
              New Password <span className="star">*</span>
            </Styled.Label>
            <Styled.MainPassword>
              <Styled.Input
                type={eyeVisible.newPassword ? "text" : "password"}
                name="password"
                value={password?.password}
                onChange={handleChange}
              />
              <span
                onClick={() => handleOldChange("newPassword")}
                className="eye"
              >
                {eyeVisible.newPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </Styled.MainPassword>
            {errors?.password && (
              <Styled.Error>{errors?.password}</Styled.Error>
            )}
          </div>
          <div>
            <Styled.Label>
              Confirm Password <span className="star">*</span>
            </Styled.Label>
            <Styled.MainPassword>
              <Styled.Input
                type={eyeVisible.confirm_password ? "text" : "password"}
                name="confirm_password"
                value={password?.confirm_password}
                onChange={handleChange}
              />
              <span
                onClick={() => handleOldChange("confirm_password")}
                className="eye"
              >
                {eyeVisible.confirm_password ? <FaEye /> : <FaEyeSlash />}
              </span>
            </Styled.MainPassword>
            {errors?.confirm_password && (
              <Styled.Error>{errors?.confirm_password}</Styled.Error>
            )}
          </div>
        </Styled.PasswordContainer>
        <Styled.UpdateButton>
          <Button
            title={Security.update}
            className={"update"}
            onClick={() => passwordData(password)}
          />
        </Styled.UpdateButton>
      </Styled.MainContainer>
    </>
  );
};
export default BusinessUserSecurity;
