import Button from "../../../components/Atoms/Button/button";
import * as Styled from "./style";
import { useState } from "react";
import { SingleDeclarationForm } from "./SingleDeclarationForm";
import { Signedbyyou } from "./Signedbyyou";
import { DeclarationButton } from "./data";

export const SingleDeclarationScreen = () => {
  const [toggle, setToggle] = useState(0);
  const handleclick = (id) => {
    setToggle(id);
  };
  return (
    <div>
      <Styled.ButtonContainer>
        {DeclarationButton?.map((item) => {
          return (
            <Button
              className = {item.id === toggle ? "active" : "non-active"} 
              title={item.title}
              onClick={() => handleclick(item.id)}
            />
          );
        })}
      </Styled.ButtonContainer>

      {toggle === 0 && <SingleDeclarationForm />}
      {toggle === 1 && <Signedbyyou />}
    </div>
  );
};
