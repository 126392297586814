import * as Styled from "./style";
import AVSECCoursesCard from "../../../components/Atoms/CoursesCard/AVSECCoursesCard";
import AntdTable from "../../../components/Atoms/Table";
import Button from "../../../components/Atoms/Button/button";
import CoursesDetail from "./CoursesDetail";
import React, { useEffect, useState } from "react";

import { BsThreeDotsVertical } from "react-icons/bs";
import { LuListFilter } from "react-icons/lu";
import { MdAdd } from "react-icons/md";
import { useCourse } from "../../../hooks/CourseHook";

import { dataItem } from "./ButtonData";
import { AiOutlineSearch } from "react-icons/ai";

const Courses = () => {
  const [isPageOpen, setIsPageOpen] = useState(true);
  const {
    TableCourse,
    show,
    licenseRelatedCourses,
    search,
    sortOrder,
    tableParams,
    handleSearch,
    handleTableChange,
    handleSort,
    fetchLicensesList,
    licenseCount,
    debouncedSearch,
    loading,
    setShow,
  } = useCourse();

  const handleClickCourse = () => {
    setIsPageOpen(!isPageOpen);
  };

  useEffect(() => {
    fetchLicensesList({
      ...debouncedSearch,
      sort: `createdAt|${sortOrder}`,
      page: tableParams.pagination.current,
    });
  }, [debouncedSearch, tableParams, sortOrder]);
  return (
    <>
      {show ? (
        <CoursesDetail show={show} setShow={setShow} />
      ) : (
        <>
          {isPageOpen ? (
            <div className="container">
              <Styled.AddCourseContainer>
                <Styled.Courses>
                  <span className="courses">{dataItem.Courses}</span>
                  <Styled.Pipe />
                  <span className="number">{licenseCount}</span>
                </Styled.Courses>
                <Styled.AddCourse>
                  <Button
                    icon={<MdAdd size={14} className="plus" />}
                    title={"Add Courses"}
                    className="add"
                    type="primary"
                    onClick={() => handleClickCourse()}
                  ></Button>
                </Styled.AddCourse>
              </Styled.AddCourseContainer>

              <Styled.BelowText>{dataItem.BelowText}</Styled.BelowText>
              <Styled.MainContainer>
                <Styled.TableHeader className="container">
                  <div className="tablecontainer">
                    <Styled.SearchContainer>
                      <AiOutlineSearch className="searchIcon" size={12} />
                      <Styled.Input
                        type="text"
                        placeholder="Search"
                        value={search?.title}
                        onChange={handleSearch}
                      />
                    </Styled.SearchContainer>

                    <Styled.Filter>
                      <LuListFilter className="filter" />
                      <Styled.NewestFirst>
                        <select
                          onChange={handleSort}
                          name="language"
                          id="language"
                          value={sortOrder}
                          className="newest"
                        >
                          <option value="DESC">{dataItem.Newestfirst}</option>
                          <option value="ASC">{dataItem.Oldestfirst}</option>
                        </select>
                      </Styled.NewestFirst>
                    </Styled.Filter>
                  </div>
                  <Styled.DotIcon>
                    <BsThreeDotsVertical className="dots" />
                  </Styled.DotIcon>
                </Styled.TableHeader>
                <div>
                  <AntdTable
                    data={licenseRelatedCourses}
                    columns={TableCourse}
                    onChange={handleTableChange}
                    {...tableParams}
                    loading={loading}
                  />
                </div>
              </Styled.MainContainer>
            </div>
          ) : (
            <AVSECCoursesCard
              isPageOpen={true}
              iswidth={false}
              handleClickCourse={handleClickCourse}
            />
          )}
        </>
      )}
    </>
  );
};

export default Courses;
