import { Tabs } from "antd";
import { Link } from "react-scroll";
import { styled } from "styled-components";
import { color } from "../../../theme/Color";

export const Tab = styled(Tabs)`
  display: flex;
  width: 100%;
  padding-top: 25px;
  font-size: 0.938rem;
  font-weight: 600;
  line-height: 140%;
  color: ${color._08232f};
  & .ant-tabs-nav-wrap {
    background: rgba(239, 243, 249, 0.5) !important;
    border-radius: 18px;
    text-decoration: none;
    font-size: 0.938rem;
    font-weight: 600;
    line-height: 140%;
    box-shadow: none;
  }
  & .ant-tabs-nav {
    width: 100%;
    background: transparent;
    margin-bottom: 45px;
  }
  & .ant-tabs-tab-btn {
    color: ${color._16384e};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  & .ant-tabs-tab-btn:hover {
    color: #177a9c;
  }

  & .ant-tabs-tab:hover {
    color: ${color._16384e};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  & .ant-tabs-ink-bar {
    height: 5px;
    background: ${color._ffbe32} !important;
  }
  & .ant-tabs-nav-list {
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${color._16384e} !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  & .ant-tabs-content-holder {
    width: ${({ TabWidth }) => (TabWidth ? "" : "100%")};
  }

  & .ant-tabs-nav::before {
    border-bottom: none;
  }
`;
export const NavLinks = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${color._08232F};
  &:hover {
    color: ${color._08232F};
  }
`;
