export const induction = {
  induction: `Induction`,
  check: ` Checklist`,
  boxes: `Please tick the boxes when you feel comfortable that you have been given information about, understand and are familiar with the following:`,
  and: `Policies and Procedures`,
  eq: `Equality`,
  protection: `Data protection`,
  app: `Appeals`,
  mal: `Malpractice`,
  safety: `Health and safety`,
  whistleblowing: `Complaints and whistleblowing`,
  appeal: `Appeals`,
  course: `Training course information`,
  training: `Roles of responsibilities of the training team`,
  aims: `Course aims`,
  st: `Structure of the training course`,
  dates: `Assessment requirements including dates`,
  supp: `Support available`,
  staff: `How and when to contact key staff members`,
  require: `Attendance requirements`,
  cont: `Course content`,
  provided: `Feedback provided to you`,
  used: `How your feedback can be provided and used`,
  trainee: `Trainee acknowledgement:`,
  covering: `I have received an induction covering all the elements listed above.`,
  comm: `Comments`,
  sign: `Signature:`,
  dateS: `Date of signature:`,
  dd: `DD/MM/YYYY`,
  tick: `(Tick all)`,
};
