import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React from "react";
import { exam } from "./data";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/routes";

const GeneralCourse = ({ data }) => {
  const navigate = useNavigate();
  const handleRoute = () => {
    navigate(Routes?.exam, { state: data?.state });
  };
  return (
    <>
      <Styled.ExamData>
        <div className="exam">{exam.courseExam}</div>
        <Styled.Security>
          <div>
            <div className="name">
              {exam.name}
              <span className="john">{data?.state?.username}</span>
            </div>
            <div className="name">
              {exam.title}
              <span className="john">{data?.state?.title}</span>
            </div>
          </div>
        </Styled.Security>
        <Styled.Introduction>
          <div className="secured">
            <div className="intro">{exam.intro}</div>
            <div className="before">{exam.before}</div>
          </div>
          <div className="secured">
            <div className="intro">{exam.mal}</div>
            <div className="before">{exam.deliberate}</div>
          </div>
          <div className="secured">
            <div className="intro">{exam.assess}</div>
            <div className="before">{exam.course}</div>
            <div className="before">{exam.final}</div>
          </div>
          <div className="secured">
            <div className="intro">{exam.pass}</div>
          </div>
          <div className="mains">
            <div className="intro">
              {exam.marks}
              <span className="percent">{exam.percent}</span>
            </div>
          </div>
        </Styled.Introduction>
        <div className="secured">
          <Button
            title={"Start Exam"}
            className="start"
            onClick={() => handleRoute()}
          />
        </div>
      </Styled.ExamData>
    </>
  );
};

export default GeneralCourse;
