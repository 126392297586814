import { createSlice } from "@reduxjs/toolkit";
const quizSlice = createSlice({
  name: "quiz",
  initialState: {
    quiz: [],
    loading: false,
    error: null,
  },
  reducers: {
    quizStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    quizSuccess: (state, action) => {
      state.loading = false;
      state.quiz = action.payload;
    },
    quizFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { quizStart, quizSuccess, quizFailure } = quizSlice.actions;
export default quizSlice.reducer;
