import { createSlice } from "@reduxjs/toolkit";
const checkoutSlice = createSlice({
  name: "checkoutData",
  initialState: {
    checkout: [],
    loading: false,
    error: null,
    checkoutForm:null
  },
  reducers: {
    checkoutStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    checkoutSuccess: (state, action) => {
      state.loading = false;
      state.checkout = action.payload;
    },
    checkoutFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    checkoutFormAction: (state, action)=>{
      state.checkoutForm = action.payload;
      localStorage.setItem("checkoutForm", JSON.stringify(action.payload))
    }
  },
});
export const { checkoutStart, checkoutSuccess, checkoutFailure, checkoutFormAction } =
  checkoutSlice.actions;
export default checkoutSlice.reducer;
