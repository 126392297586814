import styled from "styled-components";
import { color } from "../../theme/Color";

export const Courses = styled.div`
  width: 100%;
  height: 138px;
  flex-shrink: 0;
  background: ${color._0c293d};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .precourse {
    color: ${color._ffffff};
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 3px;
  }
  .please {
    color: ${color._ffffff};
    font-size: 14px;
    margin-top: 2px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const MainComponent = styled.div`
  margin-top: 20px;
  .assessEquality {
    margin-top: 20px;
    width: 91%;
    position: relative;
    left: 4%;
  }
  .yes {
    color: ${color._000000};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const FirstRow = styled.div`
  .compName {
    color: var(--Question-Color, #007097);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .avtav {
    color: ${color._68828e};
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80.3%;
  margin: 0 auto;
  height: 117px;
  background: ${color._F3FBFF};
  .agr {
    color: ${color._15374D};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%;
  }
  .company {
    color: ${color._16384E};
    margin-bottom: 5px;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .below {
    margin-top: 5px;
    color: ${color._68828e};
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const SecondRow = styled.div`
  margin: 0 auto;
  height: 39px;
  flex-shrink: 0;
  background: ${color._F3FBFF};
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .need {
    color: ${color._16384E};
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const MainForm = styled.div`
  .name {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 18px;
  }
  .john {
    color: ${color._16384E};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  position: relative;
  left: 4%;
  .mains {
    margin-left: 17px;
    margin-top: 18px;
  }
  .lang {
    color: ${color._000000};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .deaf {
    color: black;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 152.5%;
  }
  .wrapSecond {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 61%;
    padding-left: 18px;
  }
  .select {
    width: 313px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 3px;
    border: 1px solid ${color._ebebeb};
    outline: none;
    background: ${color._fbfbfb};
    margin-top: 18px;
    margin-left: 18px;
  }
  .please {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .disability {
    margin-top: 18px;
    margin-left: 18px;
  }
  .yesNo {
    gap: 23px;
    display: flex;
    margin-top: 15px;
    color: ${color._000000};
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 18px;
  }
  .singleChoiceText {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .radio {
    transform: scale(1);
  }
  .begin {
    gap: 23px;
    display: flex;
    margin-top: 15px;
    color: ${color._000000};
    margin-left: 20px;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .subQuestion {
    padding: 10px 0px 0px 18px;
  }
`;
export const Label = styled.label`
  color: ${color._000000};
  margin-top: 20px;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  .star {
    color: ${color._f20000};
  }
  gap: 3px;
  display: flex;
`;
export const Labels = styled.label`
  color: ${color._000000};
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  .star {
    color: ${color._f20000};
  }
  gap: 3px;
  display: flex;
`;

export const Data = styled.div`
  color: ${color._000000};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  .change {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 5px;
  }
`;
export const Deaf = styled.div`
  .deaf {
    color: black;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 152.5%;
  }
  .otherInput {
    background: ${color._fbfbfb};
    color: ${color._6d7d8b};
    border: 1px solid ${color._ebebeb};
    margin-left: 24px;
    width: 192px;
    height: 32px;
    padding-left: 5px;
    outline: none;
  }
`;
export const DeafChange = styled.div`
  display: flex;
  margin-top: 15px;
`;
export const Input = styled.input`
  width: 192px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid ${color._ebebeb};
  outline: none;
  background: ${color._fbfbfb};
  padding: 10px;
  text-transform: capitalize;
  &::placeholder {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 152.5%;
  }
`;
export const TextArea = styled.textarea`
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid ${color._ebebeb};
  outline: none;
  background: ${color._fbfbfb};
  padding: 10px;
  &::placeholder {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 152.5%;
  }
`;

export const DataYes = styled.div`
  margin-top: 16px;
  color: ${color._000000};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 2px;
  .support {
    margin-left: 11px;
  }
`;
export const InputData = styled.input`
  outline: none;
  width: 313px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
  text-transform: capitalize;
`;
export const InputText = styled.input`
  width: 591px;
  height: 95px;
  flex-shrink: 0;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid ${color._ebebeb};
  outline: none;
  background: ${color._fbfbfb};
  text-transform: capitalize;
`;
export const MalContainer = styled.div`
  width: 95%;
  margin: 0 auto;
  height: 133px;
  flex-shrink: 0;
  border-radius: 2px;
  background: ${color._0c293d};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  .pro {
    color: ${color._ffffff};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%;
  }
  .policy {
    color: ${color._ffffff};
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const Start = styled.div`
  margin-top: 21px;
  .start {
    width: 237px;
    height: 36px;
    border: none;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._0073de};
    color: ${color._ffffff};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Scope = styled.div`
  margin-top: 20px;
  .found {
    margin-top: 20px;
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
  }
  .http {
    color: ${color._177a9c};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
    text-decoration-line: underline;
  }
  .scope {
    color: ${color._177a9c};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 136.184%;
    margin-top: 20px;
  }
  .those {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%;
  }
  .conj {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%;
    margin-top: 20px;
  }
  .stage {
    color: ${color._15374d};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 136.184%;
    margin-top: 15px;
  }
`;
export const StartAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 92%;
  margin: 0 auto !important;
  height: 158px;
  background: ${color._F3FBFF};
  margin-top: 50px !important;
  .agr {
    color: ${color._15374D};
  }
`;
export const Img = styled.img`
  width: 290px;
`;
export const ImgPing = styled.img`
  position: relative;
  left: 3%;
`;
export const Vett = styled.div`
  flex: 0.25;
`;
export const Preview = styled.div`
  flex: 0.5;
`;
export const Image = styled.div`
  flex: 0.25;
`;

export const John = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20%;
  .hlo {
    color: ${color._15374d};
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .john {
    color: var(--Question-Color, #007097);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .field {
    color: ${color._767676};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const JohnDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-bottom: 30px;
  padding-top: 15%;
  .container {
    display: flex;
    gap: 20px;
  }
`;
export const Inter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 777px;
  height: 122px;
  flex-shrink: 0;
  border-radius: 5px;
  background: ${color._0c293d};
  color: ${color._ffffff};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 30px;
`;
export const Return = styled.div`
  position: relative;
  top: 58px;
  color: var(--Question-Color, #007097);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
`;
