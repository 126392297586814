import * as Styled from "./style";
import AntdTable from "../../../components/Atoms/Table";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegFileExcel } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";
import { dataItem } from "./data";
import { LuAlertCircle, LuListFilter } from "react-icons/lu";
import { CorporatedataItem } from "./data";
import CertificateModal from "./CertificateModal";
import { DownloadCorporateCertificate } from "./DownloadCorporateCertificate";

export const CorporateCertificates = (props) => {
  const {
    certificateList,
    certificatePreview,
    setCertificatePreview,
    count,
    handleSearch,
    handleTableChange,
    search,
    tableParams,
    loading,
    handleType,
    sortData,
    numberOfTableData,
    downloadData,
    handleClick,
    CorporateTableCourse,
  } = props;

  return (
    <div>
      <Styled.CompletedCourses>
        <span className="courses">{CorporatedataItem.Completed}</span>
        <Styled.Pipe />
        <span className="number">{count ? count : 0}</span>
      </Styled.CompletedCourses>
      <Styled.BelowText1>{CorporatedataItem.BelowText}</Styled.BelowText1>
      <Styled.AlertIcon>
        <span>
          <LuAlertCircle />
        </span>
        <span>
          {`To have the certificates below, please contact: ${
            certificateList && certificateList[0]?.corporate_purchase_account
          }`}
        </span>
      </Styled.AlertIcon>

      <Styled.MainContainer2>
        <Styled.TableHeader className="container">
          <div className="tablecontainer">
            <Styled.SearchContainer>
              <AiOutlineSearch className="searchIcon" size={12} />
              <Styled.Input
                type="text"
                placeholder="Search"
                value={search?.course_name}
                onChange={handleSearch}
              />
            </Styled.SearchContainer>

            <Styled.Filter>
              <LuListFilter className="filter" />
              <Styled.NewestFirst>
                <select
                  name="language"
                  id="language"
                  className="newest"
                  onChange={handleType}
                  value={sortData}
                >
                  <option value="DESC">{dataItem.Newestfirst}</option>
                  <option value="ASC">{dataItem.Oldestfirst}</option>
                </select>
              </Styled.NewestFirst>
            </Styled.Filter>
            <Styled.Spreadsheet onClick={handleClick}>
              <FaRegFileExcel />
              <span>{dataItem.DownloadSpreadsheet}</span>
            </Styled.Spreadsheet>
          </div>

          <Styled.DotIcon>
            <BsThreeDotsVertical className="dots" />
          </Styled.DotIcon>
        </Styled.TableHeader>
        <div>
          <AntdTable
            data={certificateList}
            columns={CorporateTableCourse}
            loading={loading}
            onChange={handleTableChange}
            numberOfTableData={numberOfTableData}
            {...tableParams}
          />
        </div>
        <div
          className="certificate"
          style={{
            position: "absolute",
            zIndex: "-1000",
            overflow: "hidden",
            height: "1px",
            width: "75%",
            opacity: "0",
          }}
        >
          <div>
            {downloadData ? (
              <DownloadCorporateCertificate item={downloadData} />
            ) : null}
          </div>
        </div>
        {certificatePreview ? (
          <CertificateModal
            certificatePreview={certificatePreview}
            setCertificatePreview={setCertificatePreview}
          />
        ) : null}
      </Styled.MainContainer2>
    </div>
  );
};
