import { createSlice } from "@reduxjs/toolkit";

const licenseSlice = createSlice({
  name: "licenseList",
  initialState: {
    licenseList: [],
    issuedLicense: [],
    loading: false,
    error: null,
  },
  reducers: {
    licenseListSuccess: (state, action) => {
      state.loading = false;
      state.licenseList = action.payload;
    },
    issueLicenseStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    issueLicenseSuccess: (state, action) => {
      state.loading = false;
      state.issuedLicense = action.payload;
    },
    issueLicenseFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.data;
    },
  },
});
export const {
  licenseListSuccess,
  issueLicenseStart,
  issueLicenseSuccess,
  issueLicenseFail,
} = licenseSlice.actions;
export default licenseSlice.reducer;
