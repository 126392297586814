import * as Styled from "./Style";
import React from "react";
import StepperLabel from "../../Atoms/StepperLabel/StepperLabel";
import { constant } from "../../../constants/Constants";
import { SignData } from "./Data";

const CompanyDetails = (props) => {
  const { handleChange, signUpData, errors } = props;

  return (
    <>
      <Styled.LabelCount>
        <StepperLabel title={constant.Work} />
      </Styled.LabelCount>
      <Styled.CompanyBox>
        <Styled.Company>
          <div className="companyName">
            <Styled.Label>
              {SignData.company} <span className="star">*</span>
            </Styled.Label>
            <Styled.CompanyInput
              type="text"
              name="companyName"
              value={signUpData?.companyName}
              onChange={handleChange}
              required
            />
            {errors.companyName && (
              <Styled.Error>{errors?.companyName}</Styled.Error>
            )}
          </div>
          <div className="companyReg">
            <div>
              <Styled.Label>
                {SignData.reg}
                <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type="text"
                name="companyRegNo"
                value={signUpData?.companyRegNo}
                onChange={handleChange}
                required
              />
              {errors.companyRegNo && (
                <Styled.Error>{errors?.companyRegNo}</Styled.Error>
              )}
            </div>
            <div>
              <Styled.Label>
                {SignData.land} <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type="tel"
                name="landingNo"
                value={signUpData?.landingNo}
                onChange={handleChange}
                required
              />
              {errors.landingNo && (
                <Styled.Error>{errors?.landingNo}</Styled.Error>
              )}
            </div>
          </div>
          <div className="companyName">
            <Styled.Label>
              {SignData.address1} <span className="star">*</span>
            </Styled.Label>
            <Styled.CompanyInput
              type="text"
              name="addressLine1"
              value={signUpData?.addressLine1}
              onChange={handleChange}
              required
            />
            {errors.addressLine1 && (
              <Styled.Error>{errors?.addressLine1}</Styled.Error>
            )}
          </div>
          <div className="companyName">
            <Styled.Label>
              {SignData.address2} <span className="star">*</span>
            </Styled.Label>
            <Styled.CompanyInput
              type="text"
              name="addressLine2"
              value={signUpData?.addressLine2}
              onChange={handleChange}
              required
            />
            {errors.addressLine2 && (
              <Styled.Error>{errors?.addressLine2}</Styled.Error>
            )}
          </div>
          <div className="companyReg">
            <div>
              <Styled.Label>
                {SignData.town} <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type="text"
                name="city"
                value={signUpData?.city}
                onChange={handleChange}
                required
              />
              {errors.city && <Styled.Error>{errors?.city}</Styled.Error>}
            </div>
            <div>
              <Styled.Label>
                {SignData.post}
                <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type="text"
                name="postcode"
                value={signUpData?.postcode}
                onChange={handleChange}
                required
              />
              {errors.postcode && (
                <Styled.Error>{errors?.postcode}</Styled.Error>
              )}
            </div>
          </div>
        </Styled.Company>
      </Styled.CompanyBox>
    </>
  );
};

export default CompanyDetails;
