import * as Styled from "./style";
import React from "react";
import dayjs from "dayjs";
import CourseScreenCompletesTraining from "../../../assets/image/CourseScreenCompletesTraining.jpg";
import sign from "../../../assets/image/sign.png";
import { ModalItem } from "./data";
import { MdClose } from "react-icons/md";

const SingleUserCertificate = ({
  certificatePreview,
  setCertificatePreview,
}) => {
  const expirationDate = dayjs(certificatePreview?.completion_date)
    .add(5, "year")
    .subtract(1, "day")
    .format("DD-MM-YYYY");
  return (
    <Styled.ModalContainer
      style={{
        top: 20,
      }}
      width={850}
      footer={null}
      closeIcon={false}
      open={certificatePreview}
      onOk={() => setCertificatePreview(false)}
      onCancel={() => setCertificatePreview(false)}
    >
      <Styled.ModalHeader>
        <Styled.ModalTitle>{ModalItem.certificateModal}</Styled.ModalTitle>
        <Styled.Icon onClick={() => setCertificatePreview(false)}>
          <MdClose className="closeIcon" />
        </Styled.Icon>
      </Styled.ModalHeader>
      <Styled.CertificateMainContainer >
        <Styled.ContentBox>
          <div className="heading">
            <div className="firstHeading">
              UK National Aviation Security <br />
              Training Programme
            </div>
            <br />
            <span className="secText">This is to certify that</span>
            <div className="mainText">
              <p className="para">
                {certificatePreview?.trainee_name
                  ? certificatePreview?.trainee_name
                  : ""}
              </p>
            </div>
          </div>
          <div className="commonText">
            Has successfully demonstrated their competence following assessment
            in{" "}
          </div>
          <div className="boldText">
            {certificatePreview?.course_name
              ? certificatePreview?.course_name
              : ""}
          </div>
          <div className="modulesText">
            Modules delivered by AVTAV LTD :<br />
            Modules:1,2,3,4,and 5<br />
            in accordance with ED Regulation 2015/1998-Chapter 11.2.6.1and SCD A
            11.2.6
            <br />
            requirements and associates,approved syllabus
          </div>
          <Styled.DateContainer>
            <div className="leftContainer">
              <span>Date of Training </span>
              <span>
                {dayjs(certificatePreview?.completion_date).format(
                  "DD-MM-YYYY"
                )}
              </span>
            </div>
            <div className="rightContainer">
              <span>Expiry Date </span>
              <span> {expirationDate}</span>
            </div>
          </Styled.DateContainer>
          <div className="number">{`Certificate No: ${
            certificatePreview?.certificate_number
              ? certificatePreview?.certificate_number
              : ""
          }`}</div>
          <div className="name">
            <div>Instructor's Name:Vasilena Hristoskova</div>
            <div>CIN:2243</div>
          </div>
          <div className="number">Instructor's Signature</div>
          <div className="signBox">
            <img src={sign} alt="sign" />
          </div>

          <div className="lastBox">
            <div>
              AVTAV LTD | Suite,Ground Floor,Sovereign Court 625-635,Sipson
              Road, West Drayton,UB70JE | 0203 488 7959
            </div>
            <div>
              For confirm of the content of this document please contact the
              training provider.
              <br />
              For verification of the Training provider, Please contact the
              Civil Aviation Authority at <br />
              Avsec.training@caa.gsi.gov.uk
            </div>
          </div>
        </Styled.ContentBox>
      </Styled.CertificateMainContainer>
    </Styled.ModalContainer>
  );
};

export default SingleUserCertificate;
