import * as Styled from "./style";
import AntdTable from "../../../components/Atoms/Table";
import DownloadInvoice from "./InvoicesModal/DownloadInvoice";
import InvoicesModal from "./InvoicesModal/InvoicesModal";
import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LuListFilter } from "react-icons/lu";
import { useSelector } from "react-redux";
import { useInvoiceHook } from "../../../hooks/InvoiceHook";
import { dataItem } from "./data";
import { AiOutlineSearch } from "react-icons/ai";
import useCommonHook from "../../../hooks/CommonHook";

export const InvoicesAndPayments = () => {
  const {
    TableHeaderInvoice,
    getInvoiceData,
    invoiceList,
    downloadData,
    invoicePreview,
    setInvoicePreview,
    loading,
  } = useInvoiceHook();
  const invoiceCount = useSelector(
    (state) => state?.invoicePaymentReducer?.invoiceList?.data?.count
  );
  const [search, setSearch] = useState({
    page: 1,
    limit: 5,
    title: "",
  });
  const handleSearch = (e) => {
    setSearch((prev) => ({ ...prev, title: e.target.value }));
  };
  const [sortOrder, setSortOrder] = useState("DESC");
  const [tablePagination, setTablePagination] = useState({
    pagination: {
      total: invoiceCount,
      current: 1,
      pageSize: 5,
    },
  });
  const { useDebounce } = useCommonHook();
  const debouncedSearch = useDebounce(search, 500);
  useEffect(() => {
    setTablePagination({
      pagination: {
        total: invoiceCount,
        current: 1,
        pageSize: 5,
      },
    });
  }, [invoiceCount]);
  const tableHandleChange = (pagination, sorter) => {
    setTablePagination({
      pagination,
      ...sorter,
    });
  };
  const handleType = (e) => {
    setSortOrder(e.target.value);
  };

  useEffect(() => {
    getInvoiceData({
      ...debouncedSearch,
      sort: `createdAt|${sortOrder}`,
      page: tablePagination.pagination.current,
      limit: 5,
    });
  }, [debouncedSearch, sortOrder, tablePagination]);

  return (
    <div className="container">
      <Styled.Invoice>
        <span className="invoiceName">{dataItem.Invoices}</span>
        <Styled.Pipe />
        <span className="number">{invoiceCount}</span>
      </Styled.Invoice>
      <Styled.BelowText>{dataItem.BelowText}</Styled.BelowText>
      <Styled.MainContainer>
        <Styled.TableHeader>
          <div className="tablecontainer">
            <Styled.SearchContainer>
              <AiOutlineSearch className="searchIcon" size={12} />
              <Styled.Input
                type="text"
                placeholder="Search by course name"
                value={search?.title}
                onChange={handleSearch}
              />
            </Styled.SearchContainer>
            <Styled.Filter>
              <LuListFilter className="filter" />
              <Styled.NewestFirst>
                <select
                  name="language"
                  id="language"
                  className="newest"
                  onChange={handleType}
                  value={sortOrder}
                >
                  <option value="DESC">{dataItem.Newestfirst}</option>
                  <option value="ASC">{dataItem.Oldestfirst}</option>
                </select>
              </Styled.NewestFirst>
            </Styled.Filter>
          </div>
          <Styled.DotIcon>
            <BsThreeDotsVertical className="dots" />
          </Styled.DotIcon>
        </Styled.TableHeader>

        <AntdTable
          data={invoiceList}
          columns={TableHeaderInvoice}
          onChange={tableHandleChange}
          numberOfTableData={tablePagination.pagination.pageSize}
          {...tablePagination}
          loading={loading}
        />
      </Styled.MainContainer>
      <div
        className="invoice"
        style={{
          position: "absolute",
          zIndex: "-1000",
          overflow: "hidden",
          height: "1px",
          width: "75%",
          opacity: "0",
        }}
      >
        <div>
          {downloadData ? <DownloadInvoice item={downloadData} /> : null}
        </div>
      </div>
      {invoicePreview ? (
        <InvoicesModal
          item={invoicePreview}
          openModal={true}
          setOpenModal={setInvoicePreview}
        />
      ) : null}
    </div>
  );
};
