export const data = {
  Billing: "Billing",
  BillingAddress: "Billing Address",
  EastLondon: "East London",
  MobileNumber: "Mobile Number",
  Number: "+44 135 3633 3336",
  SavedBilling: "Saved Billing Cards",
  SomeText: "Some short description can come here",
  CardholderName: "Cardholder Name",
  StaceyMatthews: "Stacey Matthews",
  CardNumber: "Card Number",
  Expiration: "Expiration",
  CVV: "CVV",
  ExpiryDate: "Expiry Date",
  save: "Save Changes",
  cancel: "Cancel",
  Country_Region: "Country / Region",
};
