import * as Styled from "./style";
import React from "react";
import { MdClose } from "react-icons/md";
import { ReactComponent as BusinessLogo } from "../../../assets/Svg/BusinessLogo.svg";
import { ModalItem } from "./data";

const CertificateModal = ({ certificatePreview, setCertificatePreview }) => {
  return (
    <>
      <Styled.ModalContainer
        style={{
          top: 90,
        }}
        width={850}
        footer={null}
        closeIcon={false}
        open={certificatePreview}
        onOk={() => setCertificatePreview(false)}
        onCancel={() => setCertificatePreview(false)}
      >
        <Styled.ModalHeader>
          <Styled.ModalTitle>{ModalItem.certificateModal}</Styled.ModalTitle>
          <Styled.Icon onClick={() => setCertificatePreview(false)}>
            <MdClose className="closeIcon" />
          </Styled.Icon>
        </Styled.ModalHeader>
        <Styled.ModalBoxCertificate>
          <div>
            <div className="logo">
              <BusinessLogo className="business" />
            </div>
            <div className="contact">{ModalItem.contact}</div>
            <div className="employee">
              {certificatePreview?.corporate_purchase_account
                ? certificatePreview?.corporate_purchase_account
                : ""}
            </div>
          </div>
        </Styled.ModalBoxCertificate>
      </Styled.ModalContainer>
    </>
  );
};

export default CertificateModal;
