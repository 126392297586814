import MainLayout from "../../layout/MainLayout";
import OnlineTerms from "../../containers/InvitedUserSignUp/OnlineTerms";
import React from "react";

const OnlineTermsPage = () => {
  return (
    <MainLayout>
      <OnlineTerms />
    </MainLayout>
  );
};

export default OnlineTermsPage;
