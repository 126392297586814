import { createSlice } from "@reduxjs/toolkit";

const declarationSlice = createSlice({
  name: "declaration",
  initialState: {
    managerDeclarationData: {},
    declareData: [],
    corporateDeclarationForm: [],
    singleDeclarationForm: [],
    loading: false,
    error: null,
  },
  reducers: {
    declarationFormStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    declarationFormSuccess: (state, action) => {
      state.loading = false;
      state.declareData = action?.payload;
    },
    declarationFormFail: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.data;
    },

    managerdeclarationFormStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    managerdeclarationFormSuccess: (state, action) => {
      state.loading = false;
      state.managerDeclarationData = action?.payload;
    },
    managerdeclarationFormFail: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.data;
    },

    corporateDeclarationFormSuccess: (state, action) => {
      state.loading = false;
      state.corporateDeclarationForm = action?.payload;
    },
    singleDeclarationFormSuccess: (state, action) => {
      state.loading = false;
      state.singleDeclarationForm = action?.payload;
    },
  },
});

export const {
  declarationFormStart,
  declarationFormSuccess,
  declarationFormFail,
  corporateDeclarationFormSuccess,
  singleDeclarationFormSuccess,
  managerdeclarationFormStart,
  managerdeclarationFormSuccess,
  managerdeclarationFormFail,
} = declarationSlice.actions;
export default declarationSlice.reducer;
