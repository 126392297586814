import TickAvtav from "../../../assets/Svg/TickAvtav.svg";

export const corporateInvoice = [
  {
    id: 0,
    name: TickAvtav,
  },
  {
    id: 1,
    name: TickAvtav,
  },
  {
    id: 2,
    name: TickAvtav,
  },
  {
    id: 3,
    name: TickAvtav,
  },
  {
    id: 4,
    name: TickAvtav,
  },
];

export const british = {
  invoice: "Invoice",
  no: "Invoice No ",
  hash: "#",
  hua: "3885 Hua Way",
  denmark: "Northaden, Denmark",
  client: "Client:",
};
