import Footer from "../../components/Organisms/Footer/Footer";
import CoursesModule from "../../containers/TrainingScreen/FirstScreen/CoursesModule";
import React from "react";

const TrainingCoursesPage = () => {
  return (
    <div style={{ overflowY: "auto", height: "100vh" }}>
      <CoursesModule />
      <Footer />
    </div>
  );
};

export default TrainingCoursesPage;
