import Checkbox from "../../../assets/Svg/Checkbox.svg";

export const Data = [

  {
    label: "First name",
    type: "text",
  },
  {
    label: "Last name ",
    type: "text",
  },
  {
    label: "Email",
    type: "email",
  },
  {
    label: "Mobile ",
    type: "text",
  },
];

export const companyData = [
  {
    label: "Company Name  ",
    type: "text",
    style: false,
  },
  {
    label: "Company Reg Number  ",
    type: "text",
    style: false,
  },
  {
    label: "Landline Number ",
    type: "text",
    style: false,
  },
  {
    label: "Address Line 1  ",
    type: "text",
    style: true,
  },
  {
    label: "Address Line 2  ",
    type: "text",
    style: true,
  },
  {
    label: "Town/City   ",
    type: "text",
    style: true,
  },
  {
    label: "Postcode  ",
    type: "text",
    style: true,
  },
];
export const passwordData = [
  {
    label: "Password",
    type: "password",
  },
];

export const EmpData = [
  {
    label: "Your manager’s email",
    type: "email",
  },
];

export const inputData = [
  {
    label: "Company Name",
    type: "text",
  },
  {
    label: "Company Address",
    type: "text",
  },
  {
    label: "Nature of Business",
    type: "text",
  },
  {
    label: "Company Registration Business",
    type: "text",
  },
  {
    label: "Name",
    type: "text",
  },
  {
    label: "Job Title",
    type: "text",
  },
  {
    label: "Email",
    type: "text",
  },

  {
    label: "LandLine Number",
    type: "text",
  },
  {
    label: "Mobile Number",
    type: "text",
  },
  {
    label: "Date",
    type: "date",
  },
];

export const inData = [
  {
    type: "text",
  },
  {
    type: "text",
  },
];

export const Checkboxdata = [
  {
    id: 1,
    name: <img src={Checkbox} alt="" checkbox className="logo"/>,
  },
  {
    id: 2,
    name: <img src={Checkbox} alt="" checkbox className="logo"/>,
  },
  {
    id: 3,
    name: <img src={Checkbox} alt="" checkbox className="logo"/>,
  },
  {
    id: 4,
    name: <img src={Checkbox} alt="" checkbox className="logo"/>,
  },
  {
    id: 5,
    name: <img src={Checkbox} alt="" checkbox className="logo"/>,
  },
  {
    id: 6,
    name: <img src={Checkbox} alt="" checkbox className="logo"/>,
  },
  {
    id: 7,
    name: <img src={Checkbox} alt="" checkbox className="logo"/>,
  },
  {
    id: 8,
    name: <img src={Checkbox} alt="" checkbox className="logo"/>,
  },
  {
    id: 9,
    name: <img src={Checkbox} alt="" checkbox className="logo"/>,
  },
  {
    id: 10,
    name: <img src={Checkbox} alt="" checkbox className="logo"/>,
  },
];

export const SignData = {
  first: "First Name",
  last: "Last Name",
  email: "Email",
  mobile: "Mobile",
  company: "Company Name",
  reg: "Company Reg Number ",
  land: "Landline Number",
  address1: "Address Line 1",
  address2: "Address Line 2",
  town: " Town/City",
  post: "Postcode",
  password: "Password",
};
export const PasswordData = {
  creating: "By creating an account you agree to AVTAV Ltd ",
  conditions: "website conditions of use, ",
  accept: `acceptable use policy `,
  and: "& ",
  privacy: "privacy notice",
  account: "By creating an account you agree to AVTAV Ltd",
  online: " online terms for the sale of digital content.",
  user: " end user licence agreement.",
};