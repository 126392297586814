import React from "react";
import HeaderPre from "./HeaderPre";
import PreCourseInvited from "./PreCourseInvited";
import * as Styled from "./style";

const SingleUserPreCourse = () => {
  return (
    <div>
      <HeaderPre />
      <Styled.MainComponent className="container">
        <PreCourseInvited />
      </Styled.MainComponent>
    </div>
  );
};

export default SingleUserPreCourse;
