import * as Styled from "./Style";
import React, { useState } from "react";
import StepperLabel from "../../Atoms/StepperLabel/StepperLabel";
import useAuthHook from "../../../hooks/AuthHook";
import useCommonHook from "../../../hooks/CommonHook";
import { Button } from "antd";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { constant } from "../../../constants/Constants";
import { Routes } from "../../../routes/routes";
import { PasswordData, SignData } from "./Data";
import { message } from "antd";

const PasswordYou = (props) => {
  const [eyeVisible, setEyeVisible] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const invitedCode = searchParams.get("invitedCode");
  const loginReferer = useSelector((state) => state.commonReducer.loginReferer);
  const [checkboxs, setChecked] = useState({
    licence: false,
    digital: false,
    privacy: false,
  });
  const { handleChange, signUpData, registerButton, isCorporate, errors } =
    props;
  const { handleRegister, handleInviteRegister } = useAuthHook();
  const { updateCommonState } = useCommonHook();
  const handleCorporateRegBtn = async () => {
    if (checkboxs.digital && checkboxs.licence && checkboxs.privacy) {
      const reg =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      if (signUpData?.password?.length === 0) {
        message.error("Please fill out the password");
      } else if (
        signUpData?.password.trim().length > 0 &&
        !reg.test(signUpData?.password)
      ) {
        message.error(
          "Password must be at least 8 characters long, including upper case, lower case, number, and a special character."
        );
      } else {
        if (props.isInvited) {
          const inviteData = await handleInviteRegister({
            role: "Single User",
            email: email,
            invitedCode: invitedCode,
            password: signUpData?.password,
            sessionId: signUpData?.sessionId,
          });

          if (inviteData?.status) {
            navigate(Routes?.singleUserProfile);
          } else {
            message.error(inviteData.message);
          }
        } else {
          const userData = await handleRegister({
            ...signUpData,
            type: isCorporate ? `Corporate User` : "Single User",
          });

          if (userData?.status) {
            if (loginReferer.trim() === "checkout") {
              updateCommonState({ loginReferer: "" });
              navigate(Routes?.multiCart);
            } else if (loginReferer.trim() === "cart") {
              updateCommonState({ loginReferer: "" });
              navigate(Routes?.multiCart);
            } else {
              if (userData?.data?.type === "Corporate User") {
                navigate(Routes?.userProfile);
              } else if (userData?.data?.type === "Single User") {
                navigate(Routes?.singleUserProfile);
              }
            }
          } else {
            message.error(userData.message);
          }
        }
      }
    } else {
      message.error("Please checked the above checkbox");
    }
  };
  const handleCheckbox = (e) => {
    setChecked({ ...checkboxs, [e.target.id]: e.target.checked });
  };
  const handleEyeChange = () => {
    setEyeVisible(!eyeVisible);
  };
  return (
    <>
      <Styled.LabelCount>
        <StepperLabel title={constant.Password} />
      </Styled.LabelCount>
      <Styled.PasswordBox>
        <Styled.PasswordDetails>
          <div>
            <Styled.Label>
              {SignData.password}

              <span className="star">*</span>
            </Styled.Label>
            <Styled.MainPassword>
              <Styled.PasswordInput
                type={eyeVisible ? "text" : "password"}
                name="password"
                value={signUpData?.password}
                onChange={handleChange}
              />
              <span onClick={handleEyeChange} className="eye">
                {eyeVisible ? <FaEye /> : <FaEyeSlash />}
              </span>
              {errors?.password && <div>{errors?.password}</div>}
            </Styled.MainPassword>
          </div>
        </Styled.PasswordDetails>
        <Styled.CheckBoxContainer>
          <Styled.CheckBoxText>
            <input
              type="checkbox"
              className="boxSize"
              id="privacy"
              onChange={(e) => handleCheckbox(e)}
            />
            <div className="text">
              {PasswordData.creating}
              <Link
                to={Routes?.termsPage}
                target="_blank"
                rel="noreferrer noopener"
                className="textStyle"
              >
                {PasswordData.conditions}
              </Link>
              <Link
                to={Routes?.acceptablePage}
                target="_blank"
                rel="noreferrer noopener"
                className="textStyle"
              >
                {PasswordData.accept}
              </Link>
              {PasswordData.and}{" "}
              <Link
                to={Routes?.privacyNotes}
                target="_blank"
                rel="noreferrer noopener"
                className="textStyle"
              >
                {PasswordData.privacy}
              </Link>
            </div>
          </Styled.CheckBoxText>
          <Styled.CheckBoxText>
            <input
              type="checkbox"
              className="boxSize"
              id="digital"
              onChange={(e) => handleCheckbox(e)}
            />
            <div className="text">
              {PasswordData.account}
              <Link
                to={Routes?.onlineTermsPage}
                target="_blank"
                rel="noreferrer noopener"
                className="textStyle"
              >
                {PasswordData.online}
              </Link>
            </div>
          </Styled.CheckBoxText>
          <Styled.CheckBoxText>
            <input
              type="checkbox"
              className="boxSize"
              id="licence"
              onChange={(e) => handleCheckbox(e)}
            />
            <div className="text">
              {PasswordData.account}
              <Link
                to={Routes?.endUserPage}
                target="_blank"
                rel="noreferrer noopener"
                className="textStyle"
              >
                {PasswordData.user}
              </Link>
            </div>
          </Styled.CheckBoxText>
        </Styled.CheckBoxContainer>
        <Styled.RegisterBtn>
          <Button
            type="primary"
            onClick={handleCorporateRegBtn}
            className="btnRegister"
          >
            {registerButton}
          </Button>
        </Styled.RegisterBtn>
      </Styled.PasswordBox>
    </>
  );
};

export default PasswordYou;
